import { Group, Stack, Text } from "@mantine/core";
import { Button, Radio, RadioGroup } from "components";
import { CompanyAutocompleteInput } from "components/CompanyAutocompleteInput";
import { useCheckoutContext } from "contexts/CheckoutContext";
import { areAddressesDifferent } from "helpers/address";
import { useForm } from "hooks";
import { startCase } from "lodash";
import React, { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as TechnicalSupportSketch } from "resources/sketches/technical-support.svg";
import { companyRegistrationIdString, companyVatIdString } from "schemas";
import { CompanyDetail } from "types";
import { z } from "zod";
import { StepModalBottomBar } from "../../StepModalBottomBar";
import { CompanyAddressType, useCheckoutCustomerContext } from "../Context";
import { useStyles } from "./CheckoutCustomerCompanyForm.styles";

const companySchema = z.object({
  company_name: z.string().nonempty(),
  company_registration_id: companyRegistrationIdString(),
  company_vat_id: companyVatIdString(),
});

const capitalize = (text: string) => startCase(text.toLowerCase());

export const CheckoutCustomerCompanyForm: React.FC = () => {
  const { retailer } = useCheckoutContext();
  const {
    data: { session_customer },
    setData,
    nextCustomerFormStep,
    isRepresentative,
    setIsRepresentative,
    setCompanyAddresses,
  } = useCheckoutCustomerContext();
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { onSubmit, setValues, values, errors } = useForm({
    schema: companySchema,
    initialValues: {
      company_name: "",
      company_registration_id: "",
      company_vat_id: "",
    },
  });

  const setCompany = useCallback(
    (company: z.infer<typeof companySchema>) => {
      setData({
        session_customer: {
          ...session_customer,
          ...company,
        },
      });
      nextCustomerFormStep();
    },
    [nextCustomerFormStep, session_customer, setData]
  );

  const onCompanyChange = useCallback(
    (company: CompanyDetail | null) => {
      if (company) {
        setValues({
          company_name: company.nom_entreprise,
          company_registration_id: company.siege.siret,
          company_vat_id: company.numero_tva_intracommunautaire,
        });

        const headquarterAddress = {
          type: CompanyAddressType.HEADQUARTER,
          street_address: capitalize(company.siege.adresse_ligne_1),
          street_address_2: capitalize(company.siege.adresse_ligne_2 ?? ""),
          zip_code: company.siege.code_postal,
          city: capitalize(company.siege.ville),
          country: company.siege.code_pays,
        };

        const establishmentAddress = {
          type: CompanyAddressType.ESTABLISHMENT,
          street_address: capitalize(company.etablissement.adresse_ligne_1),
          street_address_2: capitalize(company.etablissement.adresse_ligne_2 ?? ""),
          zip_code: company.etablissement.code_postal,
          city: capitalize(company.etablissement.ville),
          country: company.etablissement.code_pays,
        };

        setCompanyAddresses([
          headquarterAddress,
          ...(areAddressesDifferent(headquarterAddress, establishmentAddress)
            ? [establishmentAddress]
            : []),
        ]);
      } else {
        setValues({
          company_name: "",
          company_registration_id: "",
          company_vat_id: "",
        });
        setCompanyAddresses([]);
      }
    },
    [setCompanyAddresses, setValues]
  );

  return (
    <form className={classes.container} noValidate onSubmit={onSubmit(setCompany)}>
      <Stack spacing="sm" className={classes.contentWrapper}>
        <CompanyAutocompleteInput
          color={retailer.style.primary_color}
          label={t("checkout.siret")}
          required
          initialCompanySiret={session_customer.company_registration_id}
          onChange={onCompanyChange}
          error={!!errors["company_name"]}
        />

        {!!values.company_name && (
          <>
            <Group position="apart" className={classes.infoWrapper}>
              <Text size="sm">
                <Trans
                  i18nKey="checkout.companyRepresentativeIdDocumentRequired"
                  components={{ b: <b /> }}
                />
              </Text>
              <TechnicalSupportSketch className={classes.infoSketch} />
            </Group>

            <Text fw={700} size="sm">
              {t("checkout.youAreCompanyRepresentative")}
            </Text>

            <RadioGroup
              value={String(isRepresentative)}
              onChange={(value) => setIsRepresentative(value === String(true))}
            >
              <Group spacing="xl" pl="sm">
                <Radio
                  color={retailer.style.primary_color}
                  value={String(true)}
                  label={t("checkout.yes")}
                />
                <Radio
                  color={retailer.style.primary_color}
                  value={String(false)}
                  label={t("checkout.no")}
                />
              </Group>
            </RadioGroup>
          </>
        )}
      </Stack>

      <StepModalBottomBar>
        <Button color={retailer.style.primary_color} type="submit">
          {t("checkout.continue")}
        </Button>
      </StepModalBottomBar>
    </form>
  );
};
