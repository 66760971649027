import * as Sentry from "@sentry/react";
import { MutationCache, QueryCache, QueryClient } from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse, HttpStatusCode, isAxiosError } from "axios";
import { getSessionQueryData } from "hooks";
import { matchPath } from "react-router-dom";
import { Mode, RoutePath, Session } from "types";

const REQUEST_TIMEOUT = 60000; // 1 minute

const redirectToErrorPage = () => {
  const sessionId = matchPath(RoutePath.CHECKOUT, window.location.pathname)?.params.sessionId;
  const retailerId = getSessionQueryData(String(sessionId))?.retailer_id;
  if (
    retailerId &&
    ![RoutePath.ERROR, RoutePath.CHECKOUT_ERROR].some(
      (path) => matchPath(path, window.location.pathname) !== null
    )
  ) {
    window.location.replace(RoutePath.CHECKOUT_ERROR.replace(":retailerId", retailerId));
  } else if (matchPath(RoutePath.ERROR, window.location.pathname) === null) {
    window.location.replace(RoutePath.ERROR);
  }
};

const redirectToRejectedPage = () => {
  const sessionId = matchPath(RoutePath.CHECKOUT, window.location.pathname)?.params.sessionId;
  const retailerId = getSessionQueryData(String(sessionId))?.retailer_id;
  retailerId
    ? window.location.replace(RoutePath.CHECKOUT_REJECTED.replace(":retailerId", retailerId))
    : window.location.replace(RoutePath.ERROR);
};

const queryOnError = (error: unknown) => {
  if (!isAxiosError(error)) {
    Sentry.captureException(error);
    redirectToErrorPage();
  }
};

const queryCache = new QueryCache({
  onError: queryOnError,
});

const mutationCache = new MutationCache({
  onError: queryOnError,
});

export const queryClient = new QueryClient({ queryCache, mutationCache });

export const apiClient = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  headers: {
    common: {
      Authorization: (() => {
        const urlSearchParams = new URLSearchParams(window.location.search);

        if (urlSearchParams.has("retailerApiKey")) {
          const retailerApiKey = urlSearchParams.get("retailerApiKey");

          if (!retailerApiKey) throw Error("retailerApiKey should be in url search params.");

          sessionStorage.setItem("retailerApiKey", retailerApiKey);

          if (import.meta.env.MODE === Mode.PRODUCTION) {
            urlSearchParams.delete("retailerApiKey");
            const newUrlSearchParamsString = urlSearchParams.toString();
            window.history.replaceState(
              null,
              "",
              window.location.pathname +
                (newUrlSearchParamsString ? `?${newUrlSearchParamsString}` : "")
            );
          }

          return `Bearer ${retailerApiKey}`;
        }

        const retailerApiKey = sessionStorage.getItem("retailerApiKey");

        if (!retailerApiKey) {
          if (import.meta.env.MODE !== Mode.TEST) {
            console.warn("Retailer Api Key is missing.");
          }

          return undefined;
        }

        return `Bearer ${retailerApiKey}`;
      })(),
    },
  },
  timeout: REQUEST_TIMEOUT,
});

apiClient.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.data?.status === "blocked") {
      redirectToRejectedPage();
    }
    return response;
  },
  (error: AxiosError<{ session?: Session }>) => {
    if (
      error.response?.status === HttpStatusCode.InternalServerError ||
      error.response?.status === HttpStatusCode.Unauthorized
    ) {
      redirectToErrorPage();
    }

    if (error.response?.data?.session?.status === "blocked") {
      redirectToRejectedPage();
    }
    throw error;
  }
);
