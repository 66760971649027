import Dinero from "dinero.js";
import { CoverageOwner, IncidentType, LineItem, LineItemType, Retailer, Session } from "types";
import { createPrice, sumPrices } from "../price";

export interface CheckoutOrderPlanLineItem extends LineItem {
  firstInstalmentPrice: Dinero.Dinero;
  firstInstalmentPriceIncludingVAT: Dinero.Dinero;
  otherInstalmentPrice: Dinero.Dinero;
  otherInstalmentPriceIncludingVAT: Dinero.Dinero;
  insuredCoverageTypes: IncidentType[];
}

export interface CheckoutOrderPlan {
  totalFirstInstalmentPrice: Dinero.Dinero;
  totalFirstInstalmentPriceIncludingVAT: Dinero.Dinero;
  totalOtherInstalmentPrice: Dinero.Dinero;
  totalOtherInstalmentPriceIncludingVAT: Dinero.Dinero;
  rentedItems: CheckoutOrderPlanLineItem[];
  purchasedItems: CheckoutOrderPlanLineItem[];
  includedItems: CheckoutOrderPlanLineItem[];
  shippingItems: CheckoutOrderPlanLineItem[];
  couponItems: CheckoutOrderPlanLineItem[];
}

export type CheckoutOrder = {
  [duration: number]: CheckoutOrderPlan;
  selectedDuration: CheckoutOrderPlan;
};

const buildCheckoutOrderPlanLineItem = (
  lineItem: LineItem,
  retailer: Retailer,
  duration: number
): CheckoutOrderPlanLineItem => {
  const plan = lineItem.plans.find((plan) => plan.duration === duration);

  if (!plan) throw Error(`No plan found with duration: ${duration}`);

  const coverageConfiguration = retailer.coverage_configurations.find(
    ({ code }) => code === lineItem.coverage_configuration_code
  );

  const insuredCoverageTypes = coverageConfiguration
    ? coverageConfiguration.coverages
        .filter(
          ({ finance_owner, operation_owner }) =>
            finance_owner === CoverageOwner.INSURER || operation_owner === CoverageOwner.INSURER
        )
        .map(({ coverage_type }) => coverage_type)
    : [];

  const firstInstalmentPrice = createPrice(plan.first_instalment).multiply(
    lineItem.item_type === LineItemType.COUPON ? -1 : 1
  );

  const otherInstalmentPrice = createPrice(plan.other_instalment).multiply(
    lineItem.item_type === LineItemType.COUPON ? -1 : 1
  );

  const firstInstalmentPriceIncludingVAT = createPrice(plan.first_instalment_with_tax).multiply(
    lineItem.item_type === LineItemType.COUPON ? -1 : 1
  );

  const otherInstalmentPriceIncludingVAT = createPrice(plan.other_instalment_with_tax).multiply(
    lineItem.item_type === LineItemType.COUPON ? -1 : 1
  );

  return {
    ...lineItem,
    firstInstalmentPrice,
    firstInstalmentPriceIncludingVAT,
    otherInstalmentPrice,
    otherInstalmentPriceIncludingVAT,
    insuredCoverageTypes,
  };
};

export const buildCheckoutOrderPlan = (
  session: Session,
  retailer: Retailer,
  duration: number
): CheckoutOrderPlan => {
  const planLineItems = session.line_items.map((lineItem) =>
    buildCheckoutOrderPlanLineItem(lineItem, retailer, duration)
  );

  const totalFirstInstalmentPrice = sumPrices(
    planLineItems.map(({ firstInstalmentPrice }) => firstInstalmentPrice)
  );

  const totalOtherInstalmentPrice = sumPrices(
    planLineItems.map(({ otherInstalmentPrice }) => otherInstalmentPrice)
  );

  const totalFirstInstalmentPriceIncludingVAT = sumPrices(
    planLineItems.map(({ firstInstalmentPriceIncludingVAT }) => firstInstalmentPriceIncludingVAT)
  );

  const totalOtherInstalmentPriceIncludingVAT = sumPrices(
    planLineItems.map(({ otherInstalmentPriceIncludingVAT }) => otherInstalmentPriceIncludingVAT)
  );

  return {
    totalFirstInstalmentPrice,
    totalFirstInstalmentPriceIncludingVAT,
    totalOtherInstalmentPrice,
    totalOtherInstalmentPriceIncludingVAT,
    rentedItems: planLineItems.filter(
      ({ item_type, rent }) => item_type === LineItemType.PHYSICAL && rent
    ),
    purchasedItems: planLineItems.filter(
      ({ item_type, rent, firstInstalmentPrice }) =>
        item_type === LineItemType.PHYSICAL && rent === false && !firstInstalmentPrice.isZero()
    ),
    includedItems: planLineItems.filter(
      ({ item_type, rent, firstInstalmentPrice }) =>
        item_type === LineItemType.PHYSICAL && rent === false && firstInstalmentPrice.isZero()
    ),
    shippingItems: planLineItems.filter(({ item_type }) => item_type === LineItemType.SHIPPING),
    couponItems: planLineItems.filter(({ item_type }) => item_type === LineItemType.COUPON),
  };
};

export const buildCheckoutOrder = (
  session: Session,
  retailer: Retailer,
  selectedDuration: number
): CheckoutOrder => {
  const durations = retailer.duration_configurations.map(({ duration }) => duration);

  const order = Object.fromEntries(
    durations.map((duration) => [duration, buildCheckoutOrderPlan(session, retailer, duration)])
  );

  return {
    ...order,
    selectedDuration: order[selectedDuration],
  };
};
