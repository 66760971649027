import { createStyles } from "@mantine/core";
import { MEDIUM_SCREEN_BREAKPOINT } from "theme";
import { CHECKOUT_HEADER_HEIGHT } from "../Header/CheckoutHeader.styles";

export const MEDIUM_SCREEN_MAX_WIDTH = 700;

export const useStyles = createStyles(({ spacing, fn: { smallerThan, largerThan } }) => ({
  scrollArea: {
    flex: 1,
    minHeight: 0,
    backgroundColor: "#FAFAFA",
  },

  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: `calc(100dvh - ${CHECKOUT_HEADER_HEIGHT}px)`,
  },

  wrapper: {
    display: "flex",
    justifyContent: "center",
    padding: spacing.sm,
    paddingTop: spacing.xl,

    [largerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
      gap: "8%",
      paddingLeft: 96,
      paddingRight: 96,
    },
  },

  leftSection: {
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
    maxWidth: MEDIUM_SCREEN_MAX_WIDTH,

    [largerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
      maxWidth: "none",
    },
  },

  rightSection: {
    width: "30%",

    [smallerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
      display: "none",
    },
  },

  stepsWrapper: {
    flex: 1,
    width: `calc(100% - ${spacing.sm} * 2)`,
  },

  loader: {
    alignSelf: "center",
  },
}));
