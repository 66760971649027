import {
  Autocomplete,
  AutocompleteItem,
  AutocompleteProps,
  Box,
  SelectItemProps,
  Text,
} from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { getCompany, searchCompany } from "services/pappers";
import { CompanyDetail } from "types";
import { useStyles } from "./CompanyAutocompleteInput.styles";
import { formatCompany } from "./helpers";

type Props = Omit<
  AutocompleteProps,
  | "data"
  | "value"
  | "inputMode"
  | "onChange"
  | "onKeyDown"
  | "itemComponent"
  | "filter"
  | "classNames"
  | "onItemSubmit"
  | "color"
> & {
  color: string;
  initialCompanySiret?: string | null;
  onChange: (company: CompanyDetail | null) => void;
};

interface CompanyAutocompleteItem extends AutocompleteItem {
  siret: string;
  formattedSiret: string;
  name: string;
  zipCode: string;
}

type CompanyAutocompleteItemProps = SelectItemProps & CompanyAutocompleteItem;

const CompanyAutocompleteItemComponent = forwardRef<HTMLDivElement, CompanyAutocompleteItemProps>(
  ({ formattedSiret, name, zipCode, ...props }, ref) => (
    <Box ref={ref} px="sm" py="xs" {...props}>
      <Text>
        <Text span fw={600}>
          {name}
        </Text>{" "}
        <Text c="dimmed" span>
          ({zipCode})
        </Text>{" "}
        - <Text span>{formattedSiret}</Text>
      </Text>
    </Box>
  )
);

export const CompanyAutocompleteInput = React.forwardRef<HTMLInputElement, Props>(
  ({ initialCompanySiret, onChange, color, onFocus, onBlur, error, ...props }, ref) => {
    const [focused, setFocused] = useState(false);
    const [value, setValue] = useState("");
    const [data, setData] = useState<CompanyAutocompleteItem[]>([]);

    const { classes } = useStyles({
      color,
      empty: !value,
      focused,
      withLabel: !!props.label,
      error: !!error,
    });

    const onItemSubmit = useCallback(
      async ({ siret }: CompanyAutocompleteItem) => {
        const company = await getCompany(siret);
        onChange(company);
      },
      [onChange]
    );

    const [debouncedValue] = useDebouncedValue(value, 300);

    useEffect(() => {
      if (debouncedValue !== value) return;

      if (value) {
        searchCompany(value.replace(/\s/g, "")).then((companies) => {
          setData(
            companies
              .filter((company) => !!company.siege.code_postal)
              .map((company) => ({
                value: formatCompany(company),
                siret: company.siege.siret,
                formattedSiret: company.siege.siret_formate,
                name: company.nom_entreprise,
                zipCode: company.siege.code_postal,
              }))
          );
        });
      } else {
        setData([]);
      }
    }, [debouncedValue, onChange, value]);

    useEffect(() => {
      if (!initialCompanySiret) return;

      getCompany(initialCompanySiret)
        .then((company) => {
          setValue(formatCompany(company));
          onChange(company);
        })
        .catch(() => onChange(null));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Autocomplete
        ref={ref}
        data={data}
        value={value}
        inputMode="numeric"
        onChange={(value) => {
          setValue(value);
          if (!value) onChange(null);
        }}
        onKeyDown={(event) => {
          if (
            !event.key.match(/[0-9 ]/) &&
            !["Backspace", "Space"].includes(event.key) &&
            !event.metaKey
          ) {
            event.preventDefault();
          }
        }}
        itemComponent={CompanyAutocompleteItemComponent}
        filter={() => true}
        classNames={classes}
        onItemSubmit={onItemSubmit}
        onFocus={(event) => {
          setFocused(true);
          event.target.select();
          onFocus?.(event);
        }}
        onBlur={(event) => {
          setFocused(false);
          onBlur?.(event);
        }}
        error={!!error}
        {...props}
      />
    );
  }
);

CompanyAutocompleteInput.displayName = "CompanyAutocompleteInput";
