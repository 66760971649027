import { createStyles } from "@mantine/core";
import { LIGHT_GRAY } from "theme";

interface Params {
  color: string;
}

export const useStyles = createStyles<string, Params>(
  ({ spacing, radius, fontSizes }, { color }) => ({
    container: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },

    contentWrapper: {
      flex: 1,
      padding: spacing.sm,
      display: "flex",
      flexDirection: "column",
      rowGap: spacing.md,
      justifyContent: "space-between",
    },

    card: {
      backgroundColor: LIGHT_GRAY,
      borderRadius: radius.md,
      padding: `${spacing.xs} ${spacing.sm}`,
    },

    sketch: {
      flexShrink: 0,
      color,
      width: "75px",
    },

    stack: {
      minWidth: 0,
      rowGap: "0.2rem",
    },

    text: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontSize: fontSizes.sm,
    },
  })
);
