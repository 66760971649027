import { z } from "zod";
import { apiClient } from "../client";

interface CreateParams {
  session_id: string;
  setup_intent_id: string | null;
}

const responseDataSchema = z.object({
  client_secret: z.string(),
});

export const getPaymentClientSecret = async ({ session_id, setup_intent_id }: CreateParams) =>
  responseDataSchema.parse(
    (
      await apiClient.get(
        `sessions/${session_id}/setup_intent_result` +
          (setup_intent_id ? `?setup_intent_id=${setup_intent_id}` : "")
      )
    ).data
  ).client_secret;
