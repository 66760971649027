import { Center, Flex, Group, List, ScrollArea, Stack, Text, Title } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { Button } from "components";
import { useCheckoutContext } from "contexts/CheckoutContext";
import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ContractSignatureSketch } from "resources/sketches/contract-signature.svg";
import { ReactComponent as TechnicalSupportSketch } from "resources/sketches/technical-support.svg";
import { CheckoutOrderSummary } from "../Order/CheckoutOrderSummary";
import { useStyles } from "./CheckoutPendingContractSignature.styles";

export const CheckoutPendingContractSignature: React.FC = () => {
  const {
    retailer,
    session: {
      session_customer: { customer_type },
    },
  } = useCheckoutContext();
  const { t } = useTranslation();
  const { classes } = useStyles({ color: retailer.style.primary_color });

  return (
    <Flex direction="column" className={classes.container}>
      <ScrollArea className={classes.scrollArea}>
        <Center p="sm" pt="lg">
          <Stack spacing="lg" className={classes.wrapper}>
            <CheckoutOrderSummary shadowed />
            <Group position="apart" className={classes.header}>
              <Title size="h5">{t("checkout.rentalContractReady")}</Title>

              <ContractSignatureSketch
                color={retailer.style.primary_color}
                className={classes.sketch}
              />
            </Group>

            <Text>{t("checkout.thankYouForCompletingCheckout")}</Text>

            <List
              icon={<IconCheck />}
              size="sm"
              spacing="xs"
              center
              classNames={{ itemIcon: classes.listItemIcon }}
            >
              {Array.from({ length: 2 }).map((_, index) => (
                <List.Item key={`acceptedIdDocumentTypes-${index}`} fw={700}>
                  {t(`checkout.contractSignatureNextSteps.${customer_type}.${index}`)}
                </List.Item>
              ))}
            </List>

            <Group position="apart" className={classes.infoWrapper}>
              <Text size="sm">{t("checkout.weLookForwardToFinalizeYourRental")}</Text>
              <TechnicalSupportSketch className={classes.infoSketch} />
            </Group>

            <Text>{t("checkout.seeYouSoon")}</Text>
          </Stack>
        </Center>

        <Flex justify="center" p="sm" className={classes.buttonWrapper}>
          <a href={retailer.urls_config.shop_url} className={classes.link}>
            <Button color={retailer.style.primary_color} className={classes.button}>
              {t("checkout.backToRetailerWebsite", { retailerName: retailer.name })}
            </Button>
          </a>
        </Flex>
      </ScrollArea>
    </Flex>
  );
};
