import { createStyles } from "@mantine/core";
import { MEDIUM_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(({ spacing, fn: { largerThan } }) => ({
  wrapper: {
    padding: `${spacing.md} ${spacing.sm}`,

    [largerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
      padding: `${spacing.lg} ${spacing.sm}`,
    },
  },
}));
