import { sessionSchema } from "schemas";
import { Session } from "types";
import { apiClient } from "../client";

export const updateSessionStagingPayloadId = async ({
  id: sessionId,
  staging_payload_id,
}: Pick<Session, "id" | "staging_payload_id">) =>
  sessionSchema.parse(
    (
      await apiClient.patch<Session>(`sessions/${sessionId}`, {
        session: {
          staging_payload_id,
        },
      })
    ).data
  );
