import { createStyles } from "@mantine/core";

interface Params {
  color: string;
}

export const useStyles = createStyles<string, Params>(
  ({ shadows, colors, white, fn: { rgba, darken } }, { color }) => ({
    root: {
      maxWidth: "unset !important",
    },

    input: {
      paddingLeft: "0.5rem",
      fontWeight: 700,
      boxShadow: shadows.xs,
      ":focus": { borderColor: colors.gray[4] },
      color,
    },

    dropdown: {
      marginTop: -4,
    },

    itemsWrapper: {
      padding: 0,
    },

    item: {
      padding: `0.25rem 0.5rem`,
      borderRadius: 0,
      fontWeight: 700,
      color,

      "&[data-selected]": {
        backgroundColor: white,
        color,
      },

      ":hover, &[data-hovered], &[data-selected]:hover": {
        backgroundColor: rgba(color, 0.04),
        color: darken(color, 0.5),
      },
    },
  })
);
