import { Anchor, Group, Stack, Text } from "@mantine/core";
import { Button, DateStringInput, TextInput } from "components";
import { useCheckoutContext } from "contexts/CheckoutContext";
import { useForm } from "hooks";
import omit from "lodash/omit";
import pick from "lodash/pick";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as TechnicalSupportSketch } from "resources/sketches/technical-support.svg";
import { dateString, mobilePhoneString } from "schemas";
import { GRAY } from "theme";
import { z } from "zod";
import { StepModalBottomBar } from "../../StepModalBottomBar";
import { useCheckoutCustomerContext } from "../Context";
import { useStyles } from "./CheckoutCustomerRepresentativeForm.styles";

const customerRepresentativeSchema = z.object({
  last_name: z.string().nonempty(),
  first_name: z.string().nonempty(),
  date_of_birth: dateString(),
  phone: mobilePhoneString(),
  email: z.string().email(),
  job_title: z.string().nonempty(),
});

export const CheckoutCustomerRepresentativeForm: React.FC = () => {
  const { retailer } = useCheckoutContext();
  const {
    data: { session_customer, representative },
    setData,
    nextCustomerFormStep,
    isRepresentative,
  } = useCheckoutCustomerContext();
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { getInputProps, onSubmit } = useForm({
    schema: customerRepresentativeSchema,
    initialValues: {
      ...(isRepresentative
        ? pick(session_customer, "last_name", "first_name", "date_of_birth", "phone", "email")
        : { last_name: "", first_name: "", date_of_birth: "", phone: "", email: "" }),
      job_title: representative?.job_title ?? "",
    },
  });

  const setCustomerRepresentative = useCallback(
    (customerRepresentative: z.infer<typeof customerRepresentativeSchema>) => {
      setData({
        session_customer: {
          ...session_customer,
          ...(isRepresentative ? omit(customerRepresentative, "job_title") : undefined),
        },
        representative: customerRepresentative,
      });
      nextCustomerFormStep();
    },
    [isRepresentative, nextCustomerFormStep, session_customer, setData]
  );

  return (
    <form className={classes.container} noValidate onSubmit={onSubmit(setCustomerRepresentative)}>
      <Stack spacing="sm" className={classes.contentWrapper}>
        <Group position="apart" className={classes.infoWrapper}>
          <Text size="sm">{t("checkout.fillYourInformationCarefully")}</Text>

          <TechnicalSupportSketch className={classes.infoSketch} />
        </Group>

        <TextInput
          color={retailer.style.primary_color}
          label={t("checkout.firstName")}
          required
          autoComplete="given-name"
          {...getInputProps("first_name")}
        />

        <TextInput
          color={retailer.style.primary_color}
          label={t("checkout.lastName")}
          required
          autoComplete="family-name"
          {...getInputProps("last_name")}
        />

        <DateStringInput
          color={retailer.style.primary_color}
          label={t("checkout.dateOfBirth")}
          required
          maxDate={new Date()}
          minDate={new Date("1920-01-01")}
          autoComplete={{
            day: "birth-day",
            month: "birth-month",
            year: "birth-year",
          }}
          {...getInputProps("date_of_birth")}
        />

        <TextInput
          color={retailer.style.primary_color}
          label={t("checkout.phone")}
          type="tel"
          required
          autoComplete="tel"
          {...getInputProps("phone")}
        />

        <TextInput
          color={retailer.style.primary_color}
          label={t("checkout.email")}
          required
          autoComplete="email"
          type="email"
          {...getInputProps("email")}
        />

        <TextInput
          color={retailer.style.primary_color}
          label={t("checkout.jobTitle")}
          required
          {...getInputProps("job_title")}
        />

        <div>
          <Text c={GRAY} size={10}>
            {t("checkout.dataProcessing")}
          </Text>
          <Anchor
            c={GRAY}
            size={10}
            href="https://www.sline.io/politique-de-confidentialite"
            target="_blank"
            rel="noopener noreferrer"
            td="underline"
            lh={1}
          >
            {t("checkout.aboutDataManagement")}
          </Anchor>
        </div>
      </Stack>

      <StepModalBottomBar>
        <Button color={retailer.style.primary_color} type="submit">
          {t("checkout.continue")}
        </Button>
      </StepModalBottomBar>
    </form>
  );
};
