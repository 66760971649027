import { createContext } from "contexts/createContext";
import { Session, SessionAddress } from "types";

export enum CheckoutCustomerFormStep {
  COMPANY = "company",
  INFORMATION = "information",
  REPRESENTATIVE = "representative",
  SHIPPING = "shipping",
  BILLING = "billing",
  VALIDATION = "validation",
}

export type CheckoutCustomerData = Pick<
  Session,
  "session_customer" | "billing_address" | "shipping_address" | "representative"
>;

export enum CompanyAddressType {
  HEADQUARTER = "headquarter",
  ESTABLISHMENT = "establishment",
}

export interface CompanyAddress
  extends Pick<
    SessionAddress,
    "street_address" | "street_address_2" | "zip_code" | "city" | "country"
  > {
  type: CompanyAddressType;
}

export interface CheckoutCustomerContextValue {
  customerSteps: CheckoutCustomerFormStep[];
  currentCustomerFormStep: CheckoutCustomerFormStep;
  nextCustomerFormStep: () => CheckoutCustomerFormStep;
  previousCustomerFormStep: () => CheckoutCustomerFormStep;
  resetCustomerFormStep: () => CheckoutCustomerFormStep;

  data: CheckoutCustomerData;
  setData: (data: Partial<CheckoutCustomerData>) => void;

  companyAddresses: CompanyAddress[];
  setCompanyAddresses: (companyAddresses: CompanyAddress[]) => void;

  isRepresentative: boolean;
  setIsRepresentative: (isRepresentative: boolean) => void;

  isShippingPersonDifferent: boolean;
  setIsShippingPersonDifferent: (isShippingPersonDifferent: boolean) => void;

  isBillingAddressDifferent: boolean;
  setIsBillingAddressDifferent: (isBillingAddressDifferent: boolean) => void;

  validate: () => void;
  isValidating: boolean;
}

export const [useCheckoutCustomerContext, _CheckoutCustomerContextProvider] =
  createContext<CheckoutCustomerContextValue>();
