import { Group } from "@mantine/core";
import { IconCircleCheckFilled } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { Button, TextInput } from "components";
import { useCheckoutContext } from "contexts/CheckoutContext";
import { useForm } from "hooks";
import React from "react";
import { useTranslation } from "react-i18next";
import { updateSessionStagingPayloadId as _updateSessionStagingPayloadId } from "services/api/session";
import { z } from "zod";

const statingPayloadSchema = z.object({
  staging_payload_id: z.string().uuid(),
});

export const CheckoutStagingPayload: React.FC = () => {
  const { retailer, session } = useCheckoutContext();
  const { t } = useTranslation();

  const { getInputProps, isDirty, resetDirty, onSubmit } = useForm({
    schema: statingPayloadSchema,
    initialValues: {
      staging_payload_id: session.staging_payload_id ?? "",
    },
  });

  const {
    mutate: updateSessionStagingPayloadId,
    isLoading,
    isSuccess,
  } = useMutation({
    mutationFn: (staging_payload_id: string) =>
      _updateSessionStagingPayloadId({ id: session.id, staging_payload_id }),
    onSuccess: () => resetDirty(),
  });

  if (import.meta.env.MODE === "production") return null;

  return (
    <form
      noValidate
      onSubmit={onSubmit(({ staging_payload_id }) =>
        updateSessionStagingPayloadId(staging_payload_id)
      )}
    >
      <Group spacing="xs" my="sm" align="end">
        <TextInput
          color={retailer.style.primary_color}
          label={t("checkout.scoringId")}
          style={{ flex: 1 }}
          rightSection={
            isSuccess &&
            !isDirty() && <IconCircleCheckFilled style={{ color: retailer.style.primary_color }} />
          }
          {...getInputProps("staging_payload_id")}
        />

        <Button
          color={retailer.style.primary_color}
          h={42}
          disabled={!isDirty()}
          loading={isLoading}
          type="submit"
        >
          {t("checkout.validate")}
        </Button>
      </Group>
    </form>
  );
};
