import { Group, Paper, Text } from "@mantine/core";
import { useDidUpdate, useDisclosure } from "@mantine/hooks";
import { IconChevronRight } from "@tabler/icons-react";
import { CheckoutStep, useCheckoutContext } from "contexts/CheckoutContext";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as SignatureSketch } from "resources/sketches/signature.svg";
import { CheckoutContractSignatureModal } from "./CheckoutContractSignatureModal";
import { useStyles } from "./CheckoutContractSignatureStep.styles";

export const CheckoutContractSignatureStep: React.FC = () => {
  const { currentStep, steps } = useCheckoutContext();
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure();
  const { classes } = useStyles();
  const { t } = useTranslation();

  const status = useMemo(() => {
    if (!steps.includes(CheckoutStep.CONTRACT_SIGNATURE)) return null;

    if (steps.slice(steps.indexOf(currentStep) + 1).includes(CheckoutStep.CONTRACT_SIGNATURE))
      return "incomplete";

    if (currentStep === CheckoutStep.CONTRACT_SIGNATURE) return "active";

    return "complete";
  }, [currentStep, steps]);

  useEffect(() => {
    if (currentStep === CheckoutStep.CONTRACT_SIGNATURE) {
      setTimeout(openModal, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDidUpdate(() => {
    if (currentStep === CheckoutStep.CONTRACT_SIGNATURE) {
      setTimeout(openModal, 1000);
    }
  }, [currentStep]);

  if (!status) return null;

  return (
    <>
      <Paper
        px="sm"
        py="xs"
        radius="lg"
        withBorder
        className={classes.card}
        data-status={status}
        onClick={openModal}
      >
        <Group position="apart" h="100%" spacing="sm">
          <Group spacing="sm">
            <SignatureSketch className={classes.sketch} />

            <Text fw={700} className={classes.title}>
              {t("checkout.contractSignature")}
            </Text>
          </Group>

          <IconChevronRight className={classes.chevron} />
        </Group>
      </Paper>

      <CheckoutContractSignatureModal
        opened={status === "active" && modalOpened}
        close={closeModal}
      />
    </>
  );
};
