import { useOs } from "@mantine/hooks";
import { useCallback, useEffect, useMemo } from "react";

export const VISUAL_VIEWPORT_HEIGHT_CSS_VARIABLE_NAME = "--visual-viewport-height";

export const useVisualViewportHeight = (setup = false) => {
  const os = useOs();

  const preventViewportOffset = useCallback((event: TouchEvent) => event.preventDefault(), []);

  const supportsDVH = useMemo(() => CSS.supports("height: 100dvh"), []);

  const setVisualViewportCSSVariable = useCallback(() => {
    const root = document.getElementById("root");

    if (!(root instanceof HTMLDivElement)) throw Error("React app should create a root div.");

    root.style.setProperty(
      VISUAL_VIEWPORT_HEIGHT_CSS_VARIABLE_NAME,
      window.visualViewport && os === "ios"
        ? `${window.visualViewport.height + window.visualViewport.offsetTop}px`
        : supportsDVH
        ? "100dvh"
        : `${document.documentElement.clientHeight}px`
    );
  }, [os, supportsDVH]);

  document.addEventListener("touchmove", preventViewportOffset);

  useEffect(() => {
    if (!setup) return;

    setVisualViewportCSSVariable();

    if (window.visualViewport && os === "ios") {
      window.visualViewport?.addEventListener("resize", setVisualViewportCSSVariable);
      window.visualViewport?.addEventListener("scroll", setVisualViewportCSSVariable);

      return () => {
        window.visualViewport?.removeEventListener("resize", setVisualViewportCSSVariable);
        window.visualViewport?.removeEventListener("scroll", setVisualViewportCSSVariable);
      };
    }

    if (supportsDVH) return;

    window.addEventListener("resize", setVisualViewportCSSVariable);

    return () => window.removeEventListener("resize", setVisualViewportCSSVariable);
  }, [setup, setVisualViewportCSSVariable, preventViewportOffset, os, supportsDVH]);

  return `var(${VISUAL_VIEWPORT_HEIGHT_CSS_VARIABLE_NAME})`;
};
