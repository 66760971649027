export const DARK_BLUE = "#3E4E63";
export const BLUE = "#005CFF";

export const DARK_GRAY = "#545454";
export const GRAY = "#A2A2A2";
export const LIGHT_GRAY = "#F3F3F3";

export const LIGHT_GREEN = "#F2F8F2";
export const GREEN = "#28A138";

export const LIGHT_RED = "#FFE2E0";
export const RED = "#D21C1C";
