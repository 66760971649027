import { z } from "zod";
import { apiClient } from "../client";

const responseDataSchema = z.object({
  client_secret: z.string(),
});

export const setupIdentityVerification = async (sessionId: string) =>
  responseDataSchema.parse((await apiClient.post(`sessions/${sessionId}/judy_identity_check`)).data)
    .client_secret;
