import { createStyles, getStylesRef } from "@mantine/core";
import { SMALL_SCREEN_BREAKPOINT } from "theme";
import { CHECKOUT_HEADER_HEIGHT } from "../../Header/CheckoutHeader.styles";

export const useModalStyles = createStyles(({ fn: { smallerThan } }) => ({
  root: {
    ref: getStylesRef("modal-root"),
  },

  inner: {
    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      paddingTop: CHECKOUT_HEADER_HEIGHT,
    },
  },

  content: {
    [`.${getStylesRef("modal-root")}[data-current-step='customer'] &`]: {
      [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
      },
    },
  },

  body: {
    padding: "0 !important",

    [`.${getStylesRef("modal-root")}[data-current-step='customer'] &`]: {
      [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
        flex: 1,
        minHeight: 0,
      },
    },
  },
}));

export const useDrawerStyles = createStyles(({ fontSizes, spacing, colors }) => ({
  header: {
    padding: `${spacing.lg} ${spacing.xs} ${spacing.sm}`,
    borderBottomStyle: "solid",
    borderBottomColor: colors.gray[3],
    borderWidth: 1,
  },

  title: {
    fontSize: fontSizes.lg,
    fontWeight: 700,
  },

  content: {
    display: "flex",
    flexDirection: "column",
    padding: `0 ${spacing.md}`,
  },

  body: {
    padding: `0!important`,
    margin: `0 -${spacing.xs}`,
    flex: 1,
    position: "relative",
  },

  bottomBar: {
    position: "sticky",
    bottom: 0,
    margin: `0 -${spacing.xs}`,
  },
}));
