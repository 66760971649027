import { TextInput as MantineTextInput, TextInputProps } from "@mantine/core";
import React, { useState } from "react";
import { useStyles } from "./TextInput.styles";

type Props = Omit<TextInputProps, "classNames" | "color"> & {
  color: string;
  empty?: boolean;
};

export const TextInput = React.forwardRef<HTMLInputElement, Props>(
  ({ color, empty, onFocus, onBlur, value, error, ...props }, ref) => {
    const [focused, setFocused] = useState(false);
    const { classes } = useStyles({
      color,
      focused,
      empty: empty === undefined ? !value : empty,
      withLabel: !!props.label,
      error: !!error,
    });

    return (
      <MantineTextInput
        ref={ref}
        classNames={classes}
        onFocus={(event) => {
          setFocused(true);
          onFocus?.(event);
        }}
        onBlur={(event) => {
          setFocused(false);
          onBlur?.(event);
        }}
        value={value}
        error={!!error}
        {...props}
      />
    );
  }
);
TextInput.displayName = "TextInput";
