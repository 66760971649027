import { Select as MantineSelect, SelectProps } from "@mantine/core";
import React from "react";
import { useStyles } from "./Select.styles";

type Props = Omit<SelectProps, "classNames"> & {
  color: string;
};

export const Select = React.forwardRef<HTMLInputElement, Props>(({ color, ...props }, ref) => {
  const { classes } = useStyles({ color });

  return <MantineSelect ref={ref} classNames={classes} {...props} />;
});
Select.displayName = "Select";
