import { DropzoneProps, Dropzone as MantineDropzone } from "@mantine/dropzone";
import React from "react";
import { useStyles } from "./Dropzone.styles";

type Props = Omit<DropzoneProps, "classNames"> & {
  color: string;
};

export const Dropzone = React.forwardRef<HTMLDivElement, Props>(({ color, ...props }, ref) => {
  const { classes } = useStyles({ color });

  return <MantineDropzone ref={ref} classNames={classes} {...props} />;
});
Dropzone.displayName = "Dropzone";
