import { Anchor, Group, List, Loader, Stack, Text } from "@mantine/core";
import { IconArrowRight, IconCheck } from "@tabler/icons-react";
import { Button, Checkbox, CircledIcon } from "components";
import { useCheckoutContext } from "contexts/CheckoutContext";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as SecuritySketch } from "resources/sketches/security.svg";
import { GRAY, GREEN, LIGHT_GREEN } from "theme";
import { SessionStepStatus } from "types";
import { StepModalBottomBar } from "../StepModalBottomBar";
import { useStyles } from "./CheckoutOpenBanking.styles";

interface Props {
  openDetails: () => void;
}

export const CheckoutOpenBanking: React.FC<Props> = ({ openDetails }) => {
  const { session, retailer, requestOpenBanking, isRequestingOpenBanking } = useCheckoutContext();
  const [conditionsAgreed, setConditionsAgreed] = useState(false);
  const { t } = useTranslation();
  const { classes } = useStyles();

  const isOpenBankingStarted = useMemo(
    () => session.steps?.open_banking?.status !== SessionStepStatus.NOT_STARTED,
    [session.steps?.open_banking?.status]
  );

  return (
    <div className={classes.container}>
      {isOpenBankingStarted ? (
        <Stack align="center" className={classes.wrapper}>
          <Loader size={60} color={retailer.style.primary_color} />
          <Text w="100%">{t("checkout.waitingBankResponse")}</Text>

          <Anchor
            fw={700}
            size="sm"
            td="underline"
            onClick={openDetails}
            className={classes.detailsAnchor}
          >
            <Group spacing="0.25em">
              <Text>{t("checkout.moreInformationOnOpenBanking")}</Text>
              <IconArrowRight size="1.5em" />
            </Group>
          </Anchor>
        </Stack>
      ) : (
        <Stack className={classes.wrapper}>
          <SecuritySketch className={classes.sketch} />

          <Stack spacing="sm">
            <Text fw={700}>{t("checkout.howItWorks")}</Text>

            <List
              icon={
                <CircledIcon
                  icon={IconCheck}
                  color={GREEN}
                  backgroundColor={LIGHT_GREEN}
                  size={24}
                />
              }
              size="sm"
              spacing="xs"
              center
              classNames={{ itemIcon: classes.listItemIcon }}
            >
              {Array.from({ length: 4 }).map((_, index) => (
                <List.Item key={`openBankingProcess-${index}`}>
                  {t(`checkout.openBankingProcess.${index}`)}
                </List.Item>
              ))}
            </List>
          </Stack>

          <Stack spacing="sm">
            <Checkbox
              color={retailer.style.primary_color}
              checked={conditionsAgreed}
              onChange={(event) => setConditionsAgreed(event.target.checked)}
              label={
                <Text c={GRAY} size="xs">
                  {t("checkout.allowSlineToUseData")}
                </Text>
              }
            />

            <Text size="xs">{t("checkout.allowSlineToConnectToBankAccount")}</Text>
          </Stack>

          <Anchor
            fw={700}
            size="sm"
            td="underline"
            onClick={openDetails}
            className={classes.detailsAnchor}
          >
            <Group spacing="0.25em">
              <Text>{t("checkout.moreInformationOnOpenBanking")}</Text>
              <IconArrowRight size="1.5em" />
            </Group>
          </Anchor>
        </Stack>
      )}

      <StepModalBottomBar>
        <Button
          color={retailer.style.primary_color}
          disabled={!conditionsAgreed}
          onClick={requestOpenBanking}
          loading={isRequestingOpenBanking}
        >
          {t("checkout.connectToMyBankAccount")}
        </Button>
      </StepModalBottomBar>
    </div>
  );
};
