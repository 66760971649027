import { Button as MantineButton, ButtonProps as MantineButtonProps } from "@mantine/core";
import React from "react";
import { useStyles } from "./Button.styles";

export type ButtonProps = Omit<
  import("@mantine/utils").PolymorphicComponentProps<"button", MantineButtonProps>,
  "variant" | "color" | "classNames"
> & { color: string; variant?: "primary" | "secondary" | "ghost" };

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ color, variant, ...props }, ref) => {
    const { classes } = useStyles({ color, variant });

    return <MantineButton ref={ref} variant="filled" classNames={classes} {...props} />;
  }
);
Button.displayName = "Button";
