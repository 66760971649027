import axios from "axios";

const REQUEST_TIMEOUT = 60000; // 1 minute

export const pappersApiClient = axios.create({
  baseURL: import.meta.env.VITE_PAPPERS_API_BASE_URL,
  timeout: REQUEST_TIMEOUT,
});

export const pappersSearchApiClient = axios.create({
  baseURL: import.meta.env.VITE_PAPPERS_SEARCH_API_URL,
  timeout: REQUEST_TIMEOUT,
});
