import { createStyles, getStylesRef } from "@mantine/core";

interface Params {
  color: string;
}

export const useStyles = createStyles<string, Params>(({ spacing }, { color }) => ({
  radio: {
    ref: getStylesRef("radio"),
    borderWidth: 2,
    borderColor: color,

    ":checked": {
      backgroundColor: color,
      borderColor: color,
    },
  },

  label: {
    paddingLeft: spacing.xs,
    fontWeight: 600,
  },
}));
