import { Stack } from "@mantine/core";
import { IconPencil } from "@tabler/icons-react";
import { Button, Checkbox } from "components";
import { useCheckoutContext } from "contexts/CheckoutContext";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { StepModalBottomBar } from "../../StepModalBottomBar";
import { useCheckoutCustomerContext } from "../Context";
import { CheckoutCustomerDetails } from "../Details";
import { useStyles } from "./CheckoutCustomerValidationForm.styles";

export const CheckoutCustomerValidationForm: React.FC = () => {
  const { retailer } = useCheckoutContext();
  const { resetCustomerFormStep, validate, isValidating } = useCheckoutCustomerContext();
  const [isDataCertified, setIsDataCertified] = useState(false);
  const { t } = useTranslation();
  const { classes } = useStyles({ color: retailer.style.primary_color });

  return (
    <div className={classes.container}>
      <div className={classes.contentWrapper}>
        <Stack spacing="sm">
          <CheckoutCustomerDetails />

          <Checkbox
            checked={isDataCertified}
            onChange={(event) => setIsDataCertified(event.target.checked)}
            color={retailer.style.primary_color}
            label={t("checkout.certifyExactData")}
            size="sm"
            fw={700}
          />
        </Stack>
      </div>

      <StepModalBottomBar>
        <Button
          color={retailer.style.primary_color}
          fullWidth
          disabled={!isDataCertified}
          onClick={validate}
          loading={isValidating}
        >
          {t("checkout.validate")}
        </Button>

        <Button
          variant="ghost"
          leftIcon={<IconPencil />}
          color={retailer.style.primary_color}
          fullWidth
          onClick={resetCustomerFormStep}
        >
          {t("checkout.editInformation")}
        </Button>
      </StepModalBottomBar>
    </div>
  );
};
