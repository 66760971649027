import { buildCheckoutOrder, getSelectedDuration } from "helpers";
import { useRetailerQuery, useSessionQuery } from "hooks";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { TrackingEventData } from "services/analytics";
import { CustomerType } from "types";

export const useTrackingEventData = (): TrackingEventData => {
  const { sessionId } = useParams<"sessionId">();
  const { session, expiredSession } = useSessionQuery(sessionId);

  const { retailerId } = useParams<"retailerId">();
  const { retailer } = useRetailerQuery(retailerId ?? session?.retailer_id);

  const selectedDuration = useMemo(() => {
    if (!session || !retailer) return undefined;

    return getSelectedDuration(session, retailer);
  }, [retailer, session]);

  const order = useMemo(() => {
    if (!session || !retailer || !selectedDuration) return undefined;

    return buildCheckoutOrder(session, retailer, selectedDuration);
  }, [session, retailer, selectedDuration]);

  return {
    retailerName: retailer?.name || expiredSession?.retailer_name,
    firstInstalment: order?.selectedDuration.totalFirstInstalmentPriceIncludingVAT.getAmount(),
    othersInstalments: order?.selectedDuration.totalOtherInstalmentPriceIncludingVAT.getAmount(),
    durations: selectedDuration,
    customerType:
      session?.session_customer.customer_type !== null
        ? session?.session_customer.customer_type
        : undefined,
    rentedProducts: order?.selectedDuration.rentedItems.length,
    purchasedProducts: order?.selectedDuration.purchasedItems.length,
    additionalProducts: order?.selectedDuration.includedItems.length,
    webScore:
      (session?.session_customer.customer_type === CustomerType.PERSON &&
        retailer?.scoring_configuration.b2c_web_scoring) ||
      (session?.session_customer.customer_type === CustomerType.COMPANY &&
        retailer?.scoring_configuration.b2b_web_scoring),
  };
};
