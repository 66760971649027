import { Group, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconArrowLeft } from "@tabler/icons-react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DARK_GRAY } from "theme";
import { StepModal } from "../StepModal";
import { CheckoutOpenBanking } from "./CheckoutOpenBanking";
import { CheckoutOpenBankingDetails } from "./CheckoutOpenBankingDetails";

interface Props {
  opened: boolean;
  close: () => void;
}

export const CheckoutOpenBankingModal: React.FC<Props> = ({ opened, close }) => {
  const [detailsOpened, { close: closeDetails, open: openDetails }] = useDisclosure();
  const { t } = useTranslation();

  const modalTitle = useMemo(() => {
    if (detailsOpened) {
      return (
        <Group spacing="sm">
          <IconArrowLeft color={DARK_GRAY} onClick={closeDetails} style={{ cursor: "pointer" }} />
          <Text span>{t("checkout.moreInformation")}</Text>
        </Group>
      );
    }

    return t("checkout.bankingConnection");
  }, [closeDetails, detailsOpened, t]);

  return (
    <StepModal opened={opened} onClose={close} title={modalTitle}>
      {detailsOpened ? (
        <CheckoutOpenBankingDetails />
      ) : (
        <CheckoutOpenBanking openDetails={openDetails} />
      )}
    </StepModal>
  );
};
