import { Group, Paper, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronRight } from "@tabler/icons-react";
import { CheckoutStep, useCheckoutContext } from "contexts/CheckoutContext";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as FilesSearchSketch } from "resources/sketches/files-search.svg";
import { useStyles } from "./CheckoutCustomerStep.styles";
import { CheckoutCustomerContextProvider } from "./Context";
import { CheckoutCustomerModal } from "./Modal";

export const CheckoutCustomerStep: React.FC = () => {
  const { currentStep } = useCheckoutContext();
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure(
    currentStep === CheckoutStep.ENVELOPPE_FINANCING || currentStep === CheckoutStep.WEB_SCORING
  );
  const [status, setStatus] = useState<string>(() => {
    switch (currentStep) {
      case CheckoutStep.CUSTOMER:
        return "incomplete";
      case CheckoutStep.ENVELOPPE_FINANCING:
      case CheckoutStep.WEB_SCORING:
        return "active";
      default:
        return "completed";
    }
  });
  const { classes } = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    if (
      currentStep === CheckoutStep.CUSTOMER ||
      currentStep === CheckoutStep.ENVELOPPE_FINANCING ||
      currentStep === CheckoutStep.WEB_SCORING
    ) {
      if (status === "incomplete") {
        setStatus("active");
        setTimeout(openModal, 1000);
      }
    } else {
      setStatus("completed");
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  return (
    <CheckoutCustomerContextProvider>
      <Paper
        px="sm"
        py="xs"
        radius="lg"
        withBorder
        className={classes.card}
        data-status={status}
        onClick={openModal}
      >
        <Group position="apart" h="100%" spacing="sm">
          <Group spacing="sm">
            <FilesSearchSketch className={classes.sketch} />

            <Text fw={700} className={classes.title}>
              {t("checkout.information")}
            </Text>
          </Group>

          <IconChevronRight />
        </Group>
      </Paper>

      <CheckoutCustomerModal opened={modalOpened} close={closeModal} />
    </CheckoutCustomerContextProvider>
  );
};
