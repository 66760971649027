import { useInterval } from "@mantine/hooks";
import dayjs, { Dayjs } from "dayjs";
import { refetchSessionQuery, updateSessionQuery } from "hooks";
import { useEffect, useRef } from "react";
import { requestWebScoring } from "services/api/session";
import { Session, SessionStepStatus } from "types";
import { CheckoutStep } from "../../CheckoutContext";

const REFETCH_INTERVAL = 1000;
export const MINIMUM_WEB_SCORING_WAITING_DURATION = 2000;

export const useWebScoring = (
  session: Session,
  currentStep: CheckoutStep,
  nextStep: () => CheckoutStep
) => {
  const { start: startRefetching, stop: stopRefetching } = useInterval(
    () => refetchSessionQuery(session.id),
    REFETCH_INTERVAL
  );
  const webScoringRequestedDate = useRef<Dayjs | null>(null);

  const webScoringStatus = session.steps?.web_scoring?.status;

  useEffect(() => {
    if (currentStep !== CheckoutStep.WEB_SCORING) return;

    if (webScoringStatus === SessionStepStatus.NOT_STARTED) {
      webScoringRequestedDate.current = dayjs();

      requestWebScoring(session.id).then(() =>
        updateSessionQuery(session.id, {
          steps: {
            ...session.steps,
            web_scoring: {
              status: SessionStepStatus.PENDING,
              error_code: null,
            },
          },
        })
      );
      return;
    }

    if (webScoringStatus === SessionStepStatus.PENDING) {
      startRefetching();
      return;
    }

    stopRefetching();

    // ? We want the user to wait at least a few seconds before redirection or next step
    const waitingDuration = (webScoringRequestedDate.current ?? dayjs())
      .add(MINIMUM_WEB_SCORING_WAITING_DURATION, "milliseconds")
      .diff(dayjs());

    setTimeout(nextStep, waitingDuration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, webScoringStatus]);
};
