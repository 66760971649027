import { useQuery } from "@tanstack/react-query";
import { getRetailer } from "services/api/retailer";

export const useRetailerQuery = (retailerId: string | undefined) => {
  const { data: retailer, ...queryResult } = useQuery({
    queryKey: ["retailer", String(retailerId)],
    queryFn: () => getRetailer(String(retailerId)),
    retry: false,
    retryOnMount: false,
    staleTime: Infinity,
    enabled: !!retailerId,
  });

  return {
    retailer,
    ...queryResult,
  };
};
