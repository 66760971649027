import React from "react";
import { useTranslation } from "react-i18next";
import { StepModal } from "../../StepModal";
import { CheckoutPayment } from "../CheckoutPayment";
import { InstalmentPayment } from "../types";

interface Props {
  opened: boolean;
  close: () => void;
  instalmentPayment: InstalmentPayment;
  setInstalmentPayment: (instalmentPayment: InstalmentPayment) => void;
}

export const CheckoutPaymentModal: React.FC<Props> = ({
  opened,
  close,
  instalmentPayment,
  setInstalmentPayment,
}) => {
  const { t } = useTranslation();

  return (
    <StepModal
      opened={opened}
      onClose={close}
      title={t(`checkout.instalmentPayments.${instalmentPayment}`)}
    >
      <CheckoutPayment
        instalmentPayment={instalmentPayment}
        setInstalmentPayment={setInstalmentPayment}
      />
    </StepModal>
  );
};
