import { Group, Modal, Paper, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronRight } from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as OrderSketch } from "resources/sketches/order.svg";
import { CheckoutOrder } from "../../Order";
import { useStyles } from "./CheckoutOrderStep.styles";

export const CheckoutOrderStep: React.FC = () => {
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure();
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Paper
        px="sm"
        py="xs"
        radius="lg"
        withBorder
        className={classes.card}
        data-status="complete"
        onClick={openModal}
      >
        <Group position="apart" h="100%" spacing="sm">
          <Group spacing="sm">
            <OrderSketch className={classes.sketch} />

            <Text fw={700}>{t("checkout.order")}</Text>
          </Group>

          <IconChevronRight />
        </Group>
      </Paper>

      <Modal
        opened={modalOpened}
        onClose={closeModal}
        size="lg"
        closeOnClickOutside={false}
        title={t("checkout.orderDetails")}
      >
        <CheckoutOrder />
      </Modal>
    </>
  );
};
