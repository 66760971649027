import { Stripe } from "@stripe/stripe-js";
import { CheckoutOrder } from "helpers";
import { RentalStatus, Retailer, Session } from "types";
import { createContext } from "../createContext";

export enum CheckoutStep {
  CUSTOMER = "customer",
  ENVELOPPE_FINANCING = "enveloppe-financing",
  WEB_SCORING = "web-scoring",
  OPEN_BANKING = "open-banking",
  ID_VERIFICATION = "id-verification",
  DOCUMENTS = "documents",
  PAYMENT = "payment",
  CONTRACT_SIGNATURE = "contract-signature",
  END = "end",
}

export enum IdentityVerificationError {
  INCONSISTENT = "inconsistent",
  NON_COMPLIANT = "non-compliant",
  UNKNOWN = "unknown",
}

export interface CheckoutContextValue {
  session: Session;
  retailer: Retailer;
  steps: CheckoutStep[];
  currentStep: CheckoutStep;
  completedSteps: CheckoutStep[];
  nextStep: () => CheckoutStep;

  selectedDuration: number;
  setSelectedDuration: (duration: number) => void;

  order: CheckoutOrder;

  stripe: Stripe | null;

  requestOpenBanking: () => void;
  isRequestingOpenBanking: boolean;

  startIdentityVerification: () => void;
  isStartingIdentityVerification: boolean;
  identityVerificationError: IdentityVerificationError | null;
  resetIdentityVerificationError: () => void;

  firstPaymentClientSecret: string | undefined;
  otherPaymentClientSecret: string | undefined;

  rentalStatus: RentalStatus | undefined;
}

export const [useCheckoutContext, _CheckoutContextProvider] = createContext<CheckoutContextValue>();
