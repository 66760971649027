import { createStyles, rem } from "@mantine/core";

interface Params {
  color?: string;
  backgroundColor?: string;
  withBorder?: boolean;
  size?: string | number;
}

export const useStyles = createStyles<string, Params>(
  ({ radius }, { color, backgroundColor, withBorder = false, size = rem(20) }) => ({
    container: {
      borderRadius: radius.xl,
      backgroundColor,
      width: size,
      height: size,
      ...(withBorder
        ? { borderStyle: "solid", borderWidth: rem(2), borderColor: color }
        : undefined),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    icon: {
      height: "70%",
      width: "70%",
      color,
    },
  })
);
