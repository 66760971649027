import { Center, Group, Stack, Text, Title } from "@mantine/core";
import { Header } from "components";
import { useVisualViewportHeight } from "hooks";
import { usePageViewTracking } from "hooks/analytics";
import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ErrorSketch } from "resources/sketches/error.svg";
import { BLUE } from "theme";
import { useStyles } from "./ErrorPage.styles";

export const ErrorPage: React.FC = () => {
  usePageViewTracking("error");

  const { t } = useTranslation();
  const visualViewportHeight = useVisualViewportHeight();
  const { classes } = useStyles();

  return (
    <>
      <Header />
      <Stack spacing="xl" justify="space-between" mih={visualViewportHeight}>
        <Center py="4rem" px="sm">
          <Stack align="center" maw={800} spacing="lg">
            <Group spacing="lg" align="start">
              <Stack spacing="lg" className={classes.container}>
                <Title size="h3">{t(`error.title`)}</Title>
                <Text>{t(`error.message`)}</Text>
              </Stack>
              <ErrorSketch className={classes.sketch} color={BLUE} />
            </Group>
          </Stack>
        </Center>
      </Stack>
    </>
  );
};
