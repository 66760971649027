import { Divider, Stack, Text } from "@mantine/core";
import { useCheckoutContext } from "contexts/CheckoutContext";
import { sum } from "lodash";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useStyles } from "./CheckoutOrderIncludedItems.styles";

export const CheckoutOrderIncludedItems: React.FC = () => {
  const { order, retailer } = useCheckoutContext();
  const { classes } = useStyles({ color: retailer.style.primary_color });
  const { t } = useTranslation();

  if (order.selectedDuration.includedItems.length === 0) return null;

  return (
    <Stack spacing="sm">
      <Divider
        label={t("checkout.includedItemsCount", {
          count: sum(
            order.selectedDuration.includedItems.map((includedItem) => includedItem.quantity)
          ),
        })}
        labelPosition="center"
        classNames={{ label: classes.dividerLabel }}
      />

      {order.selectedDuration.includedItems.map((includedItem) => (
        <Text size="sm" key={includedItem.id}>
          <Trans
            i18nKey="checkout.itemNameWithQuantity"
            values={{ name: includedItem.name, count: includedItem.quantity }}
            components={{ b: <b /> }}
          />
        </Text>
      ))}
    </Stack>
  );
};
