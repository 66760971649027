import { CustomerType } from "types";

export enum TrackingEventType {
  PAGE_VIEW = "PAGE_VIEW",
  EVENT = "EVENT",
  GOAL = "GOAL",
}

export enum TrackingEventCategory {
  VISIT = "VISIT",
  PAGE_NAME = "PAGE_NAME",
  SCROLL = "SCROLL",
  LINK = "LINK",
  BUTTON = "BUTTON",
  FORM_EVENTS = "FORM_EVENTS",
}

export enum TrackingEventAction {
  FIRST_VISIT = "FIRST_VISIT",
  OPEN_NEW_PAGE = "OPEN_NEW_PAGE",
  CONSULT = "CONSULT",
  SCROLL_50_PERCENT = "SCROLL_50_PERCENT",
  SUBMIT_FORM = "SUBMIT_FORM",
  CLICK = "CLICK",
}

declare global {
  interface Window {
    _mtm: unknown[] | undefined;
    _paq: { push: (...args: unknown[]) => void } | undefined;
    _mode: string | undefined;
  }
}

export interface TrackingEventData {
  retailerName?: string;
  firstInstalment?: number;
  othersInstalments?: number;
  durations?: number;
  customerType?: CustomerType;
  rentedProducts?: number;
  purchasedProducts?: number;
  additionalProducts?: number;
  openBanking?: string;
  webScore?: boolean;
}

export interface TrackingEventParams {
  type: TrackingEventType;
  category: TrackingEventCategory;
  action: TrackingEventAction;
  name?: string;
  eventData?: TrackingEventData;
}
