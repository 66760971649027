import { createStyles } from "@mantine/core";

export const CHECKOUT_HEADER_HEIGHT = 72;

export const useStyles = createStyles(({ shadows }) => ({
  header: {
    position: "relative",
    height: CHECKOUT_HEADER_HEIGHT,
    boxShadow: shadows.xl,
    zIndex: 1,
  },
  logoRetailer: {
    maxHeight: CHECKOUT_HEADER_HEIGHT - 24,
  },
}));
