import { financingEnveloppeRequestSchema } from "schemas";
import { Financier } from "types";
import { apiClient } from "../client";

interface CreateParams {
  session_id: string;
  financer: Financier;
  company_registration_number: string;
}

export const createFinancingEnveloppeRequest = async (params: CreateParams) =>
  financingEnveloppeRequestSchema.parse(
    (await apiClient.post(`financing_enveloppe_requests`, params)).data
  );
