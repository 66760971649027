import { Drawer, Modal } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React from "react";
import { MEDIUM_SCREEN_BREAKPOINT } from "theme";
import { MEDIUM_SCREEN_MAX_WIDTH } from "../CheckoutSteps.styles";
import { useDrawerStyles, useModalStyles } from "./StepModal.styles";

interface Props {
  opened: boolean;
  onClose(): void;
  title: React.ReactNode;
  children: React.ReactNode;
  "data-current-step"?: string;
}

export const StepModalIdContext = React.createContext<string | null>(null);

export const StepModal: React.FC<Props> = (props) => {
  const isMediumScreen = useMediaQuery(`(max-width: ${MEDIUM_SCREEN_BREAKPOINT}px)`);
  const { classes: modalClasses } = useModalStyles();
  const { classes: drawerClasses } = useDrawerStyles();

  if (isMediumScreen) {
    return (
      <Modal
        size={MEDIUM_SCREEN_MAX_WIDTH}
        closeOnClickOutside={false}
        classNames={modalClasses}
        {...props}
      />
    );
  }

  return <Drawer classNames={drawerClasses} size="lg" position="right" {...props} />;
};
