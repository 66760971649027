import { Flex } from "@mantine/core";
import { CheckoutStep, useCheckoutContext } from "contexts/CheckoutContext";
import React, { useMemo, useState } from "react";
import { RentalStatus } from "types";
import { CheckoutHeader } from "./Header";
import { CheckoutPendingContractSignature } from "./PendingContractSignature/CheckoutPendingContractSignature";
import { CheckoutPlan } from "./Plan";
import { CheckoutSteps } from "./Steps";

export const Checkout: React.FC = () => {
  const { currentStep, rentalStatus } = useCheckoutContext();
  const [isPlanSelected, setIsPlanSelected] = useState(currentStep !== CheckoutStep.CUSTOMER);

  const body = useMemo(() => {
    if (!isPlanSelected) return <CheckoutPlan onPlanSelected={() => setIsPlanSelected(true)} />;

    if (rentalStatus === RentalStatus.PENDING_E_SIGNATURE)
      return <CheckoutPendingContractSignature />;

    return <CheckoutSteps />;
  }, [isPlanSelected, rentalStatus]);

  return (
    <Flex direction="column" h="100%">
      <CheckoutHeader />
      {body}
    </Flex>
  );
};
