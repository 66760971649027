import { createStyles } from "@mantine/core";
import { MEDIUM_SCREEN_BREAKPOINT, SMALL_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(({ fn: { smallerThan } }) => ({
  container: {
    alignItems: "flex-start",
    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      alignItems: "stretch",
    },
  },

  sketch: {
    flexShrink: 0,
    [smallerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
      display: "none",
    },
  },
}));
