import { CompanyDetail } from "types";
import { pappersApiClient } from "./client";

export const getCompany = async (siret: string) =>
  (
    await pappersApiClient.get<CompanyDetail>(
      `entreprise?api_token=${
        import.meta.env.VITE_PAPPERS_API_TOKEN
      }&bases=entreprises&siret=${siret}`
    )
  ).data;
