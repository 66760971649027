import { createStyles } from "@mantine/core";
import { MEDIUM_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles<string, boolean>(
  ({ spacing, fn: { smallerThan, largerThan } }, onlyOnLargeScreen) => ({
    container: {
      mb: onlyOnLargeScreen ? 0 : spacing.md,
      [smallerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
        display: onlyOnLargeScreen ? "none" : undefined,
      },
      [largerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
        justifyContent: "flex-start",
      },
    },

    sketch: {
      flexShrink: 0,
    },
  })
);
