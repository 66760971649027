import { Anchor, Group, Stack, Text } from "@mantine/core";
import { Button, DateStringInput, TextInput } from "components";
import { useCheckoutContext } from "contexts/CheckoutContext";
import { useForm } from "hooks";
import pick from "lodash/pick";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as TechnicalSupportSketch } from "resources/sketches/technical-support.svg";
import { dateString, mobilePhoneString } from "schemas";
import { GRAY } from "theme";
import { CustomerType } from "types";
import { z } from "zod";
import { StepModalBottomBar } from "../../StepModalBottomBar";
import { useCheckoutCustomerContext } from "../Context";
import { useStyles } from "./CheckoutCustomerInformationForm.styles";

const personCustomerInformationSchema = z.object({
  last_name: z.string().nonempty(),
  first_name: z.string().nonempty(),
  date_of_birth: dateString(),
  phone: mobilePhoneString(),
  email: z.string().email(),
});

const companyCustomerInformationSchema = z.object({
  last_name: z.string().nonempty(),
  first_name: z.string().nonempty(),
  date_of_birth: z.string().transform(() => ""),
  phone: mobilePhoneString(),
  email: z.string().email(),
});

export const CheckoutCustomerInformationForm: React.FC = () => {
  const { retailer } = useCheckoutContext();
  const {
    data: { session_customer },
    setData,
    nextCustomerFormStep,
  } = useCheckoutCustomerContext();
  const { classes } = useStyles();
  const { t } = useTranslation();

  const isCompany = useMemo(
    () => session_customer.customer_type === CustomerType.COMPANY,
    [session_customer.customer_type]
  );

  const { getInputProps, onSubmit } = useForm({
    schema: isCompany ? companyCustomerInformationSchema : personCustomerInformationSchema,
    initialValues: pick(
      session_customer,
      "last_name",
      "first_name",
      "date_of_birth",
      "phone",
      "email"
    ),
  });

  const setCustomerInformation = useCallback(
    (
      customerInformation:
        | z.infer<typeof personCustomerInformationSchema>
        | z.infer<typeof companyCustomerInformationSchema>
    ) => {
      setData({ session_customer: { ...session_customer, ...customerInformation } });
      nextCustomerFormStep();
    },
    [nextCustomerFormStep, session_customer, setData]
  );

  return (
    <form className={classes.container} noValidate onSubmit={onSubmit(setCustomerInformation)}>
      <Stack spacing="sm" className={classes.contentWrapper}>
        {!isCompany && (
          <Group position="apart" className={classes.infoWrapper}>
            <Text size="sm">{t("checkout.fillYourInformationCarefully")}</Text>
            <TechnicalSupportSketch className={classes.infoSketch} />
          </Group>
        )}

        <TextInput
          color={retailer.style.primary_color}
          label={t("checkout.firstName")}
          required
          autoComplete="given-name"
          {...getInputProps("first_name")}
        />

        <TextInput
          color={retailer.style.primary_color}
          label={t("checkout.lastName")}
          required
          autoComplete="family-name"
          {...getInputProps("last_name")}
        />

        {!isCompany && (
          <DateStringInput
            color={retailer.style.primary_color}
            label={t("checkout.dateOfBirth")}
            required
            maxDate={new Date()}
            minDate={new Date("1920-01-01")}
            autoComplete={{
              day: "birth-day",
              month: "birth-month",
              year: "birth-year",
            }}
            {...getInputProps("date_of_birth")}
          />
        )}

        <TextInput
          color={retailer.style.primary_color}
          label={t("checkout.phone")}
          type="tel"
          required
          autoComplete="tel"
          {...getInputProps("phone")}
        />

        <TextInput
          color={retailer.style.primary_color}
          label={t("checkout.email")}
          required
          autoComplete="email"
          type="email"
          {...getInputProps("email")}
        />

        <div>
          <Text c={GRAY} size={10}>
            {t("checkout.dataProcessing")}
          </Text>
          <Anchor
            c={GRAY}
            size={10}
            href="https://www.sline.io/politique-de-confidentialite"
            target="_blank"
            rel="noopener noreferrer"
            td="underline"
            lh={1}
          >
            {t("checkout.aboutDataManagement")}
          </Anchor>
        </div>
      </Stack>

      <StepModalBottomBar>
        <Button type="submit" color={retailer.style.primary_color}>
          {t("checkout.continue")}
        </Button>
      </StepModalBottomBar>
    </form>
  );
};
