export const getStripeAppearance = (primaryColor: string) => ({
  variables: {
    colorPrimary: primaryColor,
    colorBackground: "#fff",
    colorText: "#000",
    fontFamily: '"Manrope", "Roboto", "Helvetica", "Arial",sans-serif',
    spacingUnit: "0.25rem",
    borderRadius: "0.5rem",
  },
});
