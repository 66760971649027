import { createStyles } from "@mantine/core";

interface Params {
  color: string;
}

export const useStyles = createStyles<string, Params>((_theme, { color }) => ({
  input: {
    ":focus": {
      borderColor: color,
    },
  },
}));
