import { createStyles } from "@mantine/core";
import { LIGHT_GRAY, LIGHT_GREEN } from "theme";

interface Params {
  color: string;
}

export const useStyles = createStyles<string, Params>(
  ({ fontSizes, radius, spacing }, { color }) => ({
    dividerLabel: {
      color,
      textTransform: "uppercase",
      fontSize: fontSizes.sm,
      fontWeight: 700,
      textAlign: "center",

      "::before, ::after": {
        borderColor: LIGHT_GRAY,
      },
    },

    infoWrapper: {
      backgroundColor: LIGHT_GREEN,
      borderRadius: radius.md,
      padding: `${spacing.xs} ${spacing.sm}`,
    },

    infoSketch: {
      flexShrink: 0,
    },

    instalmentWrapper: {
      flexShrink: 0,
    },
  })
);
