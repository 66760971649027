import { clsx } from "@mantine/core";
import { useStyles } from "./StepModalBottomBar.styles";

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const StepModalBottomBar = ({ className, ...props }: Props) => {
  const { classes } = useStyles();

  return <div className={clsx(classes.container, className)} {...props} />;
};
