import { Group, Radio, Stack, Text } from "@mantine/core";
import { useCheckoutContext } from "contexts/CheckoutContext";
import { formatPrice } from "helpers/price";
import { sortBy } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { DARK_GRAY } from "theme";
import { CustomerType } from "types";
import { useStyles } from "./CheckoutPlanSelect.styles";

export const CheckoutPlanSelect: React.FC = () => {
  const {
    selectedDuration,
    setSelectedDuration,
    retailer,
    session: {
      session_customer: { customer_type },
    },
    order,
  } = useCheckoutContext();
  const { classes } = useStyles({ color: retailer.style.primary_color });
  const { t } = useTranslation();

  return (
    <Stack spacing="sm" mb="sm">
      <Text fw={700}>{t("checkout.selectYourCommitmentDuration")}</Text>

      {sortBy(retailer.duration_configurations, "duration").map(({ duration, label }) => (
        <Group
          key={String(duration)}
          className={classes.radioCard}
          align="center"
          spacing="sm"
          onClick={() => setSelectedDuration(duration)}
          data-selected={selectedDuration === duration ? true : undefined}
        >
          <Radio
            readOnly
            checked={selectedDuration === duration}
            classNames={{ radio: classes.radio }}
          />

          <Stack spacing="0.25rem">
            <Text size="md" fw={700}>
              {label}
            </Text>

            <div className={classes.instalmentWrapper} data-customer-type={customer_type}>
              <Text tt="uppercase" fw={700}>
                {t("checkout.firstInstalment")}
              </Text>

              <Text className={classes.priceWithVAT}>
                {formatPrice(order[duration].totalFirstInstalmentPriceIncludingVAT)}
              </Text>

              {customer_type === CustomerType.COMPANY ? (
                <Text c={DARK_GRAY}>
                  {t("checkout.priceWithoutVAT", {
                    price: formatPrice(order[duration].totalFirstInstalmentPrice),
                  })}
                </Text>
              ) : (
                <div className={classes.space} />
              )}

              <Text tt="uppercase" fw={700}>
                {t("checkout.otherInstalment")}
              </Text>

              <Text className={classes.priceWithVAT}>
                {formatPrice(order[duration].totalOtherInstalmentPriceIncludingVAT)}
              </Text>

              {customer_type === CustomerType.COMPANY && (
                <Text c={DARK_GRAY}>
                  {t("checkout.priceWithoutVAT", {
                    price: formatPrice(order[duration].totalOtherInstalmentPrice),
                  })}
                </Text>
              )}
            </div>
          </Stack>
        </Group>
      ))}
    </Stack>
  );
};
