import { Group, Radio, Stack, Text } from "@mantine/core";
import { AddressAutocompleteInput, Button, TextInput } from "components";
import { useCheckoutContext } from "contexts/CheckoutContext";
import { formatAddress } from "helpers/address";
import { useForm } from "hooks";
import { omit } from "lodash";
import pick from "lodash/pick";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomerType, DeliveryMethod } from "types";
import { z } from "zod";
import { StepModalBottomBar } from "../../StepModalBottomBar";
import { useCheckoutCustomerContext } from "../Context";
import { useStyles } from "./CheckoutCustomerBillingForm.styles";

const customerBillingAddressSchema = z.object({
  street_address: z.string().nonempty(),
  street_address_2: z.string(),
  zip_code: z.string().nonempty(),
  city: z.string().nonempty(),
  country: z.string().transform((country) => country ?? "FR"),
  email: z.union([z.literal(""), z.string().email()]),
});

export const CheckoutCustomerBillingForm: React.FC = () => {
  const {
    retailer,
    session: { delivery_method },
  } = useCheckoutContext();
  const {
    data: { billing_address, session_customer },
    setData,
    companyAddresses,
    nextCustomerFormStep,
  } = useCheckoutCustomerContext();
  const [selectedCompanyAddressType, setCompanySelectedAddressType] = useState(
    session_customer.customer_type === CustomerType.COMPANY ? companyAddresses[0].type : undefined
  );
  const { classes } = useStyles({ color: retailer.style.primary_color });
  const { t } = useTranslation();

  const initialBillingAddress = useMemo(() => {
    if (session_customer.customer_type === CustomerType.COMPANY)
      return omit(companyAddresses[0], "type");

    return pick(
      billing_address,
      "street_address",
      "street_address_2",
      "zip_code",
      "city",
      "country"
    );
  }, [billing_address, companyAddresses, session_customer.customer_type]);

  const { getInputProps, onSubmit, setValues, errors } = useForm({
    schema: customerBillingAddressSchema,
    initialValues: { ...initialBillingAddress, email: "" },
  });

  const setBillingAddress = useCallback(
    ({ email, ...billingAddress }: z.infer<typeof customerBillingAddressSchema>) => {
      const billing_address = {
        ...pick(session_customer, "last_name", "first_name", "phone", "company_name"),
        ...billingAddress,
        email: email || null,
      };

      setData({
        billing_address,
        ...(delivery_method === DeliveryMethod.STORE_PICKUP
          ? { shipping_address: billing_address }
          : undefined),
      });
      nextCustomerFormStep();
    },
    [delivery_method, nextCustomerFormStep, session_customer, setData]
  );

  return (
    <form className={classes.container} noValidate onSubmit={onSubmit(setBillingAddress)}>
      <Stack spacing="sm" className={classes.contentWrapper}>
        {session_customer.customer_type === CustomerType.PERSON ? (
          <>
            <AddressAutocompleteInput
              color={retailer.style.primary_color}
              label={t("checkout.billingAddress")}
              required
              initialAddress={initialBillingAddress}
              onChange={(billingAddress) =>
                billingAddress ? setValues(billingAddress) : setValues({ street_address: "" })
              }
              error={!!errors["street_address"]}
            />

            <TextInput
              color={retailer.style.primary_color}
              label={t("checkout.addressComplement")}
              autoComplete="address-line2"
              {...getInputProps("street_address_2")}
            />
          </>
        ) : (
          <>
            <Text>{t("checkout.selectBillingAddress")}</Text>

            {companyAddresses.map(({ type, ...addresss }) => (
              <Group
                key={type}
                className={classes.radioCard}
                align="center"
                spacing="sm"
                onClick={() => setCompanySelectedAddressType(type)}
                data-selected={selectedCompanyAddressType === type ? true : undefined}
              >
                <Radio
                  readOnly
                  checked={selectedCompanyAddressType === type}
                  classNames={{ radio: classes.radio }}
                />

                <Stack spacing="xs">
                  <Text size="md" fw={700} tt="uppercase">
                    {t(`checkout.companyAddressTypes.${type}`)}
                  </Text>

                  <Text size="sm" tt="capitalize">
                    {formatAddress(addresss)}
                  </Text>
                </Stack>
              </Group>
            ))}

            <Stack spacing="xs">
              <TextInput
                color={retailer.style.primary_color}
                label={t("checkout.billingEmail")}
                type="email"
                {...getInputProps("email")}
              />

              <Text size="xs" c="dimmed" px="xs">
                {t("checkout.billingEmailDescription")}
              </Text>
            </Stack>
          </>
        )}
      </Stack>

      <StepModalBottomBar>
        <Button color={retailer.style.primary_color} type="submit">
          {t("checkout.continue")}
        </Button>
      </StepModalBottomBar>
    </form>
  );
};
