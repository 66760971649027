import { Radio as MantineRadio, RadioGroupProps } from "@mantine/core";
import React from "react";
import { useStyles } from "./RadioGroup.styles";

export const RadioGroup = React.forwardRef<HTMLDivElement, Omit<RadioGroupProps, "className">>(
  ({ error, ...props }, ref) => {
    const { classes } = useStyles({ error: !!error });

    return <MantineRadio.Group ref={ref} className={classes.group} error={!!error} {...props} />;
  }
);
RadioGroup.displayName = "RadioGroup";
