import { createStyles } from "@mantine/core";
import { LIGHT_GRAY } from "theme";

interface Params {
  color: string;
}

export const useStyles = createStyles<string, Params>(
  ({ spacing, radius, fontSizes }, { color }) => ({
    card: {
      backgroundColor: LIGHT_GRAY,
      borderRadius: radius.md,
      padding: `${spacing.xs} ${spacing.sm}`,
    },

    cardSketch: {
      flexShrink: 0,
      color,
      width: "75px",
    },

    cardStack: {
      minWidth: 0,
      rowGap: "0.2rem",
    },

    cardText: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontSize: fontSizes.sm,
    },
  })
);
