export enum RoutePath {
  CHECKOUT = "/:sessionId",

  CHECKOUT_PAYMENT_FAILED = "/:retailerId/payment-failed",
  CHECKOUT_REJECTED = "/:retailerId/rejected",
  CHECKOUT_PENDING_REVIEW = "/:retailerId/pending-review",
  CHECKOUT_PENDING_CONTRACT = "/:retailerId/pending-contract",
  CHECKOUT_SUCCESS = "/:retailerId/success",
  CHECKOUT_ERROR = "/:retailerId/error",

  ERROR = "/error",

  OPEN_BANKING_AUTHENTICATION_FAILED = "/open-banking/authentication-failed",
  OPEN_BANKING_ERROR = "/open-banking/error",
  OPEN_BANKING_SUCCESS = "/open-banking/success",
}
