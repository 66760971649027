import { Center, Loader } from "@mantine/core";
import { Elements } from "@stripe/react-stripe-js";
import { useCheckoutContext } from "contexts/CheckoutContext";
import { useStripeSetupIntentQuery } from "hooks";
import React from "react";
import { getStripeAppearance } from "theme";
import { CheckoutPaymentForm } from "./Form";
import { InstalmentPayment } from "./types";

interface Props {
  instalmentPayment: InstalmentPayment;
  setInstalmentPayment: (instalmentPayment: InstalmentPayment) => void;
}

export const CheckoutPayment: React.FC<Props> = ({ instalmentPayment, setInstalmentPayment }) => {
  const { retailer, stripe, firstPaymentClientSecret, otherPaymentClientSecret } =
    useCheckoutContext();
  const { setupIntent: firstSetupIntent } = useStripeSetupIntentQuery(firstPaymentClientSecret);
  const { setupIntent: otherSetupIntent } = useStripeSetupIntentQuery(otherPaymentClientSecret);

  if (
    instalmentPayment === InstalmentPayment.FIRST ||
    instalmentPayment === InstalmentPayment.ALL
  ) {
    if (!firstPaymentClientSecret || !firstSetupIntent)
      return (
        <Center h="100%" py="md">
          <Loader color={retailer.style.primary_color} />
        </Center>
      );

    return (
      <Elements
        stripe={stripe}
        options={{
          clientSecret: firstPaymentClientSecret,
          appearance: getStripeAppearance(retailer.style.primary_color),
        }}
      >
        <CheckoutPaymentForm
          instalmentPayment={instalmentPayment}
          setInstalmentPayment={setInstalmentPayment}
          paymentClientSecret={firstPaymentClientSecret}
          setupIntent={firstSetupIntent}
        />
      </Elements>
    );
  }

  if (!otherPaymentClientSecret || !otherSetupIntent)
    return (
      <Center h="100%" py="md">
        <Loader color={retailer.style.primary_color} />
      </Center>
    );

  return (
    <Elements
      stripe={stripe}
      options={{
        clientSecret: otherPaymentClientSecret,
        appearance: getStripeAppearance(retailer.style.primary_color),
      }}
    >
      <CheckoutPaymentForm
        instalmentPayment={instalmentPayment}
        setInstalmentPayment={setInstalmentPayment}
        paymentClientSecret={otherPaymentClientSecret}
        setupIntent={otherSetupIntent}
      />
    </Elements>
  );
};
