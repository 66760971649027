import { createStyles, getStylesRef } from "@mantine/core";
import { GRAY } from "theme";

interface Params {
  color: string;
}

export const useStyles = createStyles<string, Params>(
  ({ spacing, radius, fn: { rgba }, white }, { color }) => ({
    radioCard: {
      ref: getStylesRef("radio-card"),
      borderStyle: "solid",
      borderWidth: 1.5,
      borderColor: GRAY,
      borderRadius: radius.lg,
      padding: `${spacing.xs} ${spacing.sm}`,
      cursor: "pointer",
      backgroundColor: white,

      "&[data-selected]": {
        borderColor: color,
        boxShadow: `0 0 ${spacing.xs} ${rgba(color, 0.2)}`,
        backgroundColor: rgba(color, 0.025),
      },
    },

    radio: {
      borderWidth: 2,
      borderColor: GRAY,

      ":checked": {
        backgroundColor: color,
        borderColor: color,
      },
    },

    instalmentWrapper: {
      display: "grid",
      gridTemplateColumns: "repeat(5, auto)",
      columnGap: "0.6rem",
      rowGap: "0.2rem",
      alignItems: "baseline",
      fontSize: "0.7rem",

      "&[data-customer-type='company']": {
        gridTemplateColumns: "repeat(3, auto)",
      },
    },

    space: {
      width: 20,
    },

    priceWithVAT: {
      fontSize: "0.85rem",

      [`.${getStylesRef("radio-card")}[data-selected] &`]: {
        fontWeight: 700,
      },
    },
  })
);
