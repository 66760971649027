import { Collapse, Group, Radio, Stack, Text } from "@mantine/core";
import { AddressAutocompleteInput, Button, Checkbox, TextInput } from "components";
import { useCheckoutContext } from "contexts/CheckoutContext";
import { formatAddress } from "helpers/address";
import { useForm } from "hooks";
import { omit } from "lodash";
import pick from "lodash/pick";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { mobilePhoneString } from "schemas";
import { CustomerType } from "types";
import { z } from "zod";
import { StepModalBottomBar } from "../../StepModalBottomBar";
import { useCheckoutCustomerContext } from "../Context";
import { useStyles } from "./CheckoutCustomerShippingForm.styles";

const customerShippingAddressSchema = z.object({
  street_address: z.string().nonempty(),
  street_address_2: z.string(),
  zip_code: z.string().nonempty(),
  city: z.string().nonempty(),
  country: z.string().transform((country) => country ?? "FR"),
});

const customerShippingAddressWithDifferentShippingPersonSchema = z.object({
  street_address: z.string().nonempty(),
  street_address_2: z.string(),
  zip_code: z.string().nonempty(),
  city: z.string().nonempty(),
  country: z.string().transform((country) => country ?? "FR"),
  last_name: z.string().nonempty(),
  first_name: z.string().nonempty(),
  phone: mobilePhoneString(),
});

export const CheckoutCustomerShippingForm: React.FC = () => {
  const { retailer } = useCheckoutContext();
  const {
    data: { shipping_address, session_customer },
    setData,
    companyAddresses,
    nextCustomerFormStep,
    isShippingPersonDifferent,
    setIsShippingPersonDifferent,
    isBillingAddressDifferent,
    setIsBillingAddressDifferent,
  } = useCheckoutCustomerContext();
  const [selectedCompanyAddressType, setCompanySelectedAddressType] = useState(
    session_customer.customer_type === CustomerType.COMPANY ? companyAddresses[0].type : undefined
  );
  const { classes } = useStyles({ color: retailer.style.primary_color });
  const { t } = useTranslation();

  const initialShippingAddress = useMemo(() => {
    if (session_customer.customer_type === CustomerType.COMPANY)
      return omit(companyAddresses[0], "type");

    return pick(
      shipping_address,
      "street_address",
      "street_address_2",
      "zip_code",
      "city",
      "country"
    );
  }, [companyAddresses, session_customer.customer_type, shipping_address]);

  const { getInputProps, onSubmit, setValues, errors } = useForm({
    schema: isShippingPersonDifferent
      ? customerShippingAddressWithDifferentShippingPersonSchema
      : customerShippingAddressSchema,
    initialValues: {
      ...initialShippingAddress,
      ...pick(shipping_address, "last_name", "first_name", "phone"),
    },
  });

  const setShippingAddress = useCallback(
    (
      shippingAddress:
        | z.infer<typeof customerShippingAddressSchema>
        | z.infer<typeof customerShippingAddressWithDifferentShippingPersonSchema>
    ) => {
      const shipping_address = {
        ...pick(session_customer, "last_name", "first_name", "phone", "email", "company_name"),
        ...shippingAddress,
      };

      setData({
        shipping_address,
        ...(isBillingAddressDifferent ? undefined : { billing_address: shipping_address }),
      });
      nextCustomerFormStep();
    },
    [isBillingAddressDifferent, nextCustomerFormStep, session_customer, setData]
  );

  return (
    <form className={classes.container} noValidate onSubmit={onSubmit(setShippingAddress)}>
      <Stack spacing="sm" className={classes.contentWrapper}>
        {session_customer.customer_type === CustomerType.PERSON ? (
          <>
            <AddressAutocompleteInput
              color={retailer.style.primary_color}
              label={t("checkout.shippingAddress")}
              required
              initialAddress={initialShippingAddress}
              onChange={(shippingAddress) =>
                shippingAddress ? setValues(shippingAddress) : setValues({ street_address: "" })
              }
              error={!!errors["street_address"]}
            />

            <TextInput
              color={retailer.style.primary_color}
              label={t("checkout.addressComplement")}
              autoComplete="address-line2"
              {...getInputProps("street_address_2")}
            />
          </>
        ) : (
          <>
            <Text>{t("checkout.selectShippingAddress")}</Text>

            {companyAddresses.map(({ type, ...addresss }) => (
              <Group
                key={type}
                className={classes.radioCard}
                align="center"
                spacing="sm"
                onClick={() => {
                  setCompanySelectedAddressType(type);
                  setValues(addresss);
                }}
                data-selected={selectedCompanyAddressType === type ? true : undefined}
              >
                <Radio
                  readOnly
                  checked={selectedCompanyAddressType === type}
                  classNames={{ radio: classes.radio }}
                />

                <Stack spacing="xs">
                  <Text size="md" fw={700} tt="uppercase">
                    {t(`checkout.companyAddressTypes.${type}`)}
                  </Text>

                  <Text size="sm" tt="capitalize">
                    {formatAddress(addresss)}
                  </Text>
                </Stack>
              </Group>
            ))}
          </>
        )}

        <Checkbox
          checked={isShippingPersonDifferent}
          onChange={(event) => setIsShippingPersonDifferent(event.target.checked)}
          color={retailer.style.primary_color}
          label={t("checkout.differentShippingPerson")}
          fw={700}
        />

        <Collapse in={isShippingPersonDifferent}>
          <Stack spacing="sm">
            <TextInput
              color={retailer.style.primary_color}
              label={t("checkout.firstName")}
              required
              autoComplete="given-name"
              {...getInputProps("first_name")}
            />

            <TextInput
              color={retailer.style.primary_color}
              label={t("checkout.lastName")}
              required
              autoComplete="family-name"
              {...getInputProps("last_name")}
            />

            <TextInput
              color={retailer.style.primary_color}
              label={t("checkout.phone")}
              type="tel"
              required
              autoComplete="tel"
              {...getInputProps("phone")}
            />
          </Stack>
        </Collapse>

        {session_customer.customer_type === CustomerType.PERSON && (
          <Checkbox
            checked={isBillingAddressDifferent}
            onChange={(event) => setIsBillingAddressDifferent(event.target.checked)}
            color={retailer.style.primary_color}
            label={t("checkout.differentBillingAddress")}
            fw={700}
          />
        )}
      </Stack>

      <StepModalBottomBar>
        <Button color={retailer.style.primary_color} type="submit">
          {t("checkout.continue")}
        </Button>
      </StepModalBottomBar>
    </form>
  );
};
