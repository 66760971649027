import { RentalStatus } from "types";
import { z } from "zod";
import { enumFrom } from "./utils";

export const createdRentalSchema = z.object({
  rental_id: z.string().optional().nullable(),
  rental_status: enumFrom(RentalStatus).optional().nullable(),
  session_id: z.string().optional().nullable(),
  session_status: enumFrom(RentalStatus).optional().nullable(),
});
