import { createStyles } from "@mantine/core";
import { SMALL_SCREEN_BREAKPOINT } from "theme";
import { CHECKOUT_HEADER_HEIGHT } from "../../Header/CheckoutHeader.styles";

export const useStyles = createStyles(({ fn: { smallerThan } }) => ({
  inner: {
    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      paddingTop: CHECKOUT_HEADER_HEIGHT,
    },
  },
}));
