import { useDidUpdate, useInterval } from "@mantine/hooks";
import { Stripe } from "@stripe/stripe-js";
import { useMutation } from "@tanstack/react-query";
import { refetchSessionQuery, updateSessionQuery } from "hooks";
import { useEffect, useState } from "react";
import { setupIdentityVerification } from "services/api/session";
import { Session, SessionStepStatus } from "types";
import { CheckoutStep, IdentityVerificationError } from "../../CheckoutContext";

const REFETCH_INTERVAL = 1000;

const getIdentityVerificationErrorFromCode = (
  identityVerificationErrorCode: string | undefined | null
) => {
  if (!identityVerificationErrorCode) return null;

  if (
    [
      "id_verification_abandoned",
      "id_verification_consent_declined",
      "id_verification_device_not_supported",
      "id_verification_document_unverified_other",
    ].includes(identityVerificationErrorCode)
  ) {
    return IdentityVerificationError.UNKNOWN;
  }

  if (
    [
      "id_verification_document_type_not_supported",
      "id_verification_country_not_supported",
      "id_verification_document_expired",
    ].includes(identityVerificationErrorCode)
  ) {
    return IdentityVerificationError.NON_COMPLIANT;
  }

  return IdentityVerificationError.INCONSISTENT;
};

export const useIdentityVerification = (
  session: Session,
  stripe: Stripe | null,
  currentStep: CheckoutStep,
  nextStep: () => CheckoutStep
) => {
  const { start: startRefetching, stop: stopRefetching } = useInterval(
    () => refetchSessionQuery(session.id),
    REFETCH_INTERVAL
  );

  const identityVerificationStatus = session.steps?.id_verification?.status;
  const identityVerificationErrorCode = session.steps?.id_verification?.error_code;

  const { mutate: startIdentityVerification, isLoading: isStartingIdentityVerification } =
    useMutation({
      mutationFn: async () => {
        const clientSecret = await setupIdentityVerification(session.id);

        if (!stripe) throw Error("Stripe instance is null.");

        return stripe.verifyIdentity(clientSecret);
      },
      onSuccess: () => {
        updateSessionQuery(session.id, {
          steps: {
            ...session.steps,
            id_verification: {
              status: SessionStepStatus.PENDING,
              error_code: null,
            },
          },
        });
      },
      onError: () => refetchSessionQuery(session.id),
    });

  useEffect(() => {
    if (currentStep !== CheckoutStep.ID_VERIFICATION) return;

    if (identityVerificationStatus === SessionStepStatus.PENDING) {
      startRefetching();
      return;
    }

    if (identityVerificationStatus === SessionStepStatus.RETRY) {
      stopRefetching();
      return;
    }

    if (identityVerificationStatus === SessionStepStatus.FINISHED) {
      stopRefetching();
      nextStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, identityVerificationStatus]);

  const [identityVerificationError, setIdentityVerificationError] =
    useState<IdentityVerificationError | null>(
      getIdentityVerificationErrorFromCode(identityVerificationErrorCode)
    );

  useDidUpdate(() => {
    if (!identityVerificationErrorCode) return;

    setIdentityVerificationError(
      getIdentityVerificationErrorFromCode(identityVerificationErrorCode)
    );
  }, [identityVerificationErrorCode]);

  return {
    startIdentityVerification,
    isStartingIdentityVerification,
    identityVerificationError,
    resetIdentityVerificationError: () => setIdentityVerificationError(null),
  };
};
