import { Anchor, Group, Modal, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconArrowRight } from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { GRAY } from "theme";
import { CheckoutOrder } from "./Order";

export const CheckoutOrderDetailsModal: React.FC = () => {
  const [orderDetailsModalOpened, { open: openOrderDetailsModal, close: closeOrderDetailsModal }] =
    useDisclosure();

  const { t } = useTranslation();
  return (
    <>
      <Anchor c={GRAY} fw={700} size="sm" td="underline" mb="lg" onClick={openOrderDetailsModal}>
        <Group spacing="xs">
          <IconArrowRight size="1rem" />
          <Text span>{t("checkout.seeCartDetails")}</Text>
        </Group>
      </Anchor>

      <Modal
        opened={orderDetailsModalOpened}
        onClose={closeOrderDetailsModal}
        title={t("checkout.orderDetails")}
        size="lg"
      >
        <CheckoutOrder />
      </Modal>
    </>
  );
};
