import { Stack, Text, Timeline } from "@mantine/core";
import { useCheckoutContext } from "contexts/CheckoutContext";
import { formatPrice } from "helpers/price";
import React from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./CheckoutPlanDetails.styles";

export const CheckoutPlanDetails: React.FC = () => {
  const { retailer, order } = useCheckoutContext();
  const { t } = useTranslation();
  const { classes } = useStyles({ color: retailer.style.primary_color });

  return (
    <Stack>
      <Text fw={700}>{t("checkout.yourPaymentSchedule")}</Text>

      <Timeline classNames={classes} lineWidth={1}>
        <Timeline.Item title={t("checkout.today")} bulletSize={16}>
          <Text size="sm">
            {formatPrice(order.selectedDuration.totalFirstInstalmentPriceIncludingVAT)}
          </Text>
        </Timeline.Item>

        <Timeline.Item title={t("checkout.oneMonthAfterReceiptOfYourOrder")} bulletSize={16}>
          <Text size="sm">
            {formatPrice(order.selectedDuration.totalOtherInstalmentPriceIncludingVAT)}
          </Text>
        </Timeline.Item>

        <Timeline.Item title={t("checkout.followingMonths")} bulletSize={16}>
          <Text size="sm">
            {formatPrice(order.selectedDuration.totalOtherInstalmentPriceIncludingVAT)}
          </Text>
        </Timeline.Item>
      </Timeline>
    </Stack>
  );
};
