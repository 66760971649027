import React from "react";
import { useTranslation } from "react-i18next";
import { StepModal } from "../StepModal";
import { CheckoutContractSignature } from "./CheckoutContractSignature";

interface Props {
  opened: boolean;
  close: () => void;
}

export const CheckoutContractSignatureModal: React.FC<Props> = ({ opened, close }) => {
  const { t } = useTranslation();

  return (
    <StepModal opened={opened} onClose={close} title={t("checkout.contractSignature")}>
      <CheckoutContractSignature />
    </StepModal>
  );
};
