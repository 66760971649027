import { Anchor, Flex, Group, Modal, Stack, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconInfoCircleFilled } from "@tabler/icons-react";
import { useCheckoutContext } from "contexts/CheckoutContext";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as ComputerRobotSketch } from "resources/sketches/computer-robot.svg";
import { GRAY } from "theme";
import { useStyles } from "./CheckoutSubscriptionAutomatisationModal.styles";

export const CheckoutSubscriptionAutomatisationModal: React.FC = () => {
  const { retailer } = useCheckoutContext();
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure();
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <>
      <Anchor td="underline" onClick={openModal}>
        <Group spacing="xs">
          <Flex c={GRAY}>
            <IconInfoCircleFilled size="1.2rem" />
          </Flex>

          <Text size="xs" span lh={1.8}>
            {t("checkout.subscriptionAutomatisationLink")}
          </Text>
        </Group>
      </Anchor>

      <Modal
        title={t("checkout.subscriptionAutomatisation")}
        opened={modalOpened}
        onClose={closeModal}
        classNames={classes}
      >
        <Stack align="center">
          <ComputerRobotSketch color={retailer.style.primary_color} />

          <Text>
            <Trans
              i18nKey="checkout.subscriptionAutomatisationDescription"
              components={{ b: <b /> }}
            />
          </Text>
        </Stack>
      </Modal>
    </>
  );
};
