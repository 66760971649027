import { MantineProvider } from "@mantine/core";
import { QueryClientProvider } from "@tanstack/react-query";
import { ErrorBoundary, Notifications } from "components";
import { MatomoContextProvider } from "contexts/MatomoContext";
import { useNetworkNotification, useSentry, useVisualViewportHeight } from "hooks";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { queryClient } from "services/api";
import { AppRoutes } from "./AppRoutes";
import { mantineTheme } from "./theme";
import "./translations/i18n";

const App: React.FC = () => {
  useSentry();
  useNetworkNotification();
  useVisualViewportHeight(true);

  return (
    <MantineProvider theme={mantineTheme} withGlobalStyles withNormalizeCSS>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <MatomoContextProvider>
            <ErrorBoundary>
              <Notifications />
              <AppRoutes />
            </ErrorBoundary>
          </MatomoContextProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </MantineProvider>
  );
};

export default App;
