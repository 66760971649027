import { withSentryReactRouterV6Routing } from "@sentry/react";
import {
  CheckoutPage,
  CheckoutStatus,
  CheckoutStatusPage,
  ErrorPage,
  OpenBankingStatus,
  OpenBankingStatusPage,
} from "pages";
import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { RoutePath } from "types";

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

export const AppRoutes: React.FC = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <SentryRoutes>
      <Route path={RoutePath.ERROR} element={<ErrorPage />} />

      <Route path={RoutePath.CHECKOUT} element={<CheckoutPage />} />

      <Route
        path={RoutePath.CHECKOUT_PAYMENT_FAILED}
        element={<CheckoutStatusPage status={CheckoutStatus.PAYMENT_FAILED} />}
      />
      <Route
        path={RoutePath.CHECKOUT_REJECTED}
        element={<CheckoutStatusPage status={CheckoutStatus.REJECTED} />}
      />
      <Route
        path={RoutePath.CHECKOUT_PENDING_REVIEW}
        element={<CheckoutStatusPage status={CheckoutStatus.PENDING_REVIEW} />}
      />
      <Route
        path={RoutePath.CHECKOUT_PENDING_CONTRACT}
        element={<CheckoutStatusPage status={CheckoutStatus.PENDING_CONTRACT} />}
      />
      <Route
        path={RoutePath.CHECKOUT_SUCCESS}
        element={<CheckoutStatusPage status={CheckoutStatus.SUCCESS} />}
      />
      <Route
        path={RoutePath.CHECKOUT_ERROR}
        element={<CheckoutStatusPage status={CheckoutStatus.ERROR} />}
      />

      <Route
        path={RoutePath.OPEN_BANKING_AUTHENTICATION_FAILED}
        element={
          <OpenBankingStatusPage openBankingStatus={OpenBankingStatus.AUTHENTICATION_FAILED} />
        }
      />
      <Route
        path={RoutePath.OPEN_BANKING_ERROR}
        element={<OpenBankingStatusPage openBankingStatus={OpenBankingStatus.ERROR} />}
      />
      <Route
        path={RoutePath.OPEN_BANKING_SUCCESS}
        element={<OpenBankingStatusPage openBankingStatus={OpenBankingStatus.SUCCESS} />}
      />
    </SentryRoutes>
  );
};
