import React from "react";
import { useTranslation } from "react-i18next";
import { StepModal } from "../StepModal";
import { CheckoutIdentityVerification } from "./CheckoutIdentityVerification";

interface Props {
  opened: boolean;
  close: () => void;
}

export const CheckoutIdentityVerificationModal: React.FC<Props> = ({ opened, close }) => {
  const { t } = useTranslation();

  return (
    <StepModal opened={opened} onClose={close} title={t("checkout.idDocument")}>
      <CheckoutIdentityVerification />
    </StepModal>
  );
};
