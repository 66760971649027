import { Box, Loader, Stack, Text } from "@mantine/core";
import { CheckoutStep, useCheckoutContext } from "contexts/CheckoutContext";
import React from "react";
import { useTranslation } from "react-i18next";
import { CheckoutCustomerBillingForm } from "./BillingForm";
import { useStyles } from "./CheckoutCustomer.styles";
import { CheckoutCustomerCompanyForm } from "./CompanyForm";
import { CheckoutCustomerFormStep, useCheckoutCustomerContext } from "./Context";
import { CheckoutCustomerDetails } from "./Details";
import { CheckoutCustomerInformationForm } from "./InformationForm";
import { CheckoutCustomerRepresentativeForm } from "./RepresentativeForm";
import { CheckoutCustomerShippingForm } from "./ShippingForm";
import { CheckoutCustomerValidationForm } from "./ValidationForm";

export const CheckoutCustomer: React.FC = () => {
  const { currentStep, retailer } = useCheckoutContext();
  const { currentCustomerFormStep } = useCheckoutCustomerContext();
  const { t } = useTranslation();
  const { classes } = useStyles();

  if (currentStep === CheckoutStep.CUSTOMER) {
    switch (currentCustomerFormStep) {
      case CheckoutCustomerFormStep.COMPANY:
        return <CheckoutCustomerCompanyForm />;

      case CheckoutCustomerFormStep.INFORMATION:
        return <CheckoutCustomerInformationForm />;

      case CheckoutCustomerFormStep.REPRESENTATIVE:
        return <CheckoutCustomerRepresentativeForm />;

      case CheckoutCustomerFormStep.SHIPPING: {
        return <CheckoutCustomerShippingForm />;
      }

      case CheckoutCustomerFormStep.BILLING: {
        return <CheckoutCustomerBillingForm />;
      }

      case CheckoutCustomerFormStep.VALIDATION:
        return <CheckoutCustomerValidationForm />;
    }
  }

  if (currentStep === CheckoutStep.WEB_SCORING || currentStep === CheckoutStep.ENVELOPPE_FINANCING)
    return (
      <Stack align="center" className={classes.wrapper}>
        <Loader size={60} color={retailer.style.primary_color} />
        <Text w="100%">{t("checkout.checkingDataDescription")}</Text>
      </Stack>
    );

  return (
    <Box className={classes.wrapper}>
      <CheckoutCustomerDetails />
    </Box>
  );
};
