import { useQuery } from "@tanstack/react-query";
import { HttpStatusCode, isAxiosError } from "axios";
import { useMemo } from "react";
import { expiredSessionSchema } from "schemas";
import { queryClient } from "services/api";
import { getSession } from "services/api/session";
import { Session } from "types";

const REFETCH_INTERVAL = 1000;

export const useSessionQuery = (sessionId: string | undefined) => {
  const { data: session, ...queryResult } = useQuery({
    queryKey: ["session", String(sessionId)],
    queryFn: () => getSession(String(sessionId)),
    retry: false,
    retryOnMount: false,
    staleTime: Infinity,
    refetchInterval: (session) => {
      if (!!session && session.steps === null) return REFETCH_INTERVAL;

      return false;
    },
    enabled: !!sessionId,
  });

  const expiredSession = useMemo(() => {
    if (
      isAxiosError(queryResult.error) &&
      queryResult.error.response?.status === HttpStatusCode.Gone
    )
      return expiredSessionSchema.parse(queryResult.error.response.data);

    return undefined;
  }, [queryResult.error]);

  return {
    session: !session || session.steps === null ? undefined : session,
    expiredSession,
    ...queryResult,
  };
};

export const updateSessionQuery = (
  sessionId: string,
  updater: Partial<Session> | ((session: Session) => Partial<Session>)
) =>
  queryClient.setQueryData<Session>(["session", sessionId], (session) =>
    session
      ? { ...session, ...(updater instanceof Function ? updater(session) : updater) }
      : undefined
  );

export const invalidateSessionQuery = (sessionId: string) =>
  queryClient.invalidateQueries(["session", sessionId]);

export const refetchSessionQuery = (sessionId: string) =>
  queryClient.refetchQueries(["session", sessionId]);

export const getSessionQueryData = (sessionId: string) =>
  queryClient.getQueryData<Session>(["session", sessionId]);
