import { useInterval } from "@mantine/hooks";
import { useMutation } from "@tanstack/react-query";
import dayjs, { Dayjs } from "dayjs";
import { refetchSessionQuery, updateSessionQuery } from "hooks";
import { useEffect, useRef } from "react";
import { requestOpenBanking as _requestOpenBanking } from "services/api/session";
import { Session, SessionStepStatus } from "types";
import { CheckoutStep } from "../../CheckoutContext";

const REFETCH_INTERVAL = 1000;
export const MINIMUM_OPEN_BANKING_WAITING_DURATION = 2000;

export const useOpenBanking = (
  session: Session,
  currentStep: CheckoutStep,
  nextStep: () => CheckoutStep
) => {
  const { start: startRefetching, stop: stopRefetching } = useInterval(
    () => refetchSessionQuery(session.id),
    REFETCH_INTERVAL
  );
  const openBankingRequestedDate = useRef<Dayjs | null>(null);

  const openBankingStatus = session.steps?.open_banking?.status;

  const { mutate: requestOpenBanking, isLoading: isRequestingOpenBanking } = useMutation({
    mutationFn: () => _requestOpenBanking(session.id),
    onSuccess: () => {
      openBankingRequestedDate.current = dayjs();

      updateSessionQuery(session.id, {
        steps: {
          ...session.steps,
          open_banking: {
            status: SessionStepStatus.PENDING,
            error_code: null,
          },
        },
      });
    },
  });

  useEffect(() => {
    if (currentStep !== CheckoutStep.OPEN_BANKING) return;

    if (openBankingStatus === SessionStepStatus.NOT_STARTED) return;

    if (openBankingStatus === SessionStepStatus.PENDING) {
      startRefetching();
      return;
    }

    stopRefetching();

    // ? We want the user to wait at least a few seconds before redirection or next step
    const waitingDuration = (openBankingRequestedDate.current ?? dayjs())
      .add(MINIMUM_OPEN_BANKING_WAITING_DURATION, "milliseconds")
      .diff(dayjs());

    setTimeout(nextStep, waitingDuration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, openBankingStatus]);

  return {
    requestOpenBanking: requestOpenBanking,
    isRequestingOpenBanking: isRequestingOpenBanking,
  };
};
