import { createStyles } from "@mantine/core";
import { MEDIUM_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(({ spacing, fn: { smallerThan } }) => ({
  footer: {
    padding: `${spacing.md} ${spacing.xl}`,
    backgroundColor: "#FAFAFA",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    rowGap: spacing.xs,

    [smallerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
      padding: `${spacing.sm} ${spacing.lg}`,
      flexDirection: "column",
    },
  },

  linksWrapper: {
    display: "flex",
    columnGap: spacing.lg,

    [smallerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
      flexDirection: "column",
      alignItems: "center",
      rowGap: spacing.xs,
    },
  },
}));
