import { useEffect } from "react";
import { Retailer, RoutePath, Session, SessionStatus } from "types";
import { useRedirect } from "./useRedirect";

export const useRejectedSessionRedirection = (session: Session, retailer: Retailer) => {
  const redirect = useRedirect(session, retailer);

  useEffect(() => {
    if (session.status === SessionStatus.REJECTED) {
      redirect(RoutePath.CHECKOUT_REJECTED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.status]);
};
