import { Flex } from "@mantine/core";
import { CheckoutOrderDetailsModal } from "../../CheckoutOrderDetailsModal";
import { CheckoutOrderSummary } from "../../Order/CheckoutOrderSummary";
import { CheckoutPlanDetails } from "../../Plan/Details";

export const CheckoutOrderRecap = () => {
  return (
    <Flex direction="column" gap="lg">
      <CheckoutOrderSummary shadowed />
      <CheckoutPlanDetails />
      <CheckoutOrderDetailsModal />
    </Flex>
  );
};
