import { Group, Stack, Text } from "@mantine/core";
import { useCheckoutContext } from "contexts/CheckoutContext";
import { createDate, formatDate } from "helpers/date";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as BillingAddressSketch } from "resources/sketches/billing-address.svg";
import { ReactComponent as CompanySketch } from "resources/sketches/company.svg";
import { ReactComponent as IdentitySketch } from "resources/sketches/identity.svg";
import { ReactComponent as ShippingAddressSketch } from "resources/sketches/shipping-address.svg";
import { CustomerType, DeliveryMethod } from "types";
import { useCheckoutCustomerContext } from "../Context";
import { useStyles } from "./CheckoutCustomerDetails.styles";

export const CheckoutCustomerDetails: React.FC = () => {
  const { retailer, session } = useCheckoutContext();
  const {
    data: { session_customer, shipping_address, billing_address, representative },
    isRepresentative,
  } = useCheckoutCustomerContext();
  const { t } = useTranslation();
  const { classes } = useStyles({ color: retailer.style.primary_color });

  const customerDateOfBirth = useMemo(
    () => (session_customer.date_of_birth ? createDate(session_customer.date_of_birth) : undefined),
    [session_customer.date_of_birth]
  );
  const representativeDateOfBirth = useMemo(
    () => (representative?.date_of_birth ? createDate(representative.date_of_birth) : undefined),
    [representative?.date_of_birth]
  );

  return (
    <Stack spacing="sm">
      {session_customer.customer_type === CustomerType.COMPANY && (
        <Group className={classes.card}>
          <CompanySketch className={classes.cardSketch} />

          <Stack className={classes.cardStack}>
            <Text fw={700} className={classes.cardText}>
              {t("checkout.yourCompany")}
            </Text>

            <div>
              <Text className={classes.cardText}>{session_customer.company_name}</Text>
              <Text className={classes.cardText}>
                {t("checkout.companyRegistrationId", {
                  registrationId: session_customer.company_registration_id,
                })}
              </Text>
            </div>
          </Stack>
        </Group>
      )}

      <Group className={classes.card}>
        <IdentitySketch className={classes.cardSketch} />

        <Stack className={classes.cardStack}>
          <Text fw={700} className={classes.cardText}>
            {t("checkout.yourInformation")}
          </Text>

          <div>
            <Text className={classes.cardText}>
              {session_customer.first_name} {session_customer.last_name}
            </Text>
            {!!customerDateOfBirth && (
              <Text className={classes.cardText}>{formatDate(customerDateOfBirth)}</Text>
            )}
            <Text className={classes.cardText}>{session_customer.phone}</Text>
            <Text className={classes.cardText}>{session_customer.email}</Text>
          </div>
        </Stack>
      </Group>

      {session_customer.customer_type === CustomerType.COMPANY &&
        !isRepresentative &&
        !!representative && (
          <Group className={classes.card}>
            <IdentitySketch className={classes.cardSketch} style={{ color: "black" }} />

            <Stack className={classes.cardStack}>
              <Text fw={700} className={classes.cardText}>
                {t("checkout.companyRepresentative")}
              </Text>

              <div>
                <Text className={classes.cardText}>
                  {representative.first_name} {representative.last_name}
                </Text>
                {!!representativeDateOfBirth && (
                  <Text className={classes.cardText}>{formatDate(representativeDateOfBirth)}</Text>
                )}
                <Text className={classes.cardText}>{representative.phone}</Text>
                <Text className={classes.cardText}>{representative.email}</Text>
              </div>
            </Stack>
          </Group>
        )}

      <Group className={classes.card}>
        <ShippingAddressSketch className={classes.cardSketch} />

        <Stack className={classes.cardStack}>
          <Text fw={700} className={classes.cardText}>
            {t("checkout.shippingAddress")}
          </Text>

          {session.delivery_method === DeliveryMethod.STORE_PICKUP ? (
            <div>
              <Text className={classes.cardText}>{t("checkout.storePickup")}</Text>
              <Text className={classes.cardText}>{session.store_name}</Text>
            </div>
          ) : (
            <div>
              {(shipping_address.last_name !== session_customer.last_name ||
                shipping_address.first_name !== session_customer.first_name) && (
                <Text className={classes.cardText}>
                  {shipping_address.first_name} {shipping_address.last_name}
                </Text>
              )}
              <Text className={classes.cardText}>{shipping_address.street_address}</Text>
              {!!shipping_address.street_address_2 && (
                <Text className={classes.cardText}>{shipping_address.street_address_2}</Text>
              )}
              <Text className={classes.cardText}>
                {shipping_address.zip_code}, {shipping_address.city}
              </Text>
            </div>
          )}
        </Stack>
      </Group>

      <Group className={classes.card}>
        <BillingAddressSketch className={classes.cardSketch} />

        <Stack className={classes.cardStack}>
          <Text fw={700} className={classes.cardText}>
            {t("checkout.billingAddress")}
          </Text>

          <div>
            <Text className={classes.cardText}>{billing_address.street_address}</Text>
            {!!billing_address.street_address_2 && (
              <Text className={classes.cardText}>{billing_address.street_address_2}</Text>
            )}
            <Text className={classes.cardText}>
              {billing_address.zip_code}, {billing_address.city}
            </Text>
          </div>
        </Stack>
      </Group>
    </Stack>
  );
};
