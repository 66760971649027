import { Loader, ScrollArea, Stack } from "@mantine/core";
import { Footer } from "components";
import { CheckoutStep, useCheckoutContext } from "contexts/CheckoutContext";
import React from "react";
import { CheckoutTitle } from "../Title";
import { useStyles } from "./CheckoutSteps.styles";
import { CheckoutContractSignatureStep } from "./ContractSignature";
import { CheckoutCustomerStep } from "./Customer";
import { CheckoutIdentityVerificationStep } from "./IdentityVerification";
import { CheckoutOpenBankingStep } from "./OpenBanking";
import { CheckoutOrderRecap, CheckoutOrderStep } from "./Order";
import { CheckoutPaymentStep } from "./Payment";

export const CheckoutSteps: React.FC = () => {
  const { retailer, currentStep } = useCheckoutContext();
  const { classes } = useStyles();

  return (
    <ScrollArea className={classes.scrollArea}>
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <div className={classes.leftSection}>
            <Stack spacing="sm" className={classes.stepsWrapper}>
              <CheckoutTitle onlyOnLargeScreen />

              <CheckoutOrderStep />
              <CheckoutCustomerStep />
              <CheckoutOpenBankingStep />
              <CheckoutIdentityVerificationStep />
              <CheckoutPaymentStep />
              <CheckoutContractSignatureStep />

              {currentStep === CheckoutStep.END && (
                <Loader color={retailer.style.primary_color} className={classes.loader} mt="md" />
              )}
            </Stack>
          </div>

          <div className={classes.rightSection}>
            <CheckoutOrderRecap />
          </div>
        </div>

        <Footer rentalConditionsUrl={retailer.urls_config.rental_conditions_url} />
      </div>
    </ScrollArea>
  );
};
