import { createStyles } from "@mantine/core";
import { DARK_GRAY, SMALL_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(
  ({ spacing, radius, shadows, fn: { largerThan, smallerThan } }) => ({
    logo: {
      [largerThan(SMALL_SCREEN_BREAKPOINT)]: {
        marginLeft: spacing.xl,
      },
      [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
        alignSelf: "center",
      },
    },
    container: {
      [largerThan(SMALL_SCREEN_BREAKPOINT)]: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    box: {
      display: "flex",
      flexDirection: "column",
      [largerThan(SMALL_SCREEN_BREAKPOINT)]: {
        borderStyle: "solid",
        borderColor: DARK_GRAY,
        borderWidth: 1,
        backgroundColor: "#F7F8F9",
        minWidth: 500,
        padding: spacing.lg,
        borderRadius: radius.md,
        boxShadow: shadows.sm,
        rowGap: spacing.md,
        alignItems: "center",
      },
      [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
        rowGap: spacing.sm,
      },
    },
    text: {
      [largerThan(SMALL_SCREEN_BREAKPOINT)]: {
        textAlign: "center",
      },
    },
  })
);
