import { apiClient } from "..";

interface UpdateParams {
  session_id: string;
  payment_method_id: string;
  iban: string;
  payment_method: "first" | "other";
}

export const createSessionPaymentInfo = async ({ session_id, ...params }: UpdateParams) => {
  await apiClient.post(`sessions/${session_id}/create_payment_info`, params);
};
