import {
  expiredSessionSchema,
  lineItemSchema,
  sessionAddressSchema,
  sessionDocumentSchema,
  sessionSchema,
} from "schemas";
import { z } from "zod";

export enum SessionType {
  B2C = "b2c",
  B2B = "b2b",
  B2C_B2B = "b2c_b2b",
}

export enum FinancingMode {
  AUTO = "auto",
  CACF = "cacf",
  CALF = "calf",
}

export enum Financier {
  CACF = "cacf",
  CALF = "calf",
}

export enum DeliveryMethod {
  HOME_DELIVERY = "home_delivery",
  STORE_PICKUP = "store_pickup",
}

export enum CustomerGender {
  MALE = "male",
  FEMALE = "female",
}

export enum CustomerType {
  PERSON = "person",
  COMPANY = "company",
}

export enum LineItemType {
  PHYSICAL = "physical",
  SHIPPING = "shipping",
  COUPON = "coupon",
}

export enum WebScoreStatus {
  CREATED = "created",
  IN_PROGRESS = "in_progress",
  SUCCESS = "success",
  FAILED = "failed",
  EXPIRED = "expired",
}

export enum OpenBankingStatus {
  CREATED = "created",
  IN_PROGRESS = "in_progress",
  SUCCESS = "success",
  FAILED = "failed",
  EXPIRED = "expired",
}

export enum FinancingEnveloppeRequestStatus {
  CREATED = "created",
  PENDING = "pending",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  CREATION_ERROR = "creation_error",
  CANCELED = "canceled",
  TIMEOUT = "timeout",
}

export enum CalfStatus {
  OK_ACCEPTED_LEASING = "MYL_200_OK_ACCEPTED_LEASING",
  OK_ACCEPTED_LEASING_AFTER_REVIEW = "MYL_200_OK_ACCEPTED_LEASING_AFTER_REVIEW",
  OK_CONFIRMED_LEASING = "MYL_200_OK_CONFIRMED_LEASING",
  SCORE_PENDING = "MYL_200_SCORE_PENDING",
  WAITING_DECISION = "MYL_200_WAITING_DECISION",
  OK_ASSET_DEFINITION = "MYL_200_OK_ASSET_DEFINITION",
  KO_REFUSED_LEASING_AFTER_REVIEW = "MYL_200_KO_REFUSED_LEASING_AFTER_REVIEW",
  ABORTED_LEASING = "MYL_200_ABORTED_LEASING",
}

export enum SessionStepStatus {
  NOT_STARTED = "not_started",
  PENDING = "pending",
  RETRY = "retry",
  FINISHED = "finished",
}

export enum SessionStatus {
  CREATED = "created",
  PENDING_ID_VERIFICATION = "pending_id_verification",
  ID_VERIFIED = "id_verified",
  ACTIVE = "active",
  PREPARING_PAYMENT = "preparing_payment",
  WEB_SCORING_REJECTED = "web_scoring_rejected",
  OPEN_BANKING_SCORING_REJECTED = "open_banking_scoring_rejected",
  BLOCKED = "blocked",
  PENDING_DOCUMENTS = "pending_documents",
  PENDING_E_SIGNATURE = "pending_e_signature",
  PENDING_REVIEW = "pending_review",
  REVIEWED = "reviewed",
  CANCELED = "canceled",
  REJECTED = "rejected",
  EXPIRED = "expired",
  COMPLETE = "complete",
  CALF_REJECTED = "calf_rejected",
}

export type LineItem = z.infer<typeof lineItemSchema>;

export type Session = z.infer<typeof sessionSchema>;

export type SessionAddress = z.infer<typeof sessionAddressSchema>;

export type ExpiredSession = z.infer<typeof expiredSessionSchema>;

export enum SessionDocumentType {
  IDENTITY = "identity",
  TAX = "tax",
  SALARY = "salary",
  CORPORATION_CERTIFICATE = "corporation_certificate",
  BANK = "bank",
}

export enum ContractSignatureType {
  CACF = "cacf_contract",
  CALF = "calf_contract",
}

export type SessionDocument = z.infer<typeof sessionDocumentSchema>;

export type SessionDocumentUpload = {
  session_id: string;
  document_type: SessionDocumentType;
  files: File[];
};

export type SessionRepresentativeIdUpload = {
  session_id: string;
  files: File[];
};

export type SessionDocumentDelete = {
  session_id: string;
  document_id: string;
};
