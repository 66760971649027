import { Group, Image, Stack, Text, Title } from "@mantine/core";
import { Button } from "components";
import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ExpiredSketch } from "resources/sketches/expired.svg";
import { ExpiredSession } from "types";
import { useStyles } from "./ExpiredCheckout.styles";

interface Props {
  session: ExpiredSession;
}

export const ExpiredCheckout: React.FC<Props> = ({
  session: { style, shop_url, retailer_name },
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles({ color: style.primary_color });

  return (
    <Stack align="center" maw={800} spacing="lg">
      <Image src={style.logo_url} width={200} />
      <Group spacing="lg" align="center">
        <Stack spacing="lg" className={classes.stack}>
          <Title size="h3">{t("checkout.yourSessionHasExpired")}</Title>
          <Text>{t("checkout.yourSessionHasExpiredMessage")}</Text>
          <a href={shop_url}>
            <Button color={style.primary_color} w="100%">
              {t("common.goToWebsite", { retailerName: retailer_name })}
            </Button>
          </a>
        </Stack>
        <ExpiredSketch className={classes.sketch} />
      </Group>
    </Stack>
  );
};
