import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Retailer, RoutePath, Session } from "types";

export const useRedirect = (session: Session, retailer: Retailer) => {
  const navigate = useNavigate();

  const redirect = useCallback(
    (
      path:
        | RoutePath.CHECKOUT_REJECTED
        | RoutePath.CHECKOUT_PAYMENT_FAILED
        | RoutePath.CHECKOUT_PENDING_REVIEW
        | RoutePath.CHECKOUT_PENDING_CONTRACT
        | RoutePath.CHECKOUT_SUCCESS,
      rentalId?: string | null
    ) => {
      switch (path) {
        case RoutePath.CHECKOUT_REJECTED: {
          if (retailer.urls_config.reject_return_url) {
            window.location.replace(retailer.urls_config.reject_return_url);
            return;
          }
          break;
        }
        case RoutePath.CHECKOUT_PAYMENT_FAILED: {
          if (retailer.urls_config.payment_failed_url) {
            window.location.replace(retailer.urls_config.payment_failed_url);
            return;
          }
          break;
        }
        case RoutePath.CHECKOUT_PENDING_REVIEW: {
          const params = rentalId ? `?rental_id=${rentalId}` : `?session_id=${session.id}`;

          if (retailer.urls_config.pending_validation_url) {
            window.location.replace(retailer.urls_config.pending_validation_url + params);

            return;
          }
          break;
        }
        case RoutePath.CHECKOUT_PENDING_CONTRACT:
        case RoutePath.CHECKOUT_SUCCESS: {
          if (!rentalId) throw Error("Rental id must be provided for this route.");

          if (retailer.urls_config.success_return_url) {
            window.location.replace(
              retailer.urls_config.success_return_url +
                `?rental_id=${rentalId}&session_id=${session.id}`
            );
            return;
          }
          break;
        }
      }

      navigate(path.replace(":retailerId", retailer.id), { replace: true });
    },
    [
      navigate,
      retailer.id,
      retailer.urls_config.reject_return_url,
      retailer.urls_config.payment_failed_url,
      retailer.urls_config.pending_validation_url,
      retailer.urls_config.success_return_url,
      session.id,
    ]
  );

  return redirect;
};
