import { Group, Title } from "@mantine/core";
import { useCheckoutContext } from "contexts/CheckoutContext";
import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as HandCheckSketch } from "resources/sketches/hand-check.svg";
import { useStyles } from "./CheckoutTitle.styles";

interface Props {
  onlyOnLargeScreen?: boolean;
}

export const CheckoutTitle: React.FC<Props> = ({ onlyOnLargeScreen = false }) => {
  const { t } = useTranslation();
  const { retailer } = useCheckoutContext();
  const { classes } = useStyles(onlyOnLargeScreen);

  return (
    <Group position="apart" className={classes.container}>
      <Title size="h5">{t("checkout.yourRentalWithSline")}</Title>

      <HandCheckSketch color={retailer.style.primary_color} className={classes.sketch} />
    </Group>
  );
};
