import { useNetwork } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const useNetworkNotification = () => {
  const { online } = useNetwork();
  const { t } = useTranslation();

  useEffect(() => {
    if (!online) {
      notifications.show({
        id: "network-offline",
        title: t("network-notification.title"),
        message: t("network-notification.message"),
        color: "red",
        autoClose: false,
      });
    } else {
      notifications.hide("network-offline");
    }
  }, [online, t]);
};
