import { useSetState } from "@mantine/hooks";
import { useMutation } from "@tanstack/react-query";
import { useCheckoutContext } from "contexts/CheckoutContext";
import { areAddressesDifferent } from "helpers/address";
import { updateSessionQuery } from "hooks";
import pick from "lodash/pick";
import React, { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { updateSession } from "services/api/session";
import { CustomerType, DeliveryMethod } from "types";
import {
  _CheckoutCustomerContextProvider,
  CheckoutCustomerData,
  CheckoutCustomerFormStep,
  CompanyAddress,
} from "./CheckoutCustomerContext";

export const CheckoutCustomerContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { session, nextStep } = useCheckoutContext();
  const [data, setData] = useSetState<CheckoutCustomerData>(
    pick(session, "session_customer", "billing_address", "shipping_address", "representative")
  );

  const [companyAddresses, setCompanyAddresses] = useState<CompanyAddress[]>([]);

  const [isRepresentative, setIsRepresentative] = useState(true);

  const { session_customer, shipping_address, billing_address } = data;

  const [isShippingPersonDifferent, setIsShippingPersonDifferent] = useState(
    (() => {
      if (!shipping_address.last_name || !shipping_address.first_name) return false;

      return (
        shipping_address.last_name !== session_customer.last_name ||
        shipping_address.first_name !== session_customer.first_name
      );
    })()
  );

  const [isBillingAddressDifferent, setIsBillingAddressDifferent] = useState(
    (() => {
      if (!billing_address.street_address || !shipping_address.city) return false;

      return areAddressesDifferent(billing_address, shipping_address);
    })()
  );

  const customerSteps = useMemo(() => {
    const steps: CheckoutCustomerFormStep[] = [];

    if (session_customer.customer_type === CustomerType.COMPANY) {
      steps.push(CheckoutCustomerFormStep.COMPANY);
    }

    if (session_customer.customer_type === CustomerType.PERSON) {
      steps.push(CheckoutCustomerFormStep.INFORMATION);
    } else if (!isRepresentative) {
      steps.push(CheckoutCustomerFormStep.INFORMATION);
    }

    if (session_customer.customer_type === CustomerType.COMPANY) {
      steps.push(CheckoutCustomerFormStep.REPRESENTATIVE);
    }

    if (session.delivery_method === DeliveryMethod.HOME_DELIVERY) {
      steps.push(CheckoutCustomerFormStep.SHIPPING);
    }

    if (
      session.delivery_method === DeliveryMethod.STORE_PICKUP ||
      isBillingAddressDifferent ||
      session_customer.customer_type === CustomerType.COMPANY
    ) {
      steps.push(CheckoutCustomerFormStep.BILLING);
    }

    steps.push(CheckoutCustomerFormStep.VALIDATION);

    return steps;
  }, [
    session_customer.customer_type,
    isRepresentative,
    session.delivery_method,
    isBillingAddressDifferent,
  ]);

  const [currentCustomerFormStep, setCurrentCustomerFormStep] = useState<CheckoutCustomerFormStep>(
    customerSteps[0]
  );

  const nextCustomerFormStep = useCallback(() => {
    const stepIndex = customerSteps.indexOf(currentCustomerFormStep);

    if (stepIndex === customerSteps.length - 1) return currentCustomerFormStep;

    const newCustomerStep = customerSteps[stepIndex + 1];
    setCurrentCustomerFormStep(newCustomerStep);

    return newCustomerStep;
  }, [currentCustomerFormStep, customerSteps]);

  const previousCustomerFormStep = useCallback(() => {
    const stepIndex = customerSteps.indexOf(currentCustomerFormStep);

    if (stepIndex === 0) return currentCustomerFormStep;

    const newCustomerStep = customerSteps[stepIndex - 1];
    setCurrentCustomerFormStep(newCustomerStep);

    return newCustomerStep;
  }, [currentCustomerFormStep, customerSteps]);

  const resetCustomerFormStep = useCallback(() => {
    const newCurrentCustomerStep = customerSteps[0];
    setCurrentCustomerFormStep(newCurrentCustomerStep);

    return newCurrentCustomerStep;
  }, [customerSteps]);

  const { mutate: validate, isLoading: isValidating } = useMutation({
    mutationFn: () => updateSession({ id: session.id, ...data }),
    onSuccess: async (updatedSession) => {
      updateSessionQuery(updatedSession.id, updatedSession);
      nextStep();
    },
  });

  return (
    <_CheckoutCustomerContextProvider
      value={{
        customerSteps,
        currentCustomerFormStep,
        nextCustomerFormStep,
        previousCustomerFormStep,
        resetCustomerFormStep,
        data,
        setData,
        companyAddresses,
        setCompanyAddresses,
        isRepresentative,
        setIsRepresentative,
        isShippingPersonDifferent,
        setIsShippingPersonDifferent,
        isBillingAddressDifferent,
        setIsBillingAddressDifferent,
        validate,
        isValidating,
      }}
    >
      {children}
    </_CheckoutCustomerContextProvider>
  );
};
