import { Group, Header as MantineHeader } from "@mantine/core";
import React from "react";
import { ReactComponent as SlineLogo } from "resources/icons/sline-logo.svg";
import { useStyles } from "./Header.styles";

export const HEADER_HEIGHT = 64;

export const Header: React.FC = () => {
  const { classes } = useStyles();

  return (
    <MantineHeader height={HEADER_HEIGHT} withBorder={false}>
      <Group className={classes.header} position="apart">
        <SlineLogo className={classes.logo} data-testid="sline-logo" />
      </Group>
    </MantineHeader>
  );
};
