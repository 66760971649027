import { createStyles } from "@mantine/core";

interface Params {
  color: string;
  focused: boolean;
  empty: boolean;
  withLabel: boolean;
  error: boolean;
}

export const useStyles = createStyles<string, Params>(
  ({ colors, white, spacing, fontSizes, radius }, { color, focused, empty, withLabel, error }) => ({
    inputWrapper: {
      position: "relative",
      marginTop: withLabel ? `calc(${spacing.xs}/2)` : undefined,
    },

    label: {
      position: "absolute",
      pointerEvents: "none",
      zIndex: 1,
      cursor: "text",
      left: spacing.xs,
      right: focused || !empty ? undefined : spacing.xs,
      top: focused || !empty ? 0 : "50%",
      transform: "translateY(-50%)",
      backgroundColor: white,
      borderRadius: radius.xl,
      paddingLeft: `calc(${spacing.xs}/2)`,
      paddingRight: `calc(${spacing.xs}/2)`,
      fontSize: focused || !empty ? fontSizes.xs : fontSizes.sm,
      fontWeight: 500,
      transition: "all 125ms ease-in-out",
      color: error ? colors.red[6] : focused ? color : colors.gray[6],
    },

    input: {
      cursor: "text",
      paddingLeft: spacing.sm,
      paddingRight: spacing.sm,
      paddingTop: withLabel ? `calc(${spacing.xs}/2)` : undefined,
      minHeight: "2.5rem",
      fontWeight: 400,
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: error ? colors.red[6] : focused ? color : colors.gray[4],
      borderRadius: radius.sm,
      color: error ? colors.red[6] : undefined,
      display: "flex",
      alignItems: "center",
    },

    internalInput: {
      all: "unset",

      "::-webkit-outer-spin-button, ::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
      },

      MozAppearance: "textfield",
      borderStyle: "none",
      textAlign: "center",
      ":last-of-type": {
        textAlign: "left",
        flex: 1,
      },
    },
  })
);
