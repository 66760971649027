import { Stack } from "@mantine/core";
import React from "react";
import { CheckoutOrderSummary } from "./CheckoutOrderSummary";
import { CheckoutOrderIncludedItems } from "./IncludedItems";
import { CheckoutOrderPurchasedItems } from "./PurchasedItems";
import { CheckoutOrderRentedItems } from "./RentedItems";

export const CheckoutOrder: React.FC = () => {
  return (
    <Stack>
      <CheckoutOrderSummary />
      <CheckoutOrderRentedItems />
      <CheckoutOrderPurchasedItems />
      <CheckoutOrderIncludedItems />
    </Stack>
  );
};
