import { createStyles, getStylesRef } from "@mantine/core";
import { LIGHT_GRAY } from "theme";

interface Params {
  color: string;
}

export const useStyles = createStyles<string, Params>(
  ({ fontSizes, spacing, radius }, { color }) => ({
    dividerLabel: {
      color,
      textTransform: "uppercase",
      fontSize: fontSizes.sm,
      fontWeight: 700,

      "::before, ::after": {
        borderColor: LIGHT_GRAY,
      },
    },

    image: {
      flexShrink: 0,
    },

    instalmentWrapper: {
      ref: getStylesRef("instalment-wrapper"),
      display: "flex",
      columnGap: "0.75rem",

      "&[data-customer-type='company']": {
        flexDirection: "column",
        rowGap: spacing.xs,
      },
    },

    instalment: {
      flexGrow: 1,
      flexBasis: 100 / 2,
      display: "flex",
      columnGap: spacing.sm,
      alignItems: "baseline",
      justifyContent: "center",
      backgroundColor: LIGHT_GRAY,
      borderRadius: radius.md,
      padding: "0.25rem 0",

      [`.${getStylesRef("instalment-wrapper")}[data-customer-type='company'] &`]: {
        flexDirection: "row-reverse",
        flexBasis: "unset",

        "> *": {
          flexGrow: 1,
          flexBasis: 100 / 3,
          textAlign: "center",
        },
      },
    },
  })
);
