import { Anchor, Text } from "@mantine/core";
import { useEventTracking } from "hooks/analytics";
import React from "react";
import { useTranslation } from "react-i18next";
import { TrackingEventCategory } from "services/analytics";
import { useStyles } from "./Footer.styles";

interface Props {
  rentalConditionsUrl?: string | null;
}

export const Footer: React.FC<Props> = ({ rentalConditionsUrl }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { trackClick } = useEventTracking();

  return (
    <footer className={classes.footer}>
      <div className={classes.linksWrapper}>
        {!!rentalConditionsUrl && (
          <Anchor
            size="sm"
            weight={400}
            href={rentalConditionsUrl}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => trackClick(TrackingEventCategory.LINK, "click_CGL")}
          >
            {t("footer.generalConditionsOfRental")}
          </Anchor>
        )}

        <Anchor
          size="sm"
          weight={400}
          href="https://www.sline.io/politique-de-confidentialite"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => trackClick(TrackingEventCategory.LINK, "click_confidentiality")}
        >
          {t("footer.privacyPolicy")}
        </Anchor>
      </div>

      <Text weight={700} size="sm">
        © Sline.io {new Date().getFullYear()}
      </Text>
    </footer>
  );
};
