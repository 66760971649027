import { createStyles } from "@mantine/core";
import { LIGHT_GREEN, MEDIUM_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(({ spacing, radius, fn: { largerThan } }) => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  contentWrapper: {
    flex: 1,
    rowGap: spacing.md,
    padding: `${spacing.md} ${spacing.sm}`,

    [largerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
      rowGap: spacing.lg,
      padding: `${spacing.lg} ${spacing.sm}`,
    },
  },

  sketch: {
    alignSelf: "center",
  },

  infoWrapper: {
    backgroundColor: LIGHT_GREEN,
    borderRadius: radius.md,
    padding: `${spacing.xs} ${spacing.sm}`,
  },

  infoSketch: {
    flexShrink: 0,
  },
}));
