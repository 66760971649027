import { ibanString } from "schemas";
import { z } from "zod";

export const sepaSchema = z.object({
  iban: ibanString(),
});

export const cardSchema = z.object({
  iban: z.string(),
});
