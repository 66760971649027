import { Group, Text } from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import { CheckoutStep, useCheckoutContext } from "contexts/CheckoutContext";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DARK_GRAY } from "theme";
import { StepModal } from "../../StepModal";
import { CheckoutCustomer } from "../CheckoutCustomer";
import { useCheckoutCustomerContext } from "../Context";

interface Props {
  opened: boolean;
  close: () => void;
}

export const CheckoutCustomerModal: React.FC<Props> = ({ opened, close }) => {
  const { currentStep } = useCheckoutContext();
  const { customerSteps, currentCustomerFormStep, previousCustomerFormStep } =
    useCheckoutCustomerContext();
  const { t } = useTranslation();

  const isWebScoring = useMemo(() => currentStep === CheckoutStep.WEB_SCORING, [currentStep]);
  const isEnveloppeFinancing = useMemo(
    () => currentStep === CheckoutStep.ENVELOPPE_FINANCING,
    [currentStep]
  );

  const modalTitle = useMemo(() => {
    if (currentStep === CheckoutStep.CUSTOMER) {
      const currentCustomerFormStepIndex = customerSteps.indexOf(currentCustomerFormStep);

      return (
        <Group spacing="sm">
          {currentCustomerFormStepIndex > 0 && (
            <IconArrowLeft
              color={DARK_GRAY}
              onClick={previousCustomerFormStep}
              style={{ cursor: "pointer" }}
            />
          )}
          <Text span>{t(`checkout.customerSteps.${currentCustomerFormStep}`)}</Text>
        </Group>
      );
    }

    if (!opened) t("checkout.checkingData");

    return t("checkout.information");
  }, [currentCustomerFormStep, currentStep, customerSteps, opened, previousCustomerFormStep, t]);

  return (
    <StepModal
      opened={opened || isWebScoring || isEnveloppeFinancing}
      onClose={close}
      title={modalTitle}
      data-current-step={currentStep}
    >
      <CheckoutCustomer />
    </StepModal>
  );
};
