import { Flex, Group, Stack, Text, useMantineTheme } from "@mantine/core";
import { IconCalendarEvent, IconCurrencyEuro, IconRefresh } from "@tabler/icons-react";
import { CircledIcon } from "components";
import { useCheckoutContext } from "contexts/CheckoutContext";
import { formatPrice } from "helpers/price";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { GRAY } from "theme";
import { CustomerType } from "types";
import { useStyles } from "./CheckoutOrderSummary.styles";

export const CheckoutOrderSummary = ({ shadowed = false }: { shadowed?: boolean }) => {
  const {
    session: {
      session_customer: { customer_type },
    },
    retailer,
    order,
    selectedDuration,
  } = useCheckoutContext();
  const {
    fn: { lighten },
  } = useMantineTheme();
  const { t } = useTranslation();
  const { classes } = useStyles();

  const selectedDurationConfiguration = useMemo(
    () => retailer.duration_configurations.find(({ duration }) => duration === selectedDuration),
    [retailer.duration_configurations, selectedDuration]
  );

  const itemClass = `${classes.item}${shadowed ? ` ${classes.itemShadowed}` : ""}`;

  return (
    <Group grow align="start" py="xs" spacing={0} className={classes.group}>
      <Flex className={itemClass}>
        <div className={classes.itemSpacer} />
        <Stack className={classes.itemContent}>
          <CircledIcon
            icon={IconCurrencyEuro}
            backgroundColor={lighten(retailer.style.primary_color, 0.9)}
            color={retailer.style.primary_color}
            size={32}
          />

          <Text fw={700} className={classes.itemTitle}>
            {formatPrice(order.selectedDuration.totalFirstInstalmentPriceIncludingVAT)}
          </Text>

          {customer_type === CustomerType.COMPANY && (
            <Text fw={700} c={GRAY} size="sm">
              {t("checkout.priceWithoutVAT", {
                price: formatPrice(order.selectedDuration.totalFirstInstalmentPrice),
              })}
            </Text>
          )}

          <Text fw={700} tt="uppercase" size="0.6rem" mt="0.2rem">
            {t("checkout.firstInstalment")}
          </Text>
        </Stack>
      </Flex>

      <Flex className={itemClass}>
        <div className={classes.itemSpacer} />
        <Stack className={classes.itemContent}>
          <CircledIcon
            icon={IconRefresh}
            backgroundColor={lighten(retailer.style.primary_color, 0.9)}
            color={retailer.style.primary_color}
            size={32}
          />

          <Text fw={700} className={classes.itemTitle}>
            {formatPrice(order.selectedDuration.totalOtherInstalmentPriceIncludingVAT)}
          </Text>

          {customer_type === CustomerType.COMPANY && (
            <Text fw={700} c={GRAY} size="sm">
              {t("checkout.priceWithoutVAT", {
                price: formatPrice(order.selectedDuration.totalOtherInstalmentPrice),
              })}
            </Text>
          )}

          <Text fw={700} tt="uppercase" size="0.6rem" mt="0.2rem">
            {t("checkout.otherInstalment")}
          </Text>
        </Stack>
      </Flex>

      <Flex className={itemClass}>
        <div className={classes.itemSpacer} />
        <Stack className={classes.itemContent}>
          <CircledIcon
            icon={IconCalendarEvent}
            backgroundColor={lighten(retailer.style.primary_color, 0.9)}
            color={retailer.style.primary_color}
            size={32}
          />

          <Text fw={700} className={classes.itemTitle}>
            {selectedDurationConfiguration?.label}
          </Text>

          <Text fw={700} tt="uppercase" size="0.6rem" mt="0.2rem">
            {t("checkout.commitment")}
          </Text>
        </Stack>
      </Flex>
    </Group>
  );
};
