import { getSelectedDuration } from "helpers";
import { updateSessionQuery } from "hooks";
import { useCallback, useState } from "react";
import { updateSessionSelectedDuration } from "services/api/session";
import { Retailer, Session } from "types";

export const useSelectedDuration = (session: Session, retailer: Retailer) => {
  const [selectedDuration, _setSelectedDuration] = useState(() => {
    const selectedDuration = getSelectedDuration(session, retailer);

    if (session.selected_duration === null) {
      updateSessionQuery(session.id, (session) => ({
        ...session,
        selected_duration: selectedDuration,
      }));
      void updateSessionSelectedDuration({ id: session.id, selected_duration: selectedDuration });
    }

    return selectedDuration;
  });

  const setSelectedDuration = useCallback(
    (duration: number) => {
      _setSelectedDuration(duration);

      if (session.selected_duration !== duration) {
        updateSessionQuery(session.id, {
          selected_duration: duration,
        });
        void updateSessionSelectedDuration({
          id: session.id,
          selected_duration: duration,
        });
      }
    },
    [session.id, session.selected_duration]
  );

  return [selectedDuration, setSelectedDuration] as const;
};
