import { Group, List, Stack, Text } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { useCheckoutContext } from "contexts/CheckoutContext";
import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as TechnicalSupportSketch } from "resources/sketches/technical-support.svg";
import { useStyles } from "./CheckoutIdentityVerificationInfo.styles";

export const CheckoutIdentityVerificationInfo: React.FC = () => {
  const { retailer } = useCheckoutContext();
  const { t } = useTranslation();
  const { classes } = useStyles({ color: retailer.style.primary_color });

  return (
    <Stack>
      <Group position="apart" className={classes.infoWrapper}>
        <Text size="sm">{t("checkout.youCanAlsoDownloadDocument")}</Text>
        <TechnicalSupportSketch className={classes.infoSketch} />
      </Group>

      <Stack spacing="xs">
        <Text fw={700}>{t("checkout.acceptedIdDocumentTypes.label")}</Text>

        <List
          icon={<IconCheck />}
          size="sm"
          spacing="0.3rem"
          center
          classNames={{ itemIcon: classes.listItemIcon }}
        >
          {Array.from({ length: 3 }).map((_, index) => (
            <List.Item key={`acceptedIdDocumentTypes-${index}`}>
              {t(`checkout.acceptedIdDocumentTypes.${index}`)}
            </List.Item>
          ))}
        </List>
      </Stack>

      <Stack spacing="xs">
        <Text fw={700}>{t("checkout.documentValidity.label")}</Text>

        <List
          icon={<IconCheck />}
          size="sm"
          spacing="0.3rem"
          center
          classNames={{ itemIcon: classes.listItemIcon }}
        >
          {Array.from({ length: 3 }).map((_, index) => (
            <List.Item key={`documentValidity-${index}`}>
              {t(`checkout.documentValidity.${index}`)}
            </List.Item>
          ))}
        </List>
      </Stack>
    </Stack>
  );
};
