import { Center, Group, Image, Loader, Stack, Text, Title } from "@mantine/core";
import { Button, Footer } from "components";
import { useRetailerQuery, useVisualViewportHeight } from "hooks";
import { usePageViewTracking } from "hooks/analytics";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ReactComponent as ErrorSketch } from "resources/sketches/error.svg";
import { ReactComponent as PaymentFailedSketch } from "resources/sketches/payment-failed.svg";
import { ReactComponent as PendingContractSketch } from "resources/sketches/pending-contract.svg";
import { ReactComponent as PendingReviewSketch } from "resources/sketches/pending-review.svg";
import { ReactComponent as RejectedSketch } from "resources/sketches/rejected.svg";
import { ReactComponent as SuccessSketch } from "resources/sketches/success.svg";
import { useStyles } from "./CheckoutStatusPage.styles";

export enum CheckoutStatus {
  PAYMENT_FAILED = "payment-failed",
  REJECTED = "rejected",
  PENDING_REVIEW = "pending-review",
  PENDING_CONTRACT = "pending-contract",
  SUCCESS = "success",
  ERROR = "error",
}

const sketchByCheckoutStatus: Record<CheckoutStatus, React.FC<React.SVGProps<SVGSVGElement>>> = {
  [CheckoutStatus.PAYMENT_FAILED]: PaymentFailedSketch,
  [CheckoutStatus.REJECTED]: RejectedSketch,
  [CheckoutStatus.PENDING_REVIEW]: PendingReviewSketch,
  [CheckoutStatus.PENDING_CONTRACT]: PendingContractSketch,
  [CheckoutStatus.SUCCESS]: SuccessSketch,
  [CheckoutStatus.ERROR]: ErrorSketch,
};

interface Props {
  status: CheckoutStatus;
}

export const CheckoutStatusPage: React.FC<Props> = ({ status }) => {
  usePageViewTracking(`checkout-${status}`);

  const { t } = useTranslation();
  const { retailerId } = useParams<"retailerId">();
  const visualViewportHeight = useVisualViewportHeight();
  const { classes } = useStyles();

  const { retailer } = useRetailerQuery(retailerId);

  const Sketch = sketchByCheckoutStatus[status];

  if (!retailer)
    return (
      <Center h={visualViewportHeight}>
        <Loader />
      </Center>
    );

  const {
    style,
    urls_config: { shop_url },
    name: retailerName,
  } = retailer;

  return (
    <Stack spacing="xl" justify="space-between" mih={visualViewportHeight}>
      <Center py="4rem" px="sm">
        <Stack align="center" maw={800} spacing="xl">
          <Image src={style.logo_url} width={250} height={125} fit="contain" />

          <Group spacing="lg" align="center">
            <Stack spacing="lg" className={classes.container}>
              <Title size="h3">{t(`checkoutStatus.${status}.title`)}</Title>
              <Text>{t(`checkoutStatus.${status}.message`)}</Text>
              <a href={shop_url}>
                <Button color={style.primary_color} w="100%">
                  {t("common.goToWebsite", { retailerName })}
                </Button>
              </a>
            </Stack>
            <Sketch className={classes.sketch} color={style.primary_color} />
          </Group>
        </Stack>
      </Center>
      <Footer rentalConditionsUrl={retailer.urls_config.rental_conditions_url} />
    </Stack>
  );
};
