import { Stripe, loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { Retailer } from "types";

export const useStripe = (retailer: Retailer) => {
  const [stripe, setStripe] = useState<Stripe | null>(null);

  useEffect(() => {
    loadStripe(retailer.payment_configuration.payment_processor_public_api_key).then((stripe) =>
      setStripe(stripe)
    );
  }, [retailer.payment_configuration.payment_processor_public_api_key]);

  return stripe;
};
