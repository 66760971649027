import { createStyles } from "@mantine/core";
import { LIGHT_GREEN } from "theme";

export const useStyles = createStyles(({ spacing, radius }) => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  contentWrapper: {
    flex: 1,
    padding: spacing.sm,
  },

  infoWrapper: {
    backgroundColor: LIGHT_GREEN,
    borderRadius: radius.md,
    padding: `${spacing.xs} ${spacing.sm}`,
  },

  infoSketch: {
    flexShrink: 0,
  },
}));
