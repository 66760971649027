import { Stack, Text } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import { CircledIcon } from "components";
import { IdentityVerificationError } from "contexts/CheckoutContext";
import React from "react";
import { useTranslation } from "react-i18next";
import { LIGHT_RED, RED } from "theme";

interface Props {
  error: IdentityVerificationError;
}

export const CheckoutIdentityVerificationError: React.FC<Props> = ({ error }) => {
  const { t } = useTranslation();

  return (
    <Stack align="center">
      <CircledIcon icon={IconX} backgroundColor={LIGHT_RED} color={RED} size={40} />

      <Stack spacing="xs">
        <Text ta="center" fw={700} size="lg">
          {t(`checkout.identityVerificationErrors.${error}.title`)}
        </Text>

        <Text ta="center">{t(`checkout.identityVerificationErrors.${error}.description`)}</Text>
      </Stack>
    </Stack>
  );
};
