import { Flex, ScrollArea, Stack } from "@mantine/core";
import { Button, Footer } from "components";
import { useCheckoutContext } from "contexts/CheckoutContext";
import React from "react";
import { useTranslation } from "react-i18next";
import { CheckoutOrderDetailsModal } from "../CheckoutOrderDetailsModal";
import { CheckoutTitle } from "../Title";
import { useStyles } from "./CheckoutPlan.styles";
import { CheckoutStagingPayload } from "./CheckoutStagingPayload";
import { CheckoutPlanDetails } from "./Details";
import { CheckoutPlanSelect } from "./Select";
import { CheckoutSubscriptionAutomatisationModal } from "./SubscriptionAutomatisationModal/CheckoutSubscriptionAutomatisationModal";

interface Props {
  onPlanSelected: () => void;
}

export const CheckoutPlan: React.FC<Props> = ({ onPlanSelected }) => {
  const { retailer } = useCheckoutContext();
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Flex direction="column" className={classes.container}>
      <ScrollArea classNames={{ root: classes.scrollArea, viewport: classes.scrollAreaViewport }}>
        <div className={classes.wrapper}>
          <div className={classes.row}>
            <div className={classes.leftSection}>
              <CheckoutTitle />
              <CheckoutStagingPayload />
            </div>
          </div>

          <div className={classes.row}>
            <div className={classes.leftSection}>
              <CheckoutPlanSelect />

              <CheckoutOrderDetailsModal />

              <div className={classes.largeScreenButtonWrapper}>
                <Button color={retailer.style.primary_color} onClick={onPlanSelected}>
                  {t("checkout.continue")}
                </Button>
              </div>
            </div>

            <Stack className={classes.rightSection}>
              <CheckoutPlanDetails />
              <CheckoutSubscriptionAutomatisationModal />
            </Stack>
          </div>
        </div>

        <Footer rentalConditionsUrl={retailer.urls_config.rental_conditions_url} />
      </ScrollArea>

      <div className={classes.mediumScreenBottomBar}>
        <Button
          color={retailer.style.primary_color}
          className={classes.mediumScreenButton}
          onClick={onPlanSelected}
        >
          {t("checkout.continue")}
        </Button>
      </div>
    </Flex>
  );
};
