import { createStyles } from "@mantine/core";
import { LIGHT_GREEN, MEDIUM_SCREEN_BREAKPOINT } from "theme";

const MAX_WIDTH = 700;

interface Params {
  color: string;
}

export const useStyles = createStyles<string, Params>(
  ({ spacing, shadows, radius, fn: { largerThan, smallerThan } }, { color }) => ({
    container: {
      flex: 1,
      minHeight: 0,
    },

    scrollArea: {
      flex: 1,
      [smallerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
        paddingBottom: "4.25rem",
      },
    },

    wrapper: {
      flex: 1,
      maxWidth: MAX_WIDTH,
    },

    header: {
      [largerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
        justifyContent: "center",
      },
    },

    sketch: {
      flexShrink: 0,
      [largerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
        display: "none",
      },
    },

    listItemIcon: {
      marginRight: spacing.xs,

      svg: {
        color,
        display: "block",
        strokeWidth: 3,
        height: 20,
        width: 20,
      },
    },

    infoWrapper: {
      backgroundColor: LIGHT_GREEN,
      borderRadius: radius.md,
      padding: `${spacing.xs} ${spacing.sm}`,
    },

    infoSketch: {
      flexShrink: 0,
    },

    buttonWrapper: {
      [smallerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
        backgroundColor: "white",
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        boxShadow: shadows.lg,
      },
      [largerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
        alignItems: "center",
      },
    },

    link: {
      [smallerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
        width: "100%",
        maxWidth: MAX_WIDTH,
      },
    },

    button: {
      [smallerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
        width: "100%!important",
      },
    },
  })
);
