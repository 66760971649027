import { createStyles } from "@mantine/core";
import { HEADER_HEIGHT } from "components";
import { VISUAL_VIEWPORT_HEIGHT_CSS_VARIABLE_NAME } from "hooks";
import { MEDIUM_SCREEN_BREAKPOINT } from "theme";

const MEDIUM_SCREEN_MAX_WIDTH = 700;
const MEDIUM_SCREEN_BOTTOM_BAR_HEIGHT = 70;

export const useStyles = createStyles(({ shadows, fn: { largerThan, smallerThan }, spacing }) => ({
  container: {
    flex: 1,
    minHeight: 0,

    [largerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
      backgroundColor: "#FAFAFA",
    },
  },

  scrollArea: {
    flex: 1,
  },

  scrollAreaViewport: {
    "> div": {
      display: "flex !important",
      flexDirection: "column",
      justifyContent: "space-between",
      minHeight: "100%",
      paddingTop: spacing.md,
      rowGap: spacing.md,

      [largerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
        paddingTop: spacing.xl,
      },
    },
  },

  wrapper: {
    [smallerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
      minHeight: `calc(var(${VISUAL_VIEWPORT_HEIGHT_CSS_VARIABLE_NAME}) - ${HEADER_HEIGHT}px - ${MEDIUM_SCREEN_BOTTOM_BAR_HEIGHT}px)`,
    },
  },

  row: {
    display: "flex",
    flexDirection: "column",
    padding: spacing.sm,
    margin: "0 auto",

    [smallerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
      maxWidth: MEDIUM_SCREEN_MAX_WIDTH,
    },

    [largerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
      flexDirection: "row",
      alignItems: "flex-start",
      gap: "8%",
      paddingLeft: 96,
      paddingRight: 96,
    },
  },

  leftSection: {
    display: "flex",
    flexDirection: "column",
    maxWidth: MEDIUM_SCREEN_MAX_WIDTH,

    [largerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
      width: "62%",
      maxWidth: "none",
    },
  },

  rightSection: {
    [largerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
      width: "30%",
    },
  },

  largeScreenButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",

    [smallerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
      display: "none",
    },
  },

  mediumScreenBottomBar: {
    height: MEDIUM_SCREEN_BOTTOM_BAR_HEIGHT,
    boxShadow: shadows.lg,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    [largerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
      display: "none",
    },
  },

  mediumScreenButton: {
    minWidth: "50%",
  },
}));
