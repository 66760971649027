import { ContractSignatureType } from "types";
import { apiClient } from "../client";

interface CreateParams {
  session_id: string;
  signatory_first_name: string;
  signatory_last_name: string;
  signatory_email: string;
  signatory_job_title?: string;
  signatory_phone: string;
  document_type: ContractSignatureType;
  delivery_mode: "email" | "sms" | "email_sms";
}

export const requestESignature = ({ session_id, ...data }: CreateParams) =>
  apiClient.post(`sessions/${session_id}/e_signature`, data);
