import { useForm as useMantineForm, zodResolver } from "@mantine/form";
import {
  UseFormInput as MantineUseFormInput,
  UseFormReturnType as MantineUseFormReturnType,
  OnSubmit,
} from "@mantine/form/lib/types";
import { z } from "zod";

type Nullable<T> = T extends Record<string, unknown>
  ? {
      [K in keyof T]: T[K] extends string | File ? T[K] | null : Nullable<T[K]>;
    }
  : T extends unknown[]
  ? Nullable<T[number]>[]
  : T;

export declare type TransformValues<Values> = (values: Values) => Values;

type UseFormInput<Schema extends z.ZodType> = MantineUseFormInput<
  Nullable<z.input<Schema>>,
  TransformValues<z.infer<Schema>>
> & { schema: Schema };

type UseFormReturnType<Schema extends z.ZodType> = Omit<
  MantineUseFormReturnType<Nullable<z.input<Schema>>, TransformValues<z.infer<Schema>>>,
  "onSubmit"
> & {
  onSubmit: OnSubmit<z.infer<Schema>, TransformValues<z.infer<Schema>>>;
};

export const useForm = <Schema extends z.ZodType>({
  schema,
  initialValues,
  initialErrors,
  clearInputErrorOnChange,
  validateInputOnChange,
}: UseFormInput<Schema>): UseFormReturnType<Schema> => {
  const form = useMantineForm({
    validate: zodResolver(schema),
    initialValues,
    initialErrors,
    clearInputErrorOnChange,
    validateInputOnChange,
  });

  return {
    ...form,
    onSubmit: (handleSubmit, handleValidationFailure) =>
      form.onSubmit(
        (values, event) => handleSubmit(schema.parse(values) as z.infer<Schema>, event),
        handleValidationFailure
      ),
  };
};
