import { createStyles, getStylesRef } from "@mantine/core";

interface Params {
  color: string;
}

export const useStyles = createStyles<string, Params>(({ spacing, fn: { rgba } }, { color }) => ({
  root: {
    borderWidth: 1,
    borderColor: color,
    "&[data-accept]": {
      backgroundColor: rgba(color, 0.04),
    },
    "&[tabindex]:hover": { backgroundColor: rgba(color, 0.04) },
    ":not([tabindex])": {
      ":hover": { backgroundColor: "transparent" },
      cursor: "not-allowed",
    },
    [`.${getStylesRef("loading-overlay-root")}`]: {
      justifyContent: "flex-end",
      paddingRight: spacing.sm,
    },
  },
}));
