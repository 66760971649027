import { createStyles } from "@mantine/core";
import { LIGHT_GREEN } from "theme";

interface Params {
  color: string;
}

export const useStyles = createStyles<string, Params>(({ spacing, radius }, { color }) => ({
  infoWrapper: {
    backgroundColor: LIGHT_GREEN,
    borderRadius: radius.md,
    padding: `${spacing.xs} ${spacing.sm}`,
  },

  infoSketch: {
    flexShrink: 0,
  },

  listItemIcon: {
    marginRight: spacing.xs,

    svg: {
      color,
      display: "block",
      strokeWidth: 3,
      height: 20,
      width: 20,
    },
  },
}));
