import { CSSObject, MantineTheme } from "@mantine/core";
import { mantineTheme } from "theme";

export const useStyles = (color: string) => ({
  styles: ({ white, fn: { rgba } }: MantineTheme) =>
    ({
      ":root": {
        "--pka-font-family": mantineTheme.fontFamily,
      },

      ".pka-panel-suggestions": {
        maxHeight: "7.5rem",
      },

      ".pka-panel-suggestion.pka-active": {
        backgroundColor: color,
      },

      "button.pka-panel-suggestion-action": {
        backgroundColor: rgba(white, 0.6),
      },
    } as CSSObject),
});
