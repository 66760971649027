import { Mode } from "types";
import { TrackingEventParams } from "./types";

export class MatomoInstance {
  constructor() {
    if (import.meta.env.MODE === Mode.TEST || typeof window === "undefined") return;

    window._mtm = window._mtm ?? [];
    window._mode = import.meta.env.MODE;

    if (window._mtm.length !== 0) return;

    const containerId = import.meta.env.VITE_MATOMO_CONTAINER_ID;

    if (!containerId)
      throw new Error("Environment variable VITE_MATOMO_MAIN_CONTAINER_ID not found");

    const script = document.createElement("script");

    script.innerHTML = `
    var _mtm = window._mtm = window._mtm || [];
    _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src='https://cdn.matomo.cloud/sline.matomo.cloud/container_${containerId}.js';
    document.head.insertBefore(g, s);
  `;
    document.head.insertBefore(script, document.head.firstChild);
  }

  trackEvent = ({ type, category, action, name, eventData }: TrackingEventParams) => {
    if (import.meta.env.MODE === Mode.TEST || typeof window === "undefined") return;

    window._mtm?.push({
      event: type,
      category,
      action,
      name,
      eventData,
    });
  };

  scanForForms = () => {
    if (import.meta.env.MODE === Mode.TEST || typeof window === "undefined") return;

    window._paq?.push(["FormAnalytics::scanForForms"]);
  };
}
