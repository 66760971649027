import { createStyles } from "@mantine/core";
import { MEDIUM_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(({ spacing, fn: { smallerThan, largerThan } }) => ({
  header: {
    height: "100%",
    backgroundColor: "#002566",
    [smallerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
      paddingLeft: spacing.sm,
      paddingRight: spacing.sm,
    },
    [largerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
      paddingLeft: "5rem",
      paddingRight: "5rem",
    },
  },
  logo: {
    path: {
      fill: "white",
    },
    width: "auto",
    [smallerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
      height: 24,
    },
    [largerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
      height: 32,
    },
  },
}));
