import { createStyles } from "@mantine/core";
import { LIGHT_GRAY } from "theme";

interface Params {
  color: string;
}

export const useStyles = createStyles<string, Params>(({ fontSizes }, { color }) => ({
  dividerLabel: {
    color,
    textTransform: "uppercase",
    fontSize: fontSizes.sm,
    fontWeight: 700,
    textAlign: "center",

    "::before, ::after": {
      borderColor: LIGHT_GRAY,
    },
  },
}));
