import { createStyles } from "@mantine/core";

interface Params {
  color: string;
  focused: boolean;
  empty: boolean;
  withLabel: boolean;
  error: boolean;
}

export const useStyles = createStyles<string, Params>(
  ({ colors, white, spacing, fontSizes, radius }, { color, focused, empty, withLabel, error }) => ({
    root: {
      position: "relative",
      marginTop: withLabel ? `calc(${spacing.xs}/2)` : undefined,
    },

    label: {
      position: "absolute",
      userSelect: "none",
      zIndex: 1,
      cursor: "text",
      left: 0,
      top: focused || !empty ? 0 : "50%",
      transform: `translate(${spacing.xs}, -50%)`,
      backgroundColor: white,
      borderRadius: radius.xl,
      paddingLeft: `calc(${spacing.xs}/2)`,
      paddingRight: `calc(${spacing.xs}/2)`,
      fontSize: focused || !empty ? fontSizes.xs : fontSizes.sm,
      fontWeight: 500,
      transition: "all 125ms ease-in-out",
      color: error ? colors.red[6] : focused ? color : colors.gray[6],
    },

    input: {
      paddingTop: withLabel ? `calc(${spacing.xs}/2)` : undefined,
      minHeight: "2.5rem",
      fontWeight: 400,

      ":focus": {
        borderColor: !error ? color : colors.red[6],
      },

      "&[data-disabled]": {
        background: "unset",
      },
    },
  })
);
