import { createStyles, getStylesRef } from "@mantine/core";
import { GRAY, MEDIUM_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(({ shadows, spacing, fontSizes, fn: { largerThan } }) => ({
  card: {
    height: 52,
    margin: `0 ${spacing.sm}`,
    boxShadow: "none",
    borderColor: GRAY,
    cursor: "pointer",

    transitionProperty: "height, margin, box-shadow",
    transitionDuration: "800ms",
    transitionTimingFunction: "cubic-bezier(0.7, -0.4, 0.4, 1.4)",

    "&[data-status=incomplete]": {
      pointerEvents: "none",

      [`.${getStylesRef("card-title")}`]: {
        color: GRAY,
      },

      [`.${getStylesRef("card-sketch")}`]: {
        height: 16,
        width: 16,
        filter: "grayscale(1)",
      },

      [`.${getStylesRef("card-chevron")}`]: {
        opacity: 0,
      },
    },

    "&[data-status=active]": {
      height: 56,
      margin: 0,
      boxShadow: shadows.xl,

      [`.${getStylesRef("card-title")}`]: {
        fontSize: fontSizes.lg,
      },

      [largerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
        height: 80,
      },
    },

    "&[data-status=complete]": {
      boxShadow: shadows.xs,
      pointerEvents: "none",

      [`.${getStylesRef("card-chevron")}`]: {
        opacity: 0,
      },
    },

    [largerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
      height: 72,
    },
  },

  sketch: {
    ref: getStylesRef("card-sketch"),
    height: 32,
    width: 32,

    transitionProperty: "height, width, filter",
    transitionDuration: "800ms",
    transitionTimingFunction: "cubic-bezier(0.7, -0.4, 0.4, 1.4)",
  },

  title: {
    ref: getStylesRef("card-title"),
    fontSize: fontSizes.md,

    transitionProperty: "font-size",
    transitionDuration: "800ms",
    transitionTimingFunction: "cubic-bezier(0.7, -0.4, 0.4, 1.4)",
  },

  chevron: {
    ref: getStylesRef("card-chevron"),
  },
}));
