import { Box, Center, Loader, Stack } from "@mantine/core";
import { Footer } from "components";
import { CheckoutContextProvider } from "contexts/CheckoutContext";
import { useRetailerQuery, useSessionQuery, useVisualViewportHeight } from "hooks";
import { usePageViewTracking } from "hooks/analytics";
import React from "react";
import { useParams } from "react-router-dom";
import { Checkout, ExpiredCheckout } from "./components";

export const CheckoutPage: React.FC = () => {
  usePageViewTracking("checkout");

  const { sessionId } = useParams<"sessionId">();
  const visualViewportHeight = useVisualViewportHeight();
  const { session, expiredSession } = useSessionQuery(sessionId);
  const { retailer } = useRetailerQuery(session?.retailer_id);

  if (expiredSession)
    return (
      <Stack spacing="xl" justify="space-between" mih={visualViewportHeight}>
        <Center py="xl" px="sm">
          <ExpiredCheckout session={expiredSession} />
        </Center>
        <Footer rentalConditionsUrl={expiredSession.rental_conditions_url} />
      </Stack>
    );

  if (!session || !retailer)
    return (
      <Center h={visualViewportHeight}>
        <Loader />
      </Center>
    );

  return (
    <CheckoutContextProvider session={session} retailer={retailer}>
      <Box h={visualViewportHeight}>
        <Checkout />
      </Box>
    </CheckoutContextProvider>
  );
};
