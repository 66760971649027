import { Box, BoxProps, Text } from "@mantine/core";
import { useDidUpdate, useSetState } from "@mantine/hooks";
import { toDateString } from "helpers/date";
import React, { useCallback, useRef, useState } from "react";
import { useStyles } from "./DateStringInput.styles";
import { extractDateInputValues } from "./helpers";
import { areInputValuesEmpty } from "./helpers/areInputValuesEmpty";
import { areInputValuesFilled } from "./helpers/areInputValuesFilled";
import { DateInputValues } from "./types";

type Props = Omit<BoxProps, "className" | "color"> & {
  color: string;
  value: string | null;
  onChange: (value: string | null) => void;
  label?: React.ReactNode;
  required?: boolean;
  error?: React.ReactNode;
  maxDate?: Date;
  minDate?: Date;
  name?: {
    day?: string;
    month?: string;
    year?: string;
  };
  autoComplete?: {
    day?: string;
    month?: string;
    year?: string;
  };
};

export const DateStringInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      color,
      value,
      onChange,
      error,
      label,
      required,
      maxDate,
      minDate,
      name,
      autoComplete,
      ...props
    },
    ref
  ) => {
    const [focused, setFocused] = useState(false);

    const internalValue = useRef(value);
    const validatedInputValues = useRef(extractDateInputValues(value));
    const [inputValues, _setInputValues] = useSetState(extractDateInputValues(value));

    const dayInputRef = useRef<HTMLInputElement>(null);
    const monthInputRef = useRef<HTMLInputElement>(null);
    const yearInputRef = useRef<HTMLInputElement>(null);

    const setInputValues = useCallback(
      (partialInputValues: Partial<DateInputValues>) => {
        const newInputValues = { ...inputValues, ...partialInputValues };
        _setInputValues(newInputValues);

        const inputDate = new Date();

        const day = Number(newInputValues.dayInput);
        if (day > 0 && day <= 31) {
          inputDate.setDate(day);
        }

        const month = Number(newInputValues.monthInput);
        if (month > 0 && month <= 12) {
          inputDate.setMonth(month - 1);
        }

        const year = Number(newInputValues.yearInput);
        if (year > 0) {
          inputDate.setFullYear(year);
        }

        if (areInputValuesFilled(newInputValues)) {
          const newDate = (() => {
            if (minDate && inputDate < minDate) return minDate;
            if (maxDate && inputDate > maxDate) return maxDate;
            return inputDate;
          })();

          const newValue = toDateString(newDate);
          validatedInputValues.current = extractDateInputValues(newValue);

          internalValue.current = newValue;
          onChange(newValue);
        } else {
          const newValue = areInputValuesEmpty(newInputValues) ? null : toDateString(inputDate);
          validatedInputValues.current = extractDateInputValues(newValue);

          internalValue.current = null;
          onChange(null);
        }
      },
      [_setInputValues, inputValues, maxDate, minDate, onChange]
    );

    const fixInputs = useCallback(() => {
      if (dayInputRef.current?.value) {
        _setInputValues({ dayInput: validatedInputValues.current.dayInput });
      }
      if (monthInputRef.current?.value) {
        _setInputValues({ monthInput: validatedInputValues.current.monthInput });
      }
      if (yearInputRef.current?.value) {
        _setInputValues({ yearInput: validatedInputValues.current.yearInput });
      }
    }, [_setInputValues]);

    useDidUpdate(() => {
      if (internalValue.current !== value) {
        setInputValues(extractDateInputValues(value));
      }
    }, [value]);

    const isInputEmpty = () => areInputValuesEmpty(validatedInputValues.current);

    const { classes } = useStyles({
      color,
      focused,
      empty: isInputEmpty(),
      withLabel: !!label,
      error: !!error,
    });

    return (
      <Box
        className={classes.inputWrapper}
        ref={ref}
        {...props}
        data-value={value}
        data-invalid={error ? "true" : undefined}
      >
        {!!label && (
          <Text size="xs" className={classes.label} component="label">
            {label}
            {required && (
              <Text span color="red">
                {" *"}
              </Text>
            )}
          </Text>
        )}
        <Box className={classes.input}>
          <input
            type="number"
            className={classes.internalInput}
            ref={dayInputRef}
            value={inputValues.dayInput}
            placeholder="JJ"
            style={{ width: 20 }}
            onChange={(event) => {
              const newDayInput = event.target.value.substring(0, 2);
              setInputValues({ dayInput: newDayInput });
              if (newDayInput.length === 2) {
                monthInputRef.current?.focus();
              }
            }}
            onFocus={() => {
              setFocused(true);
            }}
            onBlur={() => {
              setFocused(false);
              fixInputs();
            }}
            data-testid="day-input"
            name={name?.day}
            autoComplete={autoComplete?.day}
            pattern="\d*"
          />

          <Text onClick={() => dayInputRef.current?.focus()} px={2}>
            /
          </Text>

          <input
            type="number"
            className={classes.internalInput}
            ref={monthInputRef}
            value={inputValues.monthInput}
            placeholder="MM"
            style={{ width: 28 }}
            onChange={(event) => {
              const newMonthInput = event.target.value.substring(0, 2);
              setInputValues({ monthInput: newMonthInput });
              if (newMonthInput.length === 2) {
                yearInputRef.current?.focus();
              }
            }}
            onKeyDown={(event) => {
              if (event.key === "Backspace" && inputValues.monthInput === "") {
                dayInputRef.current?.focus();
                event.preventDefault();
              }
            }}
            onFocus={() => {
              if (isInputEmpty()) {
                dayInputRef.current?.focus();
              } else {
                setFocused(true);
              }
            }}
            onBlur={() => {
              setFocused(false);
              fixInputs();
            }}
            data-testid="month-input"
            name={name?.month}
            autoComplete={autoComplete?.month}
            pattern="\d*"
          />

          <Text onClick={() => monthInputRef.current?.focus()} px={2}>
            /
          </Text>

          <input
            type="number"
            className={classes.internalInput}
            ref={yearInputRef}
            value={inputValues.yearInput}
            placeholder="AAAA"
            onChange={(event) => {
              const newYearInput = event.target.value.substring(0, 4);
              setInputValues({ yearInput: newYearInput });
            }}
            onKeyDown={(event) => {
              if (event.key === "Backspace" && inputValues.yearInput === "") {
                monthInputRef.current?.focus();
                event.preventDefault();
              }
            }}
            onFocus={() => {
              if (isInputEmpty()) {
                dayInputRef.current?.focus();
              } else {
                setFocused(true);
              }
            }}
            onBlur={() => {
              setFocused(false);
              fixInputs();
            }}
            data-testid="year-input"
            name={name?.year}
            autoComplete={autoComplete?.year}
            pattern="\d*"
          />
        </Box>
      </Box>
    );
  }
);
DateStringInput.displayName = "DateStringInput";
