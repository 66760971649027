import iban from "iban";
import parseMobilePhoneNumber from "libphonenumber-js/mobile";
import { z } from "zod";

export const dateString = () =>
  z.string().regex(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);

export const dateOfBirthValidator = dateString().refine((dateString) => {
  const date = new Date(dateString);
  if (date >= new Date("1920-01-01")) {
    return true;
  }
  return false;
});

export const mobilePhoneString = () =>
  z
    .string()
    .refine(
      (value) =>
        parseMobilePhoneNumber(value)?.isValid() || parseMobilePhoneNumber(value, "FR")?.isValid()
    );

export const companyRegistrationIdString = () =>
  z.string().refine((value) => {
    const trimmedString = value.replace(/\s+/g, "");
    const isNum = /^\d+$/.test(trimmedString);
    return isNum && trimmedString.length === 14;
  });

export const companyVatIdString = () => z.string().regex(/^(FR)[ ]?[0-9A-Z]{2}[ ]?[0-9]{9}$/);

export const nullableDefault = <Schema extends z.Schema>(
  schema: Schema,
  defaultValue: z.infer<Schema>
) => schema.nullable().transform((value) => (value !== null ? value : defaultValue));

export const enumFrom = <T extends string>(e: { [s: string]: T }) =>
  z.enum(Object.values(e) as [T, ...T[]]);

export const ibanString = () => z.string().refine((value) => iban.isValid(value));
