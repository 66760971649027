import { useCallback, useMemo, useState } from "react";
import { FinancingMode, Session, SessionStepStatus } from "types";
import { CheckoutStep } from "../CheckoutContext";

export const useSteps = (session: Session) => {
  const steps = useMemo(() => {
    const steps = [CheckoutStep.CUSTOMER];

    if (session.financing_mode == FinancingMode.CALF) {
      steps.push(CheckoutStep.ENVELOPPE_FINANCING);
    }

    if (session.steps?.web_scoring) {
      steps.push(CheckoutStep.WEB_SCORING);
    }

    if (session.steps?.open_banking) {
      steps.push(CheckoutStep.OPEN_BANKING);
    }

    if (session.steps?.id_verification) {
      steps.push(CheckoutStep.ID_VERIFICATION);
    }

    steps.push(CheckoutStep.PAYMENT);

    if (
      session.financing_mode == FinancingMode.CALF ||
      (session.financing_mode === FinancingMode.CACF && session["requires_signature?"])
    ) {
      steps.push(CheckoutStep.CONTRACT_SIGNATURE);
    }

    steps.push(CheckoutStep.END);

    return steps;
  }, [session]);

  const [currentStep, setCurrentStep] = useState<CheckoutStep>(() => {
    let minimalCurrentStep = steps[0];

    if (session.financing_enveloppe_request) {
      minimalCurrentStep = CheckoutStep.ENVELOPPE_FINANCING;
    }

    if (
      !!session.steps?.web_scoring &&
      session.steps.web_scoring.status !== SessionStepStatus.NOT_STARTED
    ) {
      minimalCurrentStep = CheckoutStep.WEB_SCORING;
    }

    if (
      !!session.steps?.open_banking &&
      session.steps.open_banking.status !== SessionStepStatus.NOT_STARTED
    ) {
      minimalCurrentStep = CheckoutStep.OPEN_BANKING;
    }

    if (
      !!session.steps?.id_verification &&
      session.steps.id_verification.status !== SessionStepStatus.NOT_STARTED
    ) {
      minimalCurrentStep = CheckoutStep.ID_VERIFICATION;
    }

    return (sessionStorage.getItem(`${session.id}-step`) as CheckoutStep) ?? minimalCurrentStep;
  });

  const completedSteps = useMemo(
    () => steps.slice(0, steps.indexOf(currentStep)),
    [currentStep, steps]
  );

  const nextStep = useCallback(() => {
    const newStep = steps[steps.indexOf(currentStep) + 1];

    setCurrentStep(newStep);
    sessionStorage.setItem(`${session.id}-step`, newStep);

    return newStep;
  }, [currentStep, session.id, steps]);

  return {
    steps,
    currentStep,
    completedSteps,
    nextStep,
  };
};
