import { createStyles } from "@mantine/core";
import { GRAY, MEDIUM_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(({ shadows, spacing, fn: { largerThan } }) => ({
  card: {
    height: 52,
    boxShadow: shadows.xs,
    margin: `0 ${spacing.sm}`,
    borderColor: GRAY,
    cursor: "pointer",

    [largerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
      display: "none",
    },
  },

  sketch: {
    height: 32,
    width: 32,
  },
}));
