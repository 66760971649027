import { Group, Stack, Text } from "@mantine/core";
import { useMutation } from "@tanstack/react-query";
import { Button } from "components";
import { useCheckoutContext } from "contexts/CheckoutContext";
import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as SignatureSketch } from "resources/sketches/signature.svg";
import { ReactComponent as TechnicalSupportSketch } from "resources/sketches/technical-support.svg";
import { requestESignature as _requestESignature } from "services/api/session";
import { ContractSignatureType, CustomerType, FinancingMode } from "types";
import { StepModalBottomBar } from "../StepModalBottomBar";
import { useStyles } from "./CheckoutContractSignature.styles";

export const CheckoutContractSignature: React.FC = () => {
  const {
    session: { id: session_id, session_customer, representative, financing_mode },
    retailer,
    nextStep,
  } = useCheckoutContext();
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { mutate: requestESignature, isLoading: isRequestingESignature } = useMutation({
    mutationFn: () => {
      const signatory = representative ?? session_customer;

      return _requestESignature({
        session_id,
        signatory_first_name: signatory.first_name,
        signatory_last_name: signatory.last_name,
        signatory_email: signatory.email,
        signatory_job_title: representative?.job_title,
        signatory_phone: signatory.phone,
        document_type:
          financing_mode === FinancingMode.CACF
            ? ContractSignatureType.CACF
            : ContractSignatureType.CALF,
        delivery_mode: "email",
      });
    },
    onSuccess: nextStep,
  });

  return (
    <div className={classes.container}>
      <Stack p="sm" className={classes.wrapper}>
        <SignatureSketch className={classes.sketch} />

        <Text>{t(`checkout.contractSignatureDescription.${session_customer.customer_type}`)}</Text>

        {session_customer.customer_type === CustomerType.COMPANY && (
          <Group position="apart" className={classes.infoWrapper}>
            <Text size="sm">{t("checkout.contractSentToRepresentative")}</Text>
            <TechnicalSupportSketch className={classes.infoSketch} />
          </Group>
        )}
      </Stack>

      <StepModalBottomBar>
        <Button
          color={retailer.style.primary_color}
          onClick={() => requestESignature()}
          loading={isRequestingESignature}
        >
          {t("checkout.receiveMyContract")}
        </Button>
      </StepModalBottomBar>
    </div>
  );
};
