import { Box, BoxProps } from "@mantine/core";
import { TablerIconsProps } from "@tabler/icons-react";
import React from "react";
import { useStyles } from "./CircledIcon.styles";

type Props = import("@mantine/utils").PolymorphicComponentProps<"div", BoxProps> & {
  icon: (props: TablerIconsProps) => JSX.Element;
  color?: string;
  backgroundColor?: string;
  withBorder?: boolean;
  size?: string | number;
};

export const CircledIcon: React.FC<Props> = ({
  icon: Icon,
  color,
  backgroundColor,
  withBorder,
  size,
  className,
  ...props
}) => {
  const { classes } = useStyles({ color, backgroundColor, withBorder, size });

  return (
    <Box className={classes.container + (className ? " " + className : "")} {...props}>
      <Icon className={classes.icon} />
    </Box>
  );
};
