import { SetupIntent } from "@stripe/stripe-js";
import { useQuery } from "@tanstack/react-query";
import { useCheckoutContext } from "contexts/CheckoutContext";
import { queryClient } from "services/api";

const REFETCH_SETUP_INTENT_STATUSES: SetupIntent.Status[] = [
  "processing",
  "requires_action",
  "requires_confirmation",
];
const REFETCH_INTERVAL = 1000;

export const useStripeSetupIntentQuery = (clientSecret: string | undefined) => {
  const { stripe } = useCheckoutContext();

  const { data: setupIntent, ...queryResult } = useQuery({
    queryKey: ["stripe-setup-intent", clientSecret],
    queryFn: async () => {
      if (!stripe) throw Error("Stripe instance cannot be null.");
      if (!clientSecret) throw Error("Client secret cannot be undefined.");

      return (await stripe.retrieveSetupIntent(clientSecret)).setupIntent;
    },
    retry: false,
    retryOnMount: false,
    staleTime: Infinity,
    enabled: !!stripe && !!clientSecret,
    refetchInterval: (setupIntent) => {
      if (!!setupIntent && REFETCH_SETUP_INTENT_STATUSES.includes(setupIntent.status))
        return REFETCH_INTERVAL;
      return false;
    },
  });

  return {
    setupIntent,
    ...queryResult,
  };
};

export const updateStripeSetupIntentQuery = (clientSecret: string, setupIntent: SetupIntent) =>
  queryClient.setQueryData<SetupIntent>(["stripe-setup-intent", clientSecret], setupIntent);
