import { Divider, Group, Stack, Text } from "@mantine/core";
import { useCheckoutContext } from "contexts/CheckoutContext";
import { formatPrice, sumPrices } from "helpers/price";
import { sum } from "lodash";
import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as TechnicalSupportSketch } from "resources/sketches/technical-support.svg";
import { GRAY } from "theme";
import { CustomerType } from "types";
import { useStyles } from "./CheckoutOrderPurchasedItems.styles";

export const CheckoutOrderPurchasedItems: React.FC = () => {
  const {
    order,
    retailer,
    session: {
      session_customer: { customer_type },
    },
  } = useCheckoutContext();
  const { classes } = useStyles({ color: retailer.style.primary_color });
  const { t } = useTranslation();

  const totalFirstInstalmentPriceIncludingVAT = useMemo(
    () =>
      sumPrices(
        order.selectedDuration.purchasedItems.map(
          (purchasedItem) => purchasedItem.firstInstalmentPriceIncludingVAT
        )
      ),
    [order.selectedDuration.purchasedItems]
  );

  if (order.selectedDuration.purchasedItems.length === 0) return null;

  return (
    <Stack spacing="sm">
      <Divider
        label={
          <Trans
            i18nKey="checkout.purchasedItemsCount"
            values={{
              count: sum(
                order.selectedDuration.purchasedItems.map((purchasedItem) => purchasedItem.quantity)
              ),
              price: formatPrice(totalFirstInstalmentPriceIncludingVAT),
            }}
          />
        }
        labelPosition="center"
        classNames={{ label: classes.dividerLabel }}
      />

      <Group position="apart" className={classes.infoWrapper}>
        <Text size="sm">{t("checkout.purchasedItemsDescription")}</Text>
        <TechnicalSupportSketch className={classes.infoSketch} />
      </Group>

      {order.selectedDuration.purchasedItems.map((purchasedItem) => (
        <Group key={purchasedItem.id} position="apart">
          <Text size="sm">
            <Trans
              i18nKey="checkout.itemNameWithQuantity"
              values={{ name: purchasedItem.name, count: purchasedItem.quantity }}
              components={{ b: <b /> }}
            />
          </Text>

          <Group spacing="xs" className={classes.instalmentWrapper}>
            <Text size="sm" fw={700}>
              {formatPrice(purchasedItem.firstInstalmentPriceIncludingVAT)}
            </Text>

            {customer_type === CustomerType.COMPANY && (
              <Text size="sm" c={GRAY}>
                {t("checkout.priceWithoutVAT", {
                  price: formatPrice(purchasedItem.firstInstalmentPrice),
                })}
              </Text>
            )}
          </Group>
        </Group>
      ))}
    </Stack>
  );
};
