export enum InstalmentPayment {
  ALL = "all",
  FIRST = "first",
  OTHER = "other",
}

export interface CustomConfirmPaymentData {
  payment_method?: string;
  return_url: string;
}
