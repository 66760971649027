import { List, Stack, Text } from "@mantine/core";
import { IconBuildingBank, IconLock, IconShieldLock } from "@tabler/icons-react";
import { useCheckoutContext } from "contexts/CheckoutContext";
import React from "react";
import { useTranslation } from "react-i18next";

export const CheckoutOpenBankingDetails: React.FC = () => {
  const { retailer } = useCheckoutContext();
  const { t } = useTranslation();

  return (
    <Stack p="sm">
      <Text>{t("checkout.openBankingDescription")}</Text>

      <List center spacing="md" styles={{ itemIcon: { color: retailer.style.primary_color } }}>
        <List.Item icon={<IconShieldLock />}>
          {t("checkout.openBankingDetailPoints.protection")}
        </List.Item>

        <List.Item icon={<IconLock />}>{t("checkout.openBankingDetailPoints.security")}</List.Item>

        <List.Item icon={<IconBuildingBank />}>
          {t("checkout.openBankingDetailPoints.bank")}
        </List.Item>
      </List>
    </Stack>
  );
};
