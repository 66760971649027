import { retailerCoverageConfigurationSchema, retailerSchema } from "schemas";
import { z } from "zod";

export enum IncidentType {
  BREAKAGE = "breakage",
  BREAKDOWN = "breakdown",
  LOSS = "loss",
  THEFT = "theft",
}

export enum CoverageOwner {
  MERCHANT = "merchant",
  INSURER = "insurer",
  CUSTOMER = "customer",
}

export type RetailerCoverageConfiguration = z.infer<typeof retailerCoverageConfigurationSchema>;

export type Retailer = z.infer<typeof retailerSchema>;
