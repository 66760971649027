import { createStyles } from "@mantine/core";
import { MEDIUM_SCREEN_BREAKPOINT, SMALL_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(
  ({ white, fn: { smallerThan, largerThan }, shadows, spacing }) => ({
    container: {
      backgroundColor: white,
      zIndex: 10,
      paddingTop: spacing.xs,
      padding: spacing.sm,
      display: "flex",
      flexDirection: "column",
      rowGap: spacing.sm,

      [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
        paddingTop: spacing.sm,
        position: "sticky",
        zIndex: 10,
        bottom: 0,
        boxShadow: shadows.lg,
      },

      [largerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
        paddingTop: spacing.sm,
        margin: `0 -${spacing.sm}`,
        position: "sticky",
        boxShadow: shadows.lg,
        bottom: `-${spacing.md}`,
        alignItems: "center",

        button: {
          minWidth: "50%",
        },
      },
    },
  })
);
