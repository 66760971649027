import { apiClient } from "..";

interface CallbackParams {
  customer_reference_id: string;
  transaction_report_id: string;
  account_verification_report_id: string;
}

export const callbackOpenBankingProvider = (params: CallbackParams) =>
  apiClient.post("/meelo_callback", params, {
    headers: { Authorization: `Bearer ${import.meta.env.VITE_ADMIN_PUBLIC_API_KEY}` },
  });
