import { Session } from "types";
import { apiClient } from "../client";

export const updateSessionSelectedDuration = async ({
  id: sessionId,
  selected_duration,
}: Pick<Session, "id" | "selected_duration">) =>
  apiClient.patch<Session>(`sessions/${sessionId}`, {
    session: { selected_duration },
  });
