import { createStyles } from "@mantine/core";
import { LIGHT_GREEN } from "theme";

export const useStyles = createStyles(({ spacing, radius }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },

  wrapper: {
    flex: 1,
  },

  sketch: {
    alignSelf: "center",
  },

  infoWrapper: {
    backgroundColor: LIGHT_GREEN,
    borderRadius: radius.md,
    padding: `${spacing.xs} ${spacing.sm}`,
  },

  infoSketch: {
    flexShrink: 0,
  },
}));
