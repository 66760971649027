import { Divider, Group, Image, Stack, Text } from "@mantine/core";
import { useCheckoutContext } from "contexts/CheckoutContext";
import { formatPrice } from "helpers/price";
import { sum } from "lodash";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { GRAY } from "theme";
import { CustomerType } from "types";
import { useStyles } from "./CheckoutOrderRentedItems.styles";

export const CheckoutOrderRentedItems: React.FC = () => {
  const {
    order,
    retailer,
    session: {
      session_customer: { customer_type },
    },
  } = useCheckoutContext();
  const { t } = useTranslation();
  const { classes } = useStyles({ color: retailer.style.primary_color });

  return (
    <Stack spacing="sm">
      <Divider
        label={t("checkout.rentedItemsCount", {
          count: sum(order.selectedDuration.rentedItems.map((rentedItem) => rentedItem.quantity)),
        })}
        labelPosition="center"
        classNames={{ label: classes.dividerLabel }}
      />

      {order.selectedDuration.rentedItems.map((rentedItem) => (
        <Stack key={rentedItem.id} spacing="xs">
          <Group spacing="sm">
            {!!rentedItem.image && (
              <Image
                src={rentedItem.image}
                height={60}
                width={60}
                fit="contain"
                className={classes.image}
              />
            )}

            <Text size="sm">
              <Trans
                i18nKey="checkout.itemNameWithQuantity"
                values={{ name: rentedItem.name, count: rentedItem.quantity }}
                components={{ b: <b /> }}
              />
            </Text>
          </Group>

          <div className={classes.instalmentWrapper} data-customer-type={customer_type}>
            <div className={classes.instalment}>
              {customer_type === CustomerType.COMPANY && (
                <Text c={GRAY} size="sm">
                  {t("checkout.priceWithoutVAT", {
                    price: formatPrice(rentedItem.firstInstalmentPrice),
                  })}
                </Text>
              )}

              <Text fw={700} size="sm">
                {formatPrice(rentedItem.firstInstalmentPriceIncludingVAT)}
              </Text>

              <Text tt="uppercase" size="0.6rem" fw={700}>
                {t("checkout.firstInstalment")}
              </Text>
            </div>

            <div className={classes.instalment}>
              {customer_type === CustomerType.COMPANY && (
                <Text c={GRAY} size="sm">
                  {t("checkout.priceWithoutVAT", {
                    price: formatPrice(rentedItem.otherInstalmentPrice),
                  })}
                </Text>
              )}

              <Text fw={700} size="sm">
                {formatPrice(rentedItem.otherInstalmentPriceIncludingVAT)}
              </Text>

              <Text tt="uppercase" size="0.6rem" fw={700}>
                {t("checkout.otherInstalment")}
              </Text>
            </div>
          </div>
        </Stack>
      ))}
    </Stack>
  );
};
