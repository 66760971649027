import { CoverageOwner, FinancingMode, IncidentType, PaymentMethodType } from "types";
import { z } from "zod";
import { enumFrom } from "./utils";

export const retailerCoverageSchema = z.object({
  coverage_type: enumFrom(IncidentType),
  finance_owner: enumFrom(CoverageOwner),
  operation_owner: enumFrom(CoverageOwner),
});

export const retailerCoverageConfigurationSchema = z.object({
  code: z.string(),
  coverages: z.array(retailerCoverageSchema),
});

export const retailerDurationConfigurationSchema = z.object({
  duration: z.number(),
  label: z.string().optional().nullable(),
});

export const retailerScoringConfigurationSchema = z.object({
  financing_mode: enumFrom(FinancingMode),
  b2c_web_scoring: z.boolean(),
  b2c_min_web_scoring: z.number().nullable(),
  b2c_open_banking_scoring: z.boolean(),
  b2c_min_open_banking_scoring: z.number().nullable(),
  b2b_web_scoring: z.boolean(),
  b2b_min_web_scoring: z.number().nullable(),
  b2b_open_banking_scoring: z.boolean(),
  b2b_min_open_banking_scoring: z.number().nullable(),
});

export const retailerPaymentConfigurationSchema = z.object({
  payment_processor_public_api_key: z.string(),
  first_payment_method: enumFrom(PaymentMethodType),
  other_payment_method: enumFrom(PaymentMethodType),
});

export const retailerUrlsConfigSchema = z.object({
  reject_return_url: z.string().nullable(),
  payment_failed_url: z.string().nullable(),
  pending_validation_url: z.string().nullable().optional(),
  success_return_url: z.string().nullable(),
  rental_conditions_url: z.string().nullable(),
  shop_url: z.string(),
});

export const retailerStyleSchema = z.object({
  primary_color: z.string(),
  logo_url: z.string(),
  default_open_cart: z.boolean(),
  display_logo_on_mobile: z.boolean().optional().default(false),
});

export const retailerSchema = z.object({
  id: z.string(),
  name: z.string(),
  duration_configurations: z.array(retailerDurationConfigurationSchema),
  coverage_configurations: z.array(retailerCoverageConfigurationSchema),
  scoring_configuration: retailerScoringConfigurationSchema,
  payment_configuration: retailerPaymentConfigurationSchema,
  urls_config: retailerUrlsConfigSchema,
  style: retailerStyleSchema,
});
