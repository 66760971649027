import { Box, Stack, Text } from "@mantine/core";
import { Footer } from "components";
import { useVisualViewportHeight } from "hooks";
import { usePageViewTracking } from "hooks/analytics";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as SlineLogo } from "resources/icons/sline-logo.svg";
import { callbackOpenBankingProvider } from "services/api/openBanking";
import { useStyles } from "./OpenBankingStatusPage.styles";

export enum OpenBankingStatus {
  AUTHENTICATION_FAILED = "authentication-failed",
  ERROR = "error",
  SUCCESS = "success",
}

interface Props {
  openBankingStatus: OpenBankingStatus;
}

export const OpenBankingStatusPage: React.FC<Props> = ({ openBankingStatus }) => {
  usePageViewTracking(`open-banking-${openBankingStatus}`);

  const { t } = useTranslation();
  const visualViewportHeight = useVisualViewportHeight();
  const { classes } = useStyles();

  useEffect(() => {
    if (openBankingStatus === OpenBankingStatus.SUCCESS) {
      const urlParams = new URLSearchParams(window.location.search);

      const customer_reference_id = urlParams.get("customer_reference_id");
      if (!customer_reference_id) {
        console.warn("Url param customer_reference_id is missing.");
        return;
      }

      const transaction_report_id = urlParams.get("transaction_report_id");
      if (!transaction_report_id) {
        console.warn("Url param transaction_report_id is missing.");
        return;
      }

      const account_verification_report_id = urlParams.get("account_verification_report_id");
      if (!account_verification_report_id) {
        console.warn("Url param account_verification_report_id is missing.");
        return;
      }

      void callbackOpenBankingProvider({
        customer_reference_id,
        transaction_report_id,
        account_verification_report_id,
      });
    }
  }, [openBankingStatus]);

  return (
    <Stack spacing="xl" justify="space-between" mih={visualViewportHeight}>
      <Stack py="xl" px="sm" spacing="xl" sx={{ flex: 1 }}>
        <SlineLogo className={classes.logo} />
        <Box className={classes.container}>
          <Box className={classes.box}>
            <Text weight={700} className={classes.text}>
              {t(`openBankingStatus.${openBankingStatus}.message1`)}
            </Text>
            <Text className={classes.text}>
              {t(`openBankingStatus.${openBankingStatus}.message2`)}
            </Text>
          </Box>
        </Box>
      </Stack>
      <Footer />
    </Stack>
  );
};
