import { buildCheckoutOrder } from "helpers";
import React, { PropsWithChildren, useMemo, useState } from "react";
import { RentalStatus } from "types";
import { _CheckoutContextProvider, CheckoutContextValue } from "./CheckoutContext";
import {
  useEnveloppeFinancing,
  useIdentityVerification,
  useOpenBanking,
  usePayment,
  useRejectedSessionRedirection,
  useSelectedDuration,
  useSteps,
  useStripe,
  useWebScoring,
} from "./hooks";

type Props = PropsWithChildren & Pick<CheckoutContextValue, "session" | "retailer">;

export const MAX_WEB_SCORE = 100;
export const MIN_WEB_SCORE = 0;
export const INITIAL_FAKE_WEB_SCORE = MAX_WEB_SCORE;

export const CheckoutContextProvider: React.FC<Props> = ({ children, session, retailer }) => {
  const { steps, currentStep, completedSteps, nextStep } = useSteps(session);

  const [selectedDuration, setSelectedDuration] = useSelectedDuration(session, retailer);

  const order = useMemo(
    () => buildCheckoutOrder(session, retailer, selectedDuration),
    [retailer, selectedDuration, session]
  );

  const stripe = useStripe(retailer);

  useEnveloppeFinancing(session, retailer, currentStep, nextStep);
  useWebScoring(session, currentStep, nextStep);
  const { requestOpenBanking, isRequestingOpenBanking } = useOpenBanking(
    session,
    currentStep,
    nextStep
  );

  const {
    startIdentityVerification,
    isStartingIdentityVerification,
    identityVerificationError,
    resetIdentityVerificationError,
  } = useIdentityVerification(session, stripe, currentStep, nextStep);

  const [rentalStatus, setRentalStatus] = useState<RentalStatus>();

  const { firstPaymentClientSecret, otherPaymentClientSecret } = usePayment(
    session,
    retailer,
    steps,
    currentStep,
    setRentalStatus
  );

  useRejectedSessionRedirection(session, retailer);

  return (
    <_CheckoutContextProvider
      value={{
        session,
        retailer,
        steps,
        currentStep,
        completedSteps,
        nextStep,
        selectedDuration,
        setSelectedDuration,
        order,
        stripe,
        requestOpenBanking,
        isRequestingOpenBanking,
        startIdentityVerification,
        isStartingIdentityVerification,
        identityVerificationError,
        resetIdentityVerificationError,
        firstPaymentClientSecret,
        otherPaymentClientSecret,
        rentalStatus,
      }}
    >
      {children}
    </_CheckoutContextProvider>
  );
};
