import { Box } from "@mantine/core";
import { Button } from "components";
import { IdentityVerificationError, useCheckoutContext } from "contexts/CheckoutContext";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SessionStepStatus } from "types";
import { StepModalBottomBar } from "../StepModalBottomBar";
import { useStyles } from "./CheckoutIdentityVerification.styles";
import { CheckoutIdentityVerificationError } from "./CheckoutIdentityVerificationError";
import { CheckoutIdentityVerificationInfo } from "./Info/CheckoutIdentityVerificationInfo";

export const CheckoutIdentityVerification: React.FC = () => {
  const {
    session,
    retailer,
    startIdentityVerification,
    isStartingIdentityVerification,
    identityVerificationError,
    resetIdentityVerificationError,
  } = useCheckoutContext();
  const { t } = useTranslation();
  const { classes } = useStyles();

  const isVerifyingIdentity = useMemo(
    () => session.steps?.id_verification?.status === SessionStepStatus.PENDING,
    [session.steps?.id_verification?.status]
  );

  return (
    <div className={classes.container}>
      <Box className={classes.wrapper}>
        {identityVerificationError ? (
          <CheckoutIdentityVerificationError error={identityVerificationError} />
        ) : (
          <CheckoutIdentityVerificationInfo />
        )}
      </Box>

      <StepModalBottomBar>
        <Button
          color={retailer.style.primary_color}
          onClick={
            identityVerificationError === IdentityVerificationError.NON_COMPLIANT
              ? resetIdentityVerificationError
              : startIdentityVerification
          }
          loading={isStartingIdentityVerification || isVerifyingIdentity}
        >
          {identityVerificationError === IdentityVerificationError.NON_COMPLIANT
            ? t("checkout.checkComplianceList")
            : t("checkout.verifyMyIdDocument")}
        </Button>
      </StepModalBottomBar>
    </div>
  );
};
