import { createStyles } from "@mantine/core";
import { LARGE_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(({ spacing, shadows, fontSizes, fn: { largerThan } }) => ({
  group: {
    gap: spacing.xs,

    [largerThan(LARGE_SCREEN_BREAKPOINT)]: {
      gap: spacing.md,
    },
  },
  item: {
    position: "relative",
    backgroundColor: "white",
    borderRadius: "60px",
    maxHeight: "120px",
  },

  itemShadowed: {
    boxShadow: shadows.xl,
  },

  // Ensure perfect round shape
  // TODO: display issue in medium screen modal
  itemSpacer: {
    paddingTop: "100%",
  },

  itemContent: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    gap: 0,
    padding: spacing.sm,
    alignItems: "center",
    justifyContent: "center",

    [largerThan(LARGE_SCREEN_BREAKPOINT)]: {
      gap: spacing.xs,
    },
  },
  itemTitle: {
    fontSize: fontSizes.md,
    [largerThan(LARGE_SCREEN_BREAKPOINT)]: {
      fontSize: fontSizes.lg,
    },
  },
}));
