import { MantineThemeOverride, NotificationStylesParams, getStylesRef } from "@mantine/core";
import { VISUAL_VIEWPORT_HEIGHT_CSS_VARIABLE_NAME } from "hooks";
import { SMALL_SCREEN_BREAKPOINT } from "./breakpoints";

export const mantineTheme: MantineThemeOverride = {
  black: "#252A31",
  primaryColor: "dark",
  fontFamily: '"Manrope", "Roboto", "Helvetica", "Arial",sans-serif',
  lineHeight: 1.5,
  spacing: {
    xs: "0.5rem",
    sm: "1rem",
    md: "1.5rem",
    lg: "2rem",
    xl: "3rem",
  },
  shadows: {
    xs: "0px 1px 1px rgba(0, 0, 0, 0.15)",
    sm: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
    md: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    lg: "0px 1px 20px 0px rgba(0, 0, 0, 0.06), 0px 2px 2px 0px rgba(0, 0, 0, 0.25)",
    xl: "0px 4px 20px 0px rgba(0, 0, 0, 0.10), 0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.25rem",
    xl: "1.5rem",
  },
  headings: {
    sizes: {
      h1: { fontSize: "3.812rem", lineHeight: "62px", fontWeight: 700 },
      h2: { fontSize: "3.062rem", lineHeight: "54px", fontWeight: 700 },
      h3: { fontSize: "2.438rem", lineHeight: "42px", fontWeight: 700 },
      h4: { fontSize: "1.938rem", lineHeight: "40px", fontWeight: 700 },
      h5: { fontSize: "1.562rem", lineHeight: "30px", fontWeight: 700 },
      h6: { fontSize: "1.250rem", lineHeight: "24px", fontWeight: 700 },
    },
  },
  globalStyles: () => ({
    a: {
      all: "unset",
    },
    input: {
      "&:disabled": {
        opacity: "1 !important",
      },
    },
    textarea: {
      "&:disabled": {
        opacity: "1 !important",
      },
    },
  }),
  components: {
    Loader: {
      defaultProps: {
        sx: { "g > g": { strokeWidth: 3 } },
      },
    },
    Group: {
      defaultProps: {
        noWrap: true,
      },
    },
    TextInput: {
      defaultProps: {
        size: "md",
      },
    },
    Popover: {
      defaultProps: {
        withinPortal: true,
      },
    },
    Autocomplete: {
      defaultProps: {
        size: "md",
      },
    },
    DateInput: {
      defaultProps: {
        size: "md",
      },
    },
    Modal: {
      defaultProps: {
        centered: true,
        radius: "lg",
        transitionProps: { transition: "slide-up" },
        overlayProps: { opacity: 0.4 },
      },
      styles: ({ fontSizes, spacing, colors, fn: { smallerThan } }) => ({
        title: {
          fontSize: fontSizes.md,
          fontWeight: 700,
        },
        header: {
          padding: `${spacing.sm} 0`,
          margin: `0 ${spacing.sm}`,
          borderBottomStyle: "solid",
          borderBottomColor: colors.gray[3],
          borderWidth: 1,
        },
        body: {
          padding: `${spacing.sm} !important`,
        },
        inner: {
          height: `var(${VISUAL_VIEWPORT_HEIGHT_CSS_VARIABLE_NAME})`,
          [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
            padding: 0,
            alignItems: "flex-end",
          },
        },
        content: {
          [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
            height: "auto",
            maxHeight: "100%",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            flex: 1,
          },
        },
        close: {
          ":focus": {
            outline: "none",
          },
        },
      }),
    },
    Tooltip: {
      defaultProps: {
        position: "top",
        withArrow: true,
        multiline: true,
        width: 200,
        events: { hover: true, focus: false, touch: true },
      },
      styles: {
        tooltip: {
          fontWeight: 600,
          whiteSpace: "pre-wrap",
        },
      },
    },
    LoadingOverlay: {
      styles: {
        root: {
          ref: getStylesRef("loading-overlay-root"),
        },
      },
    },
    List: {
      styles: {
        item: {
          lineHeight: 1.5,
        },
      },
    },
    Notification: {
      styles: (
        { shadows, colors, primaryColor },
        { color = primaryColor }: NotificationStylesParams
      ) => ({
        root: {
          boxShadow: shadows.xl,
        },
        title: {
          fontWeight: 700,
          color: colors[color][7],
        },
        description: {
          color: colors.dark[7],
        },
      }),
    },
  },
};
