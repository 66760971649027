import { Anchor, Group, Stack, Text } from "@mantine/core";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { SetupIntent } from "@stripe/stripe-js";
import { useMutation } from "@tanstack/react-query";
import { Button, Checkbox, TextInput } from "components";
import { useCheckoutContext } from "contexts/CheckoutContext";
import { formatPrice } from "helpers/price";
import { updateStripeSetupIntentQuery, useForm } from "hooks";
import React, { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as BankSketch } from "resources/sketches/bank.svg";
import { ReactComponent as CreditCardSketch } from "resources/sketches/credit-card.svg";
import { ReactComponent as TechnicalSupportSketch } from "resources/sketches/technical-support.svg";
import { createSessionPaymentInfo } from "services/api/session";
import { PaymentMethodType } from "types";
import { StepModalBottomBar } from "../../StepModalBottomBar";
import { cardSchema, sepaSchema } from "../schemas";
import { CustomConfirmPaymentData, InstalmentPayment } from "../types";
import { useStyles } from "./CheckoutPaymentForm.styles";

interface Props {
  instalmentPayment: InstalmentPayment;
  setInstalmentPayment: (instalmentPayment: InstalmentPayment) => void;
  paymentClientSecret: string;
  setupIntent: SetupIntent;
}

export const CheckoutPaymentForm: React.FC<Props> = ({
  instalmentPayment,
  setInstalmentPayment,
  paymentClientSecret,
  setupIntent,
}) => {
  const {
    session: {
      id: session_id,
      session_customer: { first_name, last_name, phone, email },
      billing_address,
      iban: sessionIBAN,
    },
    order: {
      selectedDuration: {
        totalFirstInstalmentPriceIncludingVAT,
        totalOtherInstalmentPriceIncludingVAT,
      },
    },
    retailer,
    nextStep,
  } = useCheckoutContext();
  const [conditionsAgreed, setConditionsAgreed] = useState(false);
  const { classes } = useStyles();
  const { t } = useTranslation();

  const paymentMethodType = useMemo(
    () =>
      instalmentPayment === InstalmentPayment.OTHER
        ? retailer.payment_configuration.other_payment_method
        : retailer.payment_configuration.first_payment_method,
    [
      instalmentPayment,
      retailer.payment_configuration.first_payment_method,
      retailer.payment_configuration.other_payment_method,
    ]
  );

  const { getInputProps, onSubmit } = useForm({
    schema: paymentMethodType === PaymentMethodType.SEPA_DEBIT ? sepaSchema : cardSchema,
    initialValues: {
      iban: sessionIBAN ?? "",
    },
  });

  const stripe = useStripe();
  const elements = useElements();

  const { mutate: submitPaymentMethod, isLoading: isSubmittingPaymentMethod } = useMutation({
    mutationFn: async (formIBAN: string) => {
      if (!stripe || !elements) return;

      const billing_details = {
        name: first_name + " " + last_name,
        phone,
        email,
        address: {
          line1: billing_address.street_address,
          line2: billing_address.street_address_2,
          city: billing_address.city,
          state: "",
          postal_code: billing_address.zip_code,
          country: billing_address.country,
        },
      };

      if (paymentMethodType === PaymentMethodType.SEPA_DEBIT) {
        const iban = sessionIBAN ?? formIBAN;

        const result = await stripe.createPaymentMethod({
          type: "sepa_debit",
          sepa_debit: {
            iban,
          },
          billing_details,
        });

        if (!result.paymentMethod) throw Error("Payment method should be defined.");

        await createSessionPaymentInfo({
          session_id,
          payment_method_id: result.paymentMethod.id,
          iban,
          payment_method: instalmentPayment == InstalmentPayment.OTHER ? "other" : "first",
        });

        return stripe.confirmSetup({
          clientSecret: paymentClientSecret,
          confirmParams: {
            payment_method: result.paymentMethod.id,
            return_url: window.location.href,
          } as CustomConfirmPaymentData,
          redirect: "if_required",
        });
      } else {
        return stripe.confirmSetup({
          elements,
          confirmParams: {
            payment_method_data: {
              billing_details,
            },
            return_url: window.location.href,
          },
          redirect: "if_required",
        });
      }
    },
    onSuccess: (result) => {
      if (!result?.setupIntent) {
        console.error(result?.error?.message);
      } else {
        updateStripeSetupIntentQuery(paymentClientSecret, result.setupIntent);
      }
    },
  });

  const isProcessingPaymentMethod = useMemo(
    () => setupIntent.status !== "requires_payment_method",
    [setupIntent.status]
  );

  useEffect(() => {
    if (setupIntent.status === "succeeded") {
      if (instalmentPayment === InstalmentPayment.FIRST) {
        setInstalmentPayment(InstalmentPayment.OTHER);
      } else {
        nextStep();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setupIntent.status]);

  return (
    <form
      className={classes.container}
      onSubmit={onSubmit(({ iban }) => {
        submitPaymentMethod(iban);
      })}
    >
      <Stack className={classes.contentWrapper}>
        {paymentMethodType === "card" ? (
          <CreditCardSketch className={classes.sketch} />
        ) : (
          <BankSketch className={classes.sketch} />
        )}

        <Text>
          <Trans
            i18nKey={`checkout.instalmentPaymentDescriptions.${instalmentPayment}.${paymentMethodType}`}
            values={{
              firstInstalmentPrice: formatPrice(totalFirstInstalmentPriceIncludingVAT),
              otherInstalmentPrice: formatPrice(totalOtherInstalmentPriceIncludingVAT),
            }}
            components={{ b: <b /> }}
          />
        </Text>

        {paymentMethodType === PaymentMethodType.SEPA_DEBIT ? (
          <>
            <TextInput
              color={retailer.style.primary_color}
              label={t("checkout.iban")}
              disabled={sessionIBAN !== null}
              {...getInputProps("iban")}
            />

            {sessionIBAN !== null && (
              <Group position="apart" className={classes.infoWrapper}>
                <Text size="sm">{t("checkout.transmittedIbanWithOpenBanking")}</Text>
                <TechnicalSupportSketch className={classes.infoSketch} />
              </Group>
            )}
          </>
        ) : (
          <PaymentElement options={{ fields: { billingDetails: "never" } }} />
        )}

        {instalmentPayment != InstalmentPayment.OTHER && (
          <Checkbox
            color={retailer.style.primary_color}
            checked={conditionsAgreed}
            onChange={(event) => setConditionsAgreed(event.target.checked)}
            label={
              <Text size="sm">
                <Trans
                  i18nKey="checkout.acceptGeneralConditionsOfRental"
                  components={{
                    a: (
                      <Anchor
                        fw={700}
                        td="underline"
                        href={String(retailer.urls_config.rental_conditions_url)}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(event) => event.stopPropagation()}
                      />
                    ),
                  }}
                />
              </Text>
            }
          />
        )}
      </Stack>

      <StepModalBottomBar>
        <Button
          color={retailer.style.primary_color}
          type="submit"
          disabled={instalmentPayment != InstalmentPayment.OTHER && !conditionsAgreed}
          loading={isSubmittingPaymentMethod || isProcessingPaymentMethod}
        >
          {instalmentPayment === InstalmentPayment.OTHER
            ? t("checkout.gotIt")
            : t("checkout.pay", { price: formatPrice(totalFirstInstalmentPriceIncludingVAT) })}
        </Button>
      </StepModalBottomBar>
    </form>
  );
};
