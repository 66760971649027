import { useInterval } from "@mantine/hooks";
import dayjs, { Dayjs } from "dayjs";
import { refetchSessionQuery } from "hooks";
import { includes } from "lodash";
import { useEffect, useRef } from "react";
import { createFinancingEnveloppeRequest } from "services/api/session";
import { CalfStatus, Financier, FinancingMode, Retailer, RoutePath, Session } from "types";
import { CheckoutStep } from "../../CheckoutContext";
import { useRedirect } from "../useRedirect";

const REFETCH_INTERVAL = 1000;
export const MINIMUM_ENVELOPPE_FINANCING_WAITING_DURATION = 2000;

export const FINANCIER_BY_FINANCING_MODE: Record<
  Exclude<FinancingMode, FinancingMode.AUTO>,
  Financier
> = {
  [FinancingMode.CACF]: Financier.CACF,
  [FinancingMode.CALF]: Financier.CALF,
};

export const useEnveloppeFinancing = (
  session: Session,
  retailer: Retailer,
  currentStep: CheckoutStep,
  nextStep: () => CheckoutStep
) => {
  const { start: startRefetching, stop: stopRefetching } = useInterval(
    () => refetchSessionQuery(session.id),
    REFETCH_INTERVAL
  );
  const redirect = useRedirect(session, retailer);

  const enveloppeFinancingCreatedDate = useRef<Dayjs | null>(null);

  useEffect(() => {
    if (currentStep !== CheckoutStep.ENVELOPPE_FINANCING) return;

    if (!session.financing_enveloppe_request) {
      if (enveloppeFinancingCreatedDate.current) return;

      if (!session.session_customer.company_registration_id)
        throw Error("Cannot create financing enveloppe because company_registration_id is null.");

      if (session.financing_mode === FinancingMode.AUTO)
        throw Error("Cannot create financing enveloppe with financing mode AUTO.");

      enveloppeFinancingCreatedDate.current = dayjs();

      createFinancingEnveloppeRequest({
        session_id: session.id,
        financer: FINANCIER_BY_FINANCING_MODE[session.financing_mode],
        company_registration_number: session.session_customer.company_registration_id,
      }).then(startRefetching);

      return;
    }

    if (
      !session.financing_enveloppe_request.calf_status ||
      includes(
        [null, CalfStatus.WAITING_DECISION, CalfStatus.SCORE_PENDING],
        session.financing_enveloppe_request.calf_status
      )
    ) {
      startRefetching();
      return;
    }

    stopRefetching();

    // ? We want the user to wait at least a few seconds before redirection or next step
    const waitingDuration = (enveloppeFinancingCreatedDate.current ?? dayjs())
      .add(MINIMUM_ENVELOPPE_FINANCING_WAITING_DURATION, "milliseconds")
      .diff(dayjs());

    if (
      [
        CalfStatus.OK_ACCEPTED_LEASING,
        CalfStatus.OK_ACCEPTED_LEASING_AFTER_REVIEW,
        CalfStatus.OK_CONFIRMED_LEASING,
      ].includes(session.financing_enveloppe_request.calf_status)
    ) {
      setTimeout(() => nextStep(), waitingDuration);
    }

    if (session.financing_enveloppe_request.calf_status === CalfStatus.OK_ASSET_DEFINITION) {
      setTimeout(() => redirect(RoutePath.CHECKOUT_PENDING_REVIEW), waitingDuration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, session.financing_enveloppe_request]);
};
