import { sessionSchema } from "schemas";
import { Session } from "types";
import { apiClient } from "../client";

export const updateSession = async ({
  id: sessionId,
  session_customer,
  shipping_address,
  billing_address,
  representative,
}: Pick<
  Session,
  "id" | "session_customer" | "shipping_address" | "billing_address" | "representative"
>) => {
  const response = await apiClient.patch<Session>(`sessions/${sessionId}`, {
    session: {
      session_customer_attributes: session_customer,
      shipping_address_attributes: {
        ...shipping_address,
        address_type: "shipping",
      },
      billing_address_attributes: {
        ...billing_address,
        address_type: "billing",
      },
      ...(representative ? { representative_attributes: representative } : undefined),
    },
  });

  return sessionSchema.parse(response.data);
};
