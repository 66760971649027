import { createStyles } from "@mantine/core";

interface Params {
  color: string;
}

export const useStyles = createStyles<string, Params>((_theme, { color }) => ({
  body: {
    alignItems: "center",
  },

  input: {
    borderColor: color,
    borderWidth: 2,
    ":checked": { borderColor: color, backgroundColor: color },
  },
}));
