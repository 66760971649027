import {
  CalfStatus,
  CustomerType,
  DeliveryMethod,
  Financier,
  FinancingEnveloppeRequestStatus,
  FinancingMode,
  LineItemType,
  SessionAddress,
  SessionDocumentType,
  SessionStatus,
  SessionStepStatus,
  SessionType,
} from "types";
import { z } from "zod";
import { retailerStyleSchema } from "./retailer";
import { enumFrom, nullableDefault } from "./utils";

export const lineItemPlanSchema = z.object({
  duration: z.number(),
  first_instalment: z.number(),
  other_instalment: z.number(),
  first_instalment_with_tax: z.number(),
  other_instalment_with_tax: z.number(),
});

export const lineItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  image: z.string().nullable(),
  quantity: z.number(),
  item_type: enumFrom(LineItemType),
  rent: z.boolean().nullable(),
  plans: z.array(lineItemPlanSchema),
  coverage_configuration_code: z.string().nullable(),
});

export const sessionCustomerSchema = z.object({
  first_name: nullableDefault(z.string(), ""),
  last_name: nullableDefault(z.string(), ""),
  date_of_birth: nullableDefault(z.string(), ""),
  phone: nullableDefault(z.string(), ""),
  email: nullableDefault(z.string(), ""),
  customer_type: nullableDefault(enumFrom(CustomerType), CustomerType.PERSON),
  company_name: z.string().nullable(),
  company_registration_id: z.string().nullable(),
  company_vat_id: z.string().nullable(),
});

export const representativeSchema = z.object({
  first_name: nullableDefault(z.string(), ""),
  last_name: nullableDefault(z.string(), ""),
  date_of_birth: nullableDefault(z.string(), ""),
  phone: nullableDefault(z.string(), ""),
  email: nullableDefault(z.string(), ""),
  job_title: nullableDefault(z.string(), ""),
});

export const sessionAddressSchema = z.object({
  street_address: nullableDefault(z.string(), ""),
  street_address_2: nullableDefault(z.string(), ""),
  zip_code: nullableDefault(z.string(), ""),
  city: nullableDefault(z.string(), ""),
  country: nullableDefault(z.string(), ""),
  first_name: nullableDefault(z.string(), ""),
  last_name: nullableDefault(z.string(), ""),
  phone: nullableDefault(z.string(), ""),
  email: z.string().nullable(),
  company_name: z.string().nullable(),
});

export const sessionDocumentFileSchema = z.object({
  filename: z.string(),
});

export const sessionDocumentSchema = z.object({
  id: z.string(),
  document_type: enumFrom(SessionDocumentType).nullable(),
  files: nullableDefault(z.array(sessionDocumentFileSchema).optional().default([]), []),
});

export const financingEnveloppeRequestSchema = z.object({
  id: z.string(),
  status: enumFrom(FinancingEnveloppeRequestStatus),
  financer: enumFrom(Financier),
  calf_status: enumFrom(CalfStatus).nullable(),
});

const emptyAddress: SessionAddress = {
  street_address: "",
  street_address_2: "",
  zip_code: "",
  city: "",
  country: "",
  last_name: "",
  first_name: "",
  phone: "",
  email: "",
  company_name: null,
};

const sessionStepSchema = z.object({
  status: enumFrom(SessionStepStatus),
  error_code: z.string().nullable(),
});

export const sessionSchema = z
  .object({
    id: z.string(),
    staging_payload_id: z.string().nullable().optional(),
    retailer_id: z.string(),
    "requires_signature?": z.boolean().optional(),
    session_type: enumFrom(SessionType),
    delivery_method: enumFrom(DeliveryMethod),
    store_name: z.string().nullable(),
    selected_duration: z.number().nullable(),
    line_items: z.array(lineItemSchema),
    tax_rate: z.number(),
    status: enumFrom(SessionStatus),
    financing_mode: enumFrom(FinancingMode),
    shipping_address: nullableDefault(sessionAddressSchema, emptyAddress)
      .optional()
      .default(emptyAddress),
    billing_address: nullableDefault(sessionAddressSchema, emptyAddress)
      .optional()
      .default(emptyAddress),
    session_customer: sessionCustomerSchema,
    representative: representativeSchema.nullable().optional(),
    documents: z.array(sessionDocumentSchema).optional().default([]),
    financing_enveloppe_request: financingEnveloppeRequestSchema.nullable().optional(),
    steps: z
      .object({
        web_scoring: sessionStepSchema.optional(),
        open_banking: sessionStepSchema.optional(),
        id_verification: sessionStepSchema.optional(),
      })
      .nullable(),
    first_setup_intent_id: z.string().nullable(),
    other_setup_intent_id: z.string().nullable(),
    iban: z.string().nullable(),
  })
  .transform((session) => {
    if (session.session_type === SessionType.B2B) {
      session.session_customer.customer_type = CustomerType.COMPANY;
    }

    return session;
  });

export const expiredSessionSchema = z.object({
  retailer_name: z.string(),
  shop_url: z.string(),
  rental_conditions_url: z.string().nullable(),
  style: retailerStyleSchema,
});
