import { Flex } from "@mantine/core";
import { useCheckoutContext } from "contexts/CheckoutContext";
import React from "react";
import { useStyles } from "./CheckoutHeader.styles";

export const CheckoutHeader: React.FC = () => {
  const { retailer } = useCheckoutContext();
  const { classes } = useStyles();

  return (
    <Flex justify="center" align="center" px="md" className={classes.header}>
      <img src={retailer.style.logo_url} className={classes.logoRetailer} />

      {/* <Text fw={700} align="center">
        {t("checkout.rentalRequest", { retailerName: retailer.name })}
      </Text> */}
    </Flex>
  );
};
