import { CheckboxProps, Checkbox as MantineCheckbox } from "@mantine/core";
import React from "react";
import { useStyles } from "./Checkbox.styles";

type Props = Omit<CheckboxProps, "classNames"> & {
  color: string;
};

export const Checkbox = React.forwardRef<HTMLInputElement, Props>(({ color, ...props }, ref) => {
  const { classes } = useStyles({ color });

  return <MantineCheckbox ref={ref} classNames={classes} {...props} />;
});
Checkbox.displayName = "Checkbox";
