import { useMutation, useQuery } from "@tanstack/react-query";
import { HttpStatusCode, isAxiosError } from "axios";
import includes from "lodash/includes";
import { useEffect } from "react";
import {
  chargeFirstInstalment as _chargeFirstInstalment,
  getPaymentClientSecret,
  preparePayment,
} from "services/api/session";
import { RentalStatus, Retailer, RoutePath, Session } from "types";
import { CheckoutStep } from "../../CheckoutContext";
import { useRedirect } from "../useRedirect";

export const usePayment = (
  session: Session,
  retailer: Retailer,
  steps: CheckoutStep[],
  currentStep: CheckoutStep,
  setRentalStatus: (rentalStatus: RentalStatus) => void
) => {
  const redirect = useRedirect(session, retailer);

  const { data: firstPaymentClientSecret } = useQuery({
    queryKey: ["first-payment-secret", session.id],
    queryFn: () =>
      session.first_setup_intent_id
        ? getPaymentClientSecret({
            session_id: session.id,
            setup_intent_id: session.first_setup_intent_id,
          })
        : preparePayment(session.id),
    enabled: currentStep === CheckoutStep.PAYMENT,
    retry: false,
    retryOnMount: false,
    staleTime: Infinity,
  });

  const { data: otherPaymentClientSecret } = useQuery({
    queryKey: ["other-payment-secret", session.id],
    queryFn: () =>
      getPaymentClientSecret({
        session_id: session.id,
        setup_intent_id: session.other_setup_intent_id,
      }),
    enabled:
      currentStep === CheckoutStep.PAYMENT &&
      retailer.payment_configuration.first_payment_method !==
        retailer.payment_configuration.other_payment_method &&
      !!firstPaymentClientSecret,
    retry: false,
    retryOnMount: false,
    staleTime: Infinity,
  });

  const { mutate: chargeFirstInstalment } = useMutation({
    mutationFn: _chargeFirstInstalment,
    onSuccess: ({ rental_id, rental_status }) => {
      if (steps.includes(CheckoutStep.CONTRACT_SIGNATURE)) {
        setRentalStatus(RentalStatus.PENDING_E_SIGNATURE);
      } else {
        redirect(
          includes([RentalStatus.PENDING_REVIEW, RentalStatus.PENDING_DOCUMENTS], rental_status)
            ? RoutePath.CHECKOUT_PENDING_REVIEW
            : RoutePath.CHECKOUT_SUCCESS,
          rental_id
        );
      }
    },
    onError: (error) => {
      if (isAxiosError(error)) {
        switch (error.response?.status) {
          case HttpStatusCode.PaymentRequired:
            redirect(RoutePath.CHECKOUT_PAYMENT_FAILED);
            break;
        }
      }
    },
  });

  useEffect(() => {
    if (currentStep === CheckoutStep.END) {
      chargeFirstInstalment(session.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  return { firstPaymentClientSecret, otherPaymentClientSecret };
};
