import { createStyles } from "@mantine/core";

interface Params {
  color: string;
}

export const useStyles = createStyles<string, Params>(({ spacing }, { color }) => ({
  item: {
    paddingLeft: spacing.md,

    "::before": {
      top: "50%",
    },

    ":not(:first-of-type)": {
      marginTop: spacing.md,
    },
  },

  itemTitle: { fontWeight: 700 },

  itemBullet: {
    backgroundColor: color,
    border: "none",
    top: "50%",
    transform: "translateY(-50%)",
  },
}));
