import { createStyles } from "@mantine/core";
import { MEDIUM_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(({ spacing, fn: { largerThan } }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },

  wrapper: {
    flex: 1,
    rowGap: spacing.md,
    padding: `${spacing.md} ${spacing.sm}`,

    [largerThan(MEDIUM_SCREEN_BREAKPOINT)]: {
      rowGap: spacing.lg,
      padding: `${spacing.lg} ${spacing.sm}`,
    },
  },

  sketch: {
    alignSelf: "center",
  },

  listItemIcon: {
    marginRight: spacing.xs,

    svg: {
      strokeWidth: 3,
      height: "80%",
      width: "80%",
    },
  },

  detailsAnchor: {
    alignSelf: "flex-start",
  },
}));
