import { createStyles } from "@mantine/core";

interface Params {
  color: string;
  variant?: "primary" | "secondary" | "ghost";
}

export const useStyles = createStyles<string, Params>(
  ({ radius, white, fn: { darken, rgba } }, { color, variant = "primary" }) => ({
    root: {
      borderRadius: radius.xl,
      borderWidth: variant === "secondary" ? 1 : 0,
      borderColor: color,
      backgroundColor: variant === "primary" ? color : "transparent",

      ":hover": {
        backgroundColor: variant === "primary" ? darken(color, 0.1) : rgba(color, 0.04),
      },

      "&[data-disabled]":
        variant === "primary"
          ? {
              backgroundColor: rgba(color, 0.5),
              color: white,
            }
          : undefined,
    },

    label: { fontWeight: 700, color: variant === "primary" ? undefined : color },

    icon: { color: variant === "primary" ? undefined : color },
  })
);
