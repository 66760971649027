import { createStyles, getStylesRef } from "@mantine/core";

interface Params {
  error: boolean;
}

export const useStyles = createStyles<string, Params>(({ colors }, { error }) => ({
  group: {
    [`.${getStylesRef("radio")}`]: error
      ? {
          borderColor: colors.red[6],
        }
      : {},
  },
}));
