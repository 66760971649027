import { Radio as MantineRadio, RadioProps } from "@mantine/core";
import React from "react";
import { useStyles } from "./Radio.styles";

type Props = Omit<RadioProps, "classNames"> & {
  color: string;
};

export const Radio = React.forwardRef<HTMLInputElement, Props>(({ color, ...props }, ref) => {
  const { classes } = useStyles({ color });

  return <MantineRadio ref={ref} classNames={classes} {...props} />;
});
Radio.displayName = "Radio";
