import { z } from "zod";
import { apiClient } from "../client";

const responseDataSchema = z.object({
  payment_secret: z.string(),
});

export const preparePayment = async (sessionId: string) =>
  responseDataSchema.parse((await apiClient.post(`sessions/${sessionId}/prepare_payment`)).data)
    .payment_secret;
