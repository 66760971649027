import { useMatomoContext } from "contexts/MatomoContext";
import { useEffect } from "react";
import { TrackingEventAction, TrackingEventCategory, TrackingEventType } from "services/analytics";
import { useTrackingEventData } from "./useTrackingEventData";

export const usePageViewTracking = (name: string): void => {
  const eventData = useTrackingEventData();
  const { trackEvent } = useMatomoContext();

  useEffect(() => {
    trackEvent({
      type: TrackingEventType.PAGE_VIEW,
      category: TrackingEventCategory.PAGE_NAME,
      action: TrackingEventAction.OPEN_NEW_PAGE,
      name,
      eventData,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);
};
