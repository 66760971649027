import { createStyles } from "@mantine/core";
import { SMALL_SCREEN_BREAKPOINT } from "theme";

export const useStyles = createStyles(({ spacing, fn: { smallerThan } }) => ({
  notifications: {
    top: `${spacing.sm} !important`,
    right: `${spacing.sm} !important`,
    maxWidth: "22rem !important",
    [smallerThan(SMALL_SCREEN_BREAKPOINT)]: {
      top: "unset !important",
      bottom: `${spacing.sm} !important`,
      left: `${spacing.sm} !important`,
      maxWidth: "unset !important",
      width: "unset",
    },
  },
}));
