import {
  Provider,
  createContext as reactCreateContext,
  useContext as reactUseContext,
} from "react";

/**
 * Create and return a useContext hook and a corresponding context Provider without needing to check the `undefined` value of the context value
 * Credit: https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/context/
 */
export const createContext = <A>(): readonly [() => A, Provider<A | undefined>] => {
  const context = reactCreateContext<A | undefined>(undefined);
  const customUseContext = () => {
    const customContext = reactUseContext(context);
    if (customContext === undefined)
      throw new Error("useContext must be inside a Provider with a value");

    return customContext;
  };

  return [customUseContext, context.Provider] as const; // 'as const' makes TypeScript infer a tuple
};
