import { createStyles } from "@mantine/core";
import { GRAY } from "theme";

interface Params {
  color: string;
}

export const useStyles = createStyles<string, Params>(
  ({ spacing, radius, fn: { rgba } }, { color }) => ({
    container: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },

    contentWrapper: {
      flex: 1,
      padding: spacing.sm,
    },

    radioCard: {
      borderStyle: "solid",
      borderWidth: 1.5,
      borderColor: GRAY,
      borderRadius: radius.md,
      padding: spacing.sm,
      cursor: "pointer",

      "&[data-selected]": {
        borderColor: color,
        boxShadow: `0 0 ${spacing.xs} ${rgba(color, 0.2)}`,
      },
    },

    radio: {
      borderWidth: 2,
      borderColor: GRAY,

      ":checked": {
        backgroundColor: color,
        borderColor: color,
      },
    },
  })
);
